import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useSetTag } from 'trending/hooks/use-set-tag'

export const useQueryFilter = () => {
  const { location: { search } } = useHistory()
  const setTag = useSetTag()

  useEffect(() => {
    const parsedQuery = new URLSearchParams(search)

    if (parsedQuery.has('trending')) {
      setTag(parsedQuery.get('trending'))
    }
  }, [search, setTag])
}
