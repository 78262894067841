import { useResetState } from 'collections/auctions/behaviour/hooks/use-reset-state'
import { useFetchInitial } from 'collections/auctions/behaviour/hooks/use-fetch-initial'
import { useForceResetState } from 'collections/auctions/behaviour/hooks/use-force-reset-state'
import { useTransactionWatch } from 'collections/auctions/behaviour/hooks/use-transaction-watch'

export const Behaviour = () => {
  useResetState()
  useForceResetState()
  useFetchInitial()
  useTransactionWatch()

  return ''
}
