import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Empty as EmptyState } from 'components/empty'
import { selector as auctionSelector } from 'account/tabs/assets/auctions/hooks/use-items'
import { useSelector } from 'react-redux'

const stateProps = {
  icon: faGavel,
  message: 'No auctions yet.',
}

const useAuctionCount = () => useSelector(auctionSelector).length

export const Empty = () => {
  const auctionCount = useAuctionCount()

  return !auctionCount && <EmptyState {...stateProps} />
}
