import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

export const useSoftRefresh = () => {
  const { location: { state, pathname }, replace } = useHistory()

  useEffect(() => {
    if (state === 'reload') {
      replace(pathname)
    }
  }, [pathname, replace, state])
}
