import match from '@nerds.sh/js-pattern'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { State } from 'sidebar/connect/ledger-login/ledger-container/state'
import { ConfirmAddress } from 'sidebar/connect/ledger-login/ledger-container/confirm-address'
import { AddressTable } from 'sidebar/connect/ledger-login/ledger-container/address-table'
import { LedgerConnect } from 'sidebar/connect/ledger-login/ledger-container/ledger-connect'

const loadingProps = {
  icon: faCircleNotch,
  iconClassName: 'fa-spin ledger-container-primary',
  title: 'Waiting for device',
}

const tableProps = props => ({
  accounts: props.accounts,
  loading: props.isLoading,
  onGoToNextPage: props.onGoToNextPage,
  onGoToPrevPage: props.onGoToPrevPage,
  onSelectAddress: props.onSelectAddress,
  startIndex: props.startIndex,
  selectedAddress: props.selectedAddress?.address,
  onConfirmSelectedAddress: props.onConfirmSelectedAddress,
})

export const Content = props => match({})
  .with(props.showAddressList && !props.error, () => <AddressTable {...tableProps(props)} />)
  .with(props.ledgerAccount != null && !props.error, () => <ConfirmAddress token={props.token} />)
  .with(props.isLoading, () => <State {...loadingProps} />)
  .otherwise(() => <LedgerConnect onClick={props.login} error={props.error} />)
