import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import moment from 'moment'
import { nominate } from 'components/conversion'
import { actions } from 'modals/auction/start/slices/create-auction'

export const useCreateBundleAuction = asset => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const variables = {
      identifier: asset.identifier,
      quantity: `${payload.quantity}`,
      minBid: nominate(payload.minBid),
      maxBid: nominate(payload.maxBid),
      deadline: moment(payload.deadline).unix()
        .toString(),
      paymentToken: 'EGLD',
      maxOneSftPerPayment: false,
    }

    return dispatch(actions.attempt(variables))
  }, [asset.identifier, dispatch])
}
