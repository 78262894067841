import { Content } from 'components/username/content'
import PropTypes from 'prop-types'
import { colors } from 'components/username/colors'
import 'components/username/style.scss'

export const Username = props => {
  const hidden = props.herotagOnly && !props.herotag

  return !hidden && <Content {...props} />
}

Username.defaultProps = {
  herotag: '',
  address: '',
  herotagOnly: false,
  className: '',
  linkDestination: 'collected',
  color: colors['dark-blue'],
  padding: 6,
  isPrivate: false,
}

Username.propTypes = {
  herotag: PropTypes.string,
  address: PropTypes.string,
  herotagOnly: PropTypes.bool,
  linkDestination: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['dark-blue', 'primary']),
  padding: PropTypes.number,
  isPrivate: PropTypes.bool,
}

