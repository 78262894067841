/* eslint-disable */
import { actions as market } from 'market/slices/market'
import { actions as exploreNfts } from 'explore-nfts/nfts/slices/get'
import { actions as trending } from 'trending/slices/cursor'
import { actions as collectionAssetsActions } from 'collections/assets/slices/get'
import { actions as collectionAuctionsActions } from 'collections/auctions/slices/get'
import { actions as accountCreations } from 'account/tabs/assets/creations/slices/get'
import { actions as accountCollected } from 'account/tabs/assets/collected/slices/get'
import { actions as accountAuctions } from 'account/tabs/assets/auctions/slices/get'
import { actions as accountBids } from 'account/tabs/assets/bids/slices/get'
import { actions as accountClaimable } from 'account/tabs/assets/claimable/slices/get'
import { useExploreNftsEdges } from 'home/explore-nfts/nfts/hooks/use-explore-nfts-edges'
import { useAuctions as useHomeTrending } from 'home/trending/hooks/use-auctions'
import { useActualList as useMarketList } from 'market/hooks/use-actual-list'
import { useActualList as useTrendingAuctions } from 'trending/hooks/use-actual-list'
import { useActualList as useExploreNftsList } from 'explore-nfts/nfts/hooks/use-actual-list'
import { useCollectionAssetsList } from 'collections/assets/hooks/use-collection-assets-list'
import { useCollectionAuctionsList } from 'collections/auctions/hooks/use-collection-auctions-list'
import { useActualItems as useAccountCreations } from 'account/tabs/assets/creations/hooks/use-actual-items'
import { useActualItems as useAccountCollected } from 'account/tabs/assets/collected/hooks/use-actual-items'
import { useActualItems as useAccountAuctions } from 'account/tabs/assets/auctions/hooks/use-actual-items'
import { useActualItems as useAccountBids } from 'account/tabs/assets/bids/hooks/use-actual-items'
import { useActualItems as useAccountClaimable } from 'account/tabs/assets/claimable/hooks/use-actual-items'
import { useHasMore as accountCreationsHasMore } from 'account/tabs/assets/creations/hooks/use-has-more'
import { useHasMore as accountCollectedHasMore } from 'account/tabs/assets/collected/hooks/use-has-more'
import { useHasMore as accountAuctionsHasMore } from 'account/tabs/assets/auctions/hooks/use-has-more'
import { useHasMore as accountBidsHasMore } from 'account/tabs/assets/bids/hooks/use-has-more'
import { useHasMore as accountClaimableHasMore } from 'account/tabs/assets/claimable/hooks/use-has-more'
import { useHasMore as exploreNftsHasMore } from 'explore-nfts/nfts/hooks/use-has-more'
import { useHasMore as trendingHasMore } from 'trending/hooks/use-has-more'
import { useHasMore as marketHasMore } from 'market/hooks/use-has-more'
import { useHasMore as collectionAssetsHasMore } from 'collections/assets/hooks/use-has-more'
import { useHasMore as collectionAuctionsHasMore } from 'collections/auctions/hooks/use-has-more'
import { useFetchPayloadPreview as useFetchAssetsPayload } from 'collections/assets/hooks/use-fetch-payload-preview'
import { useFetchPayloadPreview as useFetchAuctionsPayload } from 'collections/auctions/hooks/use-fetch-payload-preview'
import { useFetchPayload as useFetchCollectedPayload} from 'account/tabs/assets/collected/hooks/use-fetch-payload'
import { useFetchPayloadPreview as useFetchMarketPayload } from 'market/hooks/use-fetch-payload-preview'
import { useFetchPayloadPreview as useFetchExplorePayload } from 'explore-nfts/nfts/hooks/use-fetch-payload-preview'

export const usePreviewSources = () => [
  {
    name: 'home-trending',
    hasMore: false,
    selector: useHomeTrending(),
  },
  {
    name: 'home-explore-nfts',
    hasMore: false,
    selector: useExploreNftsEdges(),
  },
  {
    name: 'collection-assets',
    hasMore: collectionAssetsHasMore(),
    action: collectionAssetsActions.attempt,
    selector: useCollectionAssetsList(),
    payload: useFetchAssetsPayload(),
  },
  {
    name: 'collection-auctions',
    hasMore: collectionAuctionsHasMore(),
    action: collectionAuctionsActions.attempt,
    selector: useCollectionAuctionsList(),
    payload: useFetchAuctionsPayload(),
  },
  {
    name: 'market',
    hasMore: marketHasMore(),
    action: market.attempt,
    selector: useMarketList(),
    payload: useFetchMarketPayload()
  },
  {
    name: 'trending',
    hasMore: trendingHasMore(),
    action: trending.set,
    selector: useTrendingAuctions(),
    payload: ({ cursor }) => cursor,
  },
  {
    name: 'explore',
    hasMore: exploreNftsHasMore(),
    action: exploreNfts.attempt,
    selector: useExploreNftsList(),
    payload: useFetchExplorePayload(),
  },
  {
    name: 'account-creations',
    hasMore: accountCreationsHasMore(),
    action: accountCreations.setCursor,
    selector: useAccountCreations(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-collected',
    hasMore: accountCollectedHasMore(),
    action: accountCollected.attempt,
    selector: useAccountCollected(),
    payload: useFetchCollectedPayload()
  },
  {
    name: 'account-auctions',
    hasMore: accountAuctionsHasMore(),
    action: accountAuctions.setCursor,
    selector: useAccountAuctions(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-bids',
    hasMore: accountBidsHasMore(),
    action: accountBids.setCursor,
    selector: useAccountBids(),
    payload: ({ cursor }) => cursor
  },
  {
    name: 'account-claimable',
    hasMore: accountClaimableHasMore(),
    action: accountClaimable.setCursor,
    selector: useAccountClaimable(),
    payload: ({ cursor }) => cursor
  },
]

/* eslint-enable */
