import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { sendTransactions } from '@elrondnetwork/dapp-core/services'
import { makeTransaction } from 'common/hooks/make-transaction'
import { useSetTransaction } from 'transaction/hooks'
import { useHistory } from 'react-router-dom'
import { actions as actionsStatuses } from 'action-statuses/slices'
import { get } from 'lodash'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import { useMarketplaceAddress } from 'marketplaces/hooks/use-marketplace-address'
import { useCmsCampaignContract } from 'subdomain/cms-content/hooks/use-cms-campaign-contract'

const walletDestination = ({ callbackUrl }) => get(callbackUrl, 'pathname', callbackUrl)

const buildSendingPayload = ({ data, marketplaceAddress, cmsCampaignContract, ...rest }) => ({
  transactions: [makeTransaction(data, marketplaceAddress, cmsCampaignContract)],
  minGasLimit: 3822000,
  // TODO: Find a way to remove this
  callbackRoute: walletDestination(rest),
})

/* eslint-disable max-lines-per-function */
export const useOnSentPayload = ({ data, callbackUrl, resetAction, actionIdentifier, isModal = true }) => {
  const dispatch = useDispatch()
  const setTransaction = useSetTransaction()
  const { location } = useHistory()
  const closeLayer = useCloseLayerNavigate()

  const marketplaceAddress = useMarketplaceAddress()
  const cmsCampaignContract = useCmsCampaignContract()

  useEffect(() => {
    if (data) {
      sendTransactions(buildSendingPayload({ data, marketplaceAddress, cmsCampaignContract, callbackUrl }))
        .then(({ sessionId }) => {
          dispatch(resetAction())
          dispatch(actionsStatuses.add({ [actionIdentifier]: sessionId }))
          setTransaction({ sessionId, callbackUrl, previousLocation: location })
          isModal && closeLayer(callbackUrl.pathname || callbackUrl)
        })
    }
  }, [
    data, callbackUrl, dispatch, resetAction, setTransaction,
    actionIdentifier, location, closeLayer,
    marketplaceAddress, cmsCampaignContract, isModal,
  ])
}
/* eslint-enable max-lines-per-function */
