import { gql } from 'graphql-tag'

export const TRENDING = gql`
    query trendingCollections($pagination: ConnectionArgs){
        trendingCollections(pagination: $pagination) {
            identifier
            imageUrl
            name
            verified
            percentage
            volume {
                amount
                token
                usdAmount
                tokenData{
                    decimals
                    symbol
                }
            }
            floorPrice {
                amount
                token
                usdAmount
                tokenData{
                    decimals
                    symbol
                }
            }
        }
    }
`
