import { Empty as EmptyState } from 'components/empty'
import { useExploreCollectionsList } from 'explore-nfts/collections/hooks/use-explore-collections-list'
import { useIsLoading } from 'explore-nfts/collections/hooks/use-is-loading'
import { faCompass } from '@fortawesome/pro-light-svg-icons'

const stateProps = {
  icon: faCompass,
  message: 'No collections.',
  fullscreen: true,
}

export const Empty = () => {
  const data = useExploreCollectionsList()
  const isLoading = useIsLoading()

  return !isLoading && !data.length && <EmptyState {...stateProps} />
}
