// import { useCreationsStats } from 'account/stats/hooks/use-creations-stats'
import { useCollectionsStats } from 'account/stats/hooks/use-collections-stats'
import { useCollectedStats } from 'account/stats/hooks/use-collected-stats'

// import { selector as creationsSelector } from 'account/tabs/assets/creations/hooks/use-items'
import { selector as collectedSelector } from 'account/tabs/assets/collected/hooks/use-items'
import { selector as collectionsSelector } from 'account/tabs/assets/collections/hooks/use-items'

import { Loading } from 'loading'

export const publicTabs = {
  collected: {
    hash: 'collected',
    label: 'Gallery',
    omitTabInDestination: true,
    selector: useCollectedStats,
    previewSource: 'account-collected',
    itemsSelector: collectedSelector,
    loadingID: Loading.ID.account.tab.collected,
    loadingFactory: Loading.ghosts.assets,
  },
  collections: {
    hash: 'collections',
    label: 'Collections',
    selector: useCollectionsStats,
    previewSource: '',
    itemsSelector: collectionsSelector,
    loadingID: Loading.ID.account.tab.collections,
    loadingFactory: Loading.ghosts.collections,
  },
}
