import { gql } from 'graphql-tag'

export const STATS = gql`
    query accountStats($filters: AccountStatsFilter!) {
        accountStats(filters:  $filters) {
            address
            auctions
            biddingBalance
            claimable
            collected
            collections
            creations
            orders
        }
    }`
