import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'

const initialState = { data: [] }

const append = ({ data }, { payload }) => ({ data: uniqBy([...data, ...payload], 'node.collection') })

const reset = () => initialState

export const list = createSlice({
  initialState,
  name: 'explore-nfts/collections/list',
  reducers: {
    append,
    reset,
  },
})

export const { actions } = list
