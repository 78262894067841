import { useThumbnailUrl } from 'common/asset/use-thumbnail-url'
import { EmptyAsset } from 'components/collection-card/asset/empty-asset'
import { useErrorCallback } from 'components/collection-card/hooks/use-error-callback'
import 'components/collection-card/asset/index.scss'
import { isEmpty } from 'lodash'

export const Asset = ({ asset }) => {
  const onError = useErrorCallback()
  const thumbnailUrl = useThumbnailUrl(asset, { useDefault: true })
  const isEmptyAsset = isEmpty(asset)
  const hasAssetClass = !isEmptyAsset ? '' : 'no-asset'

  return <div className={`collection-card-asset ${hasAssetClass}`}>
    {!isEmptyAsset ? <img src={thumbnailUrl} alt={'...'} onError={onError} /> : <EmptyAsset />}
  </div>
}
