import { useDispatch } from 'react-redux'
import { useEndCursor } from 'subdomain/modals/account/my-nfts/hooks/use-end-cursor'
import { useCallback } from 'react'
import { actions } from 'subdomain/modals/account/my-nfts/slices/get'
import { payload } from 'subdomain/modals/account/my-nfts/payload'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useSubdomainIdentifiers } from 'subdomain/home/collections/hooks/use-subdomain-identifiers'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const dispatch = useDispatch()
  const cursor = useCursor(customCursor)
  const { address } = useGetAccountInfo()
  const collections = useSubdomainIdentifiers()

  return useCallback(() => {
    address && dispatch(actions.attempt(payload({ address, cursor, collections })))
  }, [address, dispatch, cursor, collections])
}

