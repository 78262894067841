import { useCallback } from 'react'
import { useBalance } from 'common/hooks/use-balance'
import { denominate } from 'components/conversion'
import { get } from 'lodash'

export const useHasInsufficientFunds = auction => {
  const balance = useBalance()
  const maxBid = denominate(get(auction, 'maxBid.amount', '0'))

  return useCallback(value => {
    const maxBidValue = parseFloat(maxBid) && value

    return parseFloat(balance) <= maxBidValue
  }, [balance, maxBid])
}
