import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'explore-nfts/nfts/slices/get'
import { useSelectedTag } from '../../../hooks/use-selected-tag'

export const useForceResetState = () => {
  const dispatch = useDispatch()

  const selected = useSelectedTag()
  useEffect(() => {
    dispatch(actions.reset({}))
  }, [dispatch, selected])
}
