import { Description } from 'nft-page/artist-account/description'
import { SocialIcons } from 'nft-page/artist-account/social-icons'
import { useNftCreatorSelector } from 'nft-page/hooks/nft/use-nft-creator-selector'
import { get } from 'lodash'
import 'nft-page/artist-account/index.scss'
import { VisibleIfExists } from 'nft-page/artist-account/visible-if-exists'
import { FlexRow } from 'components/flex-row'
import { Artist } from 'nft-page/artist-account/artist'

export const ArtistAccount = () => {
  const creator = useNftCreatorSelector()
  const socialLinks = get(creator, 'socialLinks', [])
  const description = get(creator, 'description', '')

  return <div className={'artist-account'}>
    <FlexRow className={'flex-wrap justify-content-between'}>
      <Artist />
      <VisibleIfExists value={socialLinks ? socialLinks.length : socialLinks}>
        <SocialIcons socialLinks={socialLinks} />
      </VisibleIfExists>
    </FlexRow>
    <VisibleIfExists value={description}>
      <Description description={description} />
    </VisibleIfExists>
  </div>
}
