import { useEffect } from 'react'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'

export const useOnThemeChange = () => {
  const isDarkTheme = useIsDarkTheme()

  useEffect(() => {
    const darkCSS = document.getElementById('dark-theme-stylesheet')

    if (isDarkTheme) {
      darkCSS?.removeAttribute('disabled')
      document.body.classList.add('dark-theme')
    } else {
      darkCSS?.setAttribute('disabled', true)
      document.body.classList.remove('dark-theme')
    }
  }, [isDarkTheme])
}
