import { useOnClick } from 'account/create-collection/hooks/use-on-click'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'

export const CollectionLink = () => {
  const isActiveUser = useIsActiveUser()
  const onClick = useOnClick()

  return isActiveUser && <a onClick={onClick} className={'collection-link'}>
    Create new collection
  </a>
}
