import { faUserCheck } from '@fortawesome/pro-light-svg-icons'
import { Empty as EmptyState } from 'components/empty'
import { selector as claimableSelector } from 'account/tabs/assets/claimable/hooks/use-items'
import { useSelector } from 'react-redux'

const stateProps = {
  icon: faUserCheck,
  message: 'No claimable auctions yet.',
}

const useClaimableCount = () => useSelector(claimableSelector).length

export const Empty = () => {
  const claimableCount = useClaimableCount()

  return !claimableCount && <EmptyState {...stateProps} />
}
