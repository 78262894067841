import { useRunningAuctionsPageInfo } from 'nft-page/details/other-listings/hooks/use-running-auctions-page-info'
import { Button } from '../other-listings/button'
import { useClaimableAuctionsPageInfo } from 'nft-page/details/other-listings/hooks/use-claimable-auctions-page-info'
import { useRunningLoadMore } from 'nft-page/details/other-listings/hooks/use-running-load-more'
import { useClaimableLoadMore } from 'nft-page/details/other-listings/hooks/use-claimable-load-more'
import { useCallback } from 'react'

const useOnClick = () => {
  const { hasNextPage: runningHasNextPage } = useRunningAuctionsPageInfo()
  const { hasNextPage: claimableHasNextPage } = useClaimableAuctionsPageInfo()
  const runningLoadMore = useRunningLoadMore()
  const claimableLoadMore = useClaimableLoadMore()

  return useCallback(() => {
    runningHasNextPage && runningLoadMore()
    claimableHasNextPage && claimableLoadMore()
  }, [runningHasNextPage, runningLoadMore, claimableHasNextPage, claimableLoadMore])
}

export const LoadMore = () => {
  const { hasNextPage: runningHasNextPage } = useRunningAuctionsPageInfo()
  const { hasNextPage: claimableHasNextPage } = useClaimableAuctionsPageInfo()
  const onClick = useOnClick()

  return runningHasNextPage || claimableHasNextPage ? <Button onClick={onClick} /> : ''
}
