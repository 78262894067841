import { combineReducers } from 'redux'

import { exploreNfts } from 'home/explore-nfts/slices'
import { trending } from 'home/trending/slices'
import { featured } from 'home/featured/slices'
import { reducer as campaigns } from 'home/campaigns/reducers'
import { get as trendingCollections } from 'home/trending-collections/slices'

export const home = combineReducers({
  exploreNfts,
  trending,
  featured: featured.reducer,
  campaigns,
  trendingCollections: trendingCollections.reducer,
})
