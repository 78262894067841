import { Failure } from 'failure'
import { history } from 'network'
import { Router } from 'react-router-dom'
import { Footer } from 'components/footer'
import { Layers } from 'components/layered-router/layers'
import { RouterContext, useRouterContextValue } from 'components/layered-router/context'
import { Behaviour } from 'components/layered-router/behaviour'
import { PreferencesBehaviour } from 'components/layered-router/preferences-behaviour'

export const LayeredRouter = ({ layers, children }) => <RouterContext.Provider value={useRouterContextValue()}>
  <Behaviour layers={layers} />
  <Router history={history}>
    {children}
    <Failure.Absolute>
      <PreferencesBehaviour />
      <Layers layers={layers} />
    </Failure.Absolute>
    <Footer />
  </Router>
</RouterContext.Provider>

