import { useFetchNft } from 'nft-page/hooks/use-fetch-nft'
import { useFetchOwners } from 'owners/hooks/use-fetch-owners'
import { useFetchNftWithOwner } from 'nft-page/owner/hooks/use-fetch-nft-with-owner'
import { useAutoFocus } from 'nft-page/hooks/use-auto-focus'
import { useEffect } from 'react'
import { useStateCleanup } from 'nft-page/hooks/use-state-cleanup'
import { useFetchAssetHistory } from 'asset-history/hooks/use-fetch-asset-history'
import { useAppendHistoryData } from 'asset-history/hooks/use-append-history-data'
import { useControls } from 'nft-page/hooks/use-controls'
import { useFetchSelectedAuction } from 'nft-page/auction/hooks/use-fetch-selected-auction'
import { useFetchRunningAuctions } from 'nft-page/details/other-listings/hooks/use-fetch-running-auctions'
import { useParams } from 'react-router-dom'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useInvalidRedirect } from 'nft-page/hooks/use-invalid-redirect'
import { useActionCallback } from 'nft-page/details/other-listings/hooks/use-action-callback'
import { useFetchClaimableAuctions } from 'nft-page/details/other-listings/hooks/use-fetch-claimable-auctions'
import { useAuctionPooling } from 'nft-page/hooks/use-auction-pooling'
import { useFetchAuctionPrice } from 'nft-page/hooks/use-fetch-auction-price'
import { useFetchAssetOrders } from 'nft-page/auction/bids/hooks/use-fetch-asset-orders'
import { useTrackAsset } from 'nft-page/tracking/hooks/use-track-asset'
import { useFetchMore } from 'nft-page/controls/hooks/use-fetch-more'
import { useFetchLikes } from 'nft-page/likes/hooks'

const useResetState = () => {
  const stateCleanup = useStateCleanup()
  const { identifier, auctionId } = useParams()

  useEffect(() => () => {
    stateCleanup()
  }, [stateCleanup, identifier, auctionId])
}

const useWatchActions = () => {
  const { identifier, auctionId } = useParams()
  const execute = useActionCallback()

  useWatchAction({ prefix: `${identifier}_Asset_${identifier}`, onChange: execute })
  useWatchAction({ prefix: `${identifier}_Auction_${auctionId}`, onChange: execute })
}

const useFetchAuctions = () => {
  useFetchSelectedAuction()
  useFetchAuctionPrice()
  useFetchRunningAuctions()
  useFetchClaimableAuctions()
  useFetchAssetOrders()
}

export const Behaviour = () => {
  useWatchActions()
  useControls()
  useResetState()
  useAutoFocus()
  useFetchNft()
  useFetchLikes()
  useFetchNftWithOwner()
  useFetchAssetHistory()
  useAppendHistoryData()
  useFetchOwners()
  useFetchAuctions()
  useInvalidRedirect()
  useAuctionPooling()
  useTrackAsset()
  useFetchMore()

  return ''
}
