import { formatToken } from 'common/format-token'
import { denominate } from 'components/conversion'

export const ActionLabel = ({ whom }) => <>
  bidded
  {' '}
  <strong>{denominate(whom.price.amount)}</strong>
  {' '}
  {formatToken(whom.price.token)}
  {' '}
  on
</>
