import { useResetState } from 'explore-nfts/collections/behaviour/hooks/use-reset-state'
import { useAppendData } from 'explore-nfts/collections/behaviour/hooks/use-append-data'
import { useAssetsFetching } from 'explore-nfts/collections/behaviour/hooks/use-assets-fetching'
import { useForceResetState } from 'explore-nfts/collections/behaviour/hooks/use-force-reset-state'

export const Behaviour = () => {
  useResetState()
  useForceResetState()
  useAssetsFetching()
  useAppendData()

  return ''
}
