import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { usePreviousElement } from 'nft-page/controls/hooks/use-previous-element'
import { useLayeredPath } from 'nft-page/controls/hooks/use-layered-path'

export const useOnLeftClick = () => {
  const { replace } = useHistory()
  const previousElement = usePreviousElement()
  const layerPath = useLayeredPath()

  return useCallback(() => {
    if (previousElement) {
      replace(layerPath(previousElement))
    }
  }, [layerPath, previousElement, replace])
}
