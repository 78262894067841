import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = Object.freeze({ loginToken: undefined })

const setLoginToken = (state, action) => ({ ...state, loginToken: action.payload })

const resetLoginToken = state => ({ ...state, loginToken: INITIAL_STATE.loginToken })

export const maiar = createSlice({
  name: 'auth/maiar',
  initialState: INITIAL_STATE,
  reducers: {
    setLoginToken,
    resetLoginToken,
  },
})
