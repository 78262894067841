import { Redirect } from 'react-router-dom'
import { useFirstExactMatch } from 'search/hooks/use-first-exact-match'

export const ExploreRedirect = () => {
  const result = useFirstExactMatch()

  if (result?.kind === 'tag') {
    const to = {
      pathname: `/explore`,
      search: `?tag=${result?.item.identifier}`,
      state: { forceReset: true },
    }

    return <Redirect to={to} />
  }

  return ''
}
