import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useSelectedTag } from 'explore-nfts/hooks/use-selected-tag'

export const useIsSelected = () => {
  const selectedTag = useSelectedTag()

  return useCallback(({ value }) => {
    if (isEmpty(selectedTag)) return value === ''

    return selectedTag === value
  }, [selectedTag])
}
