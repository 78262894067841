import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'

export const Qr = ({ walletConnectUri }) => {
  const [qr, setQr] = useState('')

  useEffect(() => {
    if (walletConnectUri) {
      QRCode.toString(walletConnectUri, { type: 'svg' })
        .then(setQr)
    }
  }, [walletConnectUri, setQr])

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: qr }} className={'mx-auto mb-3'}
              style={{
                width: '15rem',
                height: '15rem',
              }} />
}
