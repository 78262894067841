import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useIsInvalid } from 'nft-page/hooks/use-invalid-redirect/use-is-invalid'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'
import { actions as selectedAuction } from 'nft-page/auction/slices/selected-auction'
import { useDispatch } from 'react-redux'

const makeUrl = identifier => `/nfts/${identifier}`

export const useInvalidRedirect = () => {
  const isInvalid = useIsInvalid()
  const { replace } = useHistory()
  const { identifier } = useParams()
  const makeLocation = useLayeredLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isInvalid) {
      dispatch(selectedAuction.reset({}))
      replace(makeLocation(makeUrl(identifier), 'foreground'))
    }
  }, [dispatch, identifier, isInvalid, makeLocation, replace])
}
