import { useEffect, useRef } from 'react'
import { useContext } from 'components/rolling-collection/context'

export const useTimer = () => {
  const { loadedThumbs, setCurrent } = useContext()
  const interval = useRef(null)

  useEffect(() => {
    interval.current = setInterval(() => {
      const updateCallback = current => current + 1 > loadedThumbs.length - 1 ? 0 : current + 1
      interval.current && setCurrent(updateCallback)
    }, 300)

    return () => clearInterval(interval.current)
  }, [interval, setCurrent, loadedThumbs.length])
}
