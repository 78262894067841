import { faStarOfLife } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTotalEgldValue } from 'components/forms/nerds-form/fields/total-price-field/hooks/use-total-egld-value'
import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import 'components/forms/nerds-form/fields/total-price-field/index.scss'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const TotalPriceField = ({ auction }) => {
  const totalEgldValue = useTotalEgldValue(auction)
  const { price } = useEconomicsSelector()
  const decimals = useDenominationDecimals()
  const totalUsdPrice = parseFloat(`${price * totalEgldValue}`).toFixed(decimals)

  return <div className={'total-price-field d-flex align-items-center mt-2 mb-2'}>
    <FontAwesomeIcon icon={faStarOfLife} className={'total-price-field-image ms-2 me-2'} size={'2x'} />
    <div>
      <div className={'total-price-field-title d-flex justify-content-start'}>
        Total price per pieces
      </div>
      <div className={'d-flex justify-content-start'}>
        {`${totalEgldValue} ${egldLabel()} (~$${totalUsdPrice})`}
      </div>
    </div>
  </div>
}
