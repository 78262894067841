import './index.scss'
import { useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelectItem } from './hooks/use-select-item'
import { Button } from './button'

const findOption = (options, name) => options.find(item => item.name === name)

const makeClassName = (active = '') => `dropdown-item ${active ? 'active' : ''}`

export const Dropdown = ({ options, onChange, id, initial }) => {
  const [selected, setSelected] = useState(findOption(options, initial) || options[0])
  const selectItem = useSelectItem(setSelected, onChange)

  return <div className={'btn-group'}>
    <Button id={id} selected={selected} />
    <div className={'dropdown-menu'}>
      {options.map(option => <button key={option.name + option.value} onClick={() => selectItem(option)}
                                     className={makeClassName(selected.name === option.name)}>
        <div className={'d-flex justify-content-between'}>
          <div>
            {option.value}
          </div>
          <div>
            {selected.name === option.name && <FontAwesomeIcon icon={faCheck} /> }
          </div>
        </div>
      </button>)}
    </div>
  </div>
}
