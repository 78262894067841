import { useCallback } from 'react'
import { settings } from 'settings/fetching'
import { services } from '@elrondnetwork/dapp-core-internal'
import { useSetLoginToken } from 'auth/hooks/use-set-login-token'
import { useGetIsLoggedIn } from '@elrondnetwork/dapp-core/hooks/account'

const makeUrl = () => `${settings().maiarId}/api/v1`

export const useIdentityInit = () => {
  const setLoginToken = useSetLoginToken()
  const isLoggedIn = useGetIsLoggedIn()

  return useCallback(() => {
    if (!isLoggedIn) {
      services.maiarId.init({ maiarIdApi: makeUrl() }).then(setLoginToken)
    }
  }, [setLoginToken, isLoggedIn])
}
