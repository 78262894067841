import { SwiperWithNavigation } from 'components/swipper-with-navigation'
import { Item } from 'components/carousel/item'
import { SwiperSlide } from 'swiper/react/swiper-react'

export const Content = ({ items, previewSource }) => <SwiperWithNavigation>
  {
    items.map((item, index) => <SwiperSlide key={index}>
      <Item item={item} previewSource={previewSource} />
    </SwiperSlide>)
  }
</SwiperWithNavigation>
