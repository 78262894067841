import { gql } from 'graphql-tag'

export const FEATURED = gql`
query featuredCollections {
  featuredCollections {
    edges {
      cursor
      node {
        name
        collection
        collectionAsset {
          assets (input: { size: 5 }) {
            identifier
            thumbnailUrl
          }
          totalCount
        }
      }
    }
    pageData {
      count
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
`
