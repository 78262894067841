import { useResetData } from 'home/explore-nfts/hooks/use-reset-data'
import { useExploreNfts } from 'home/explore-nfts/nfts/hooks/use-explore-nfts'
import { useSetTags } from 'home/explore-nfts/hooks/use-set-tags'
import { useExploreCollections } from 'home/explore-nfts/collections/hooks/use-explore-collections'
import { useFetchAssetTags } from 'tags/hooks/use-fetch-asset-tags'

export const Behaviour = () => {
  useResetData()
  useExploreNfts()
  useExploreCollections()
  useFetchAssetTags()
  useSetTags()

  return ''
}
