import { useCallback } from 'react'
import { useHasInsufficientFunds } from 'components/forms/buy-sft-form/hooks/use-has-insufficient-funds'

export const useTestBalance = auction => {
  const hasInsufficientFunds = useHasInsufficientFunds(auction)

  return useCallback((value, context) => {
    if (!hasInsufficientFunds(value, context)) return true

    return context.createError({ message: `Insufficient funds`, path: context.path })
  }, [hasInsufficientFunds])
}
