import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useOnHide = easyClose => {
  const location = useCloseLayerLocation()
  const { replace } = useHistory()

  return useCallback(() => {
    easyClose && replace(location)
  }, [easyClose, location, replace])
}
