import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions as dataActions } from 'explore-nfts/collections/slices/get'
import { actions as listActions } from 'explore-nfts/collections/slices/list'

export const useResetState = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(dataActions.setCursor(''))
    dispatch(dataActions.reset({}))
    dispatch(listActions.reset({}))
  }, [dispatch])
}
