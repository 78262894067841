import { ReactComponent as MoonIcon } from 'startup/assets/moon-icon.svg'
import { ReactComponent as SunIcon } from 'startup/assets/sun-icon.svg'
import { useOnClick } from 'components/top-navigation/components/theme-toggle/hooks/use-on-click'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'
import 'components/top-navigation/components/theme-toggle/index.scss'

export const ThemeToggle = () => {
  const isDarkTheme = useIsDarkTheme()

  return <button className={'theme-toggle d-flex align-items-center justify-content-center'}
                 onClick={useOnClick()}>
    {!isDarkTheme ? <MoonIcon /> : <SunIcon />}
  </button>
}
