import { createSlice } from '@reduxjs/toolkit'
import { THEMES } from 'theme/constants/themes'

const initialState = { value: THEMES.light }

export const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    set: (_, { payload }) => ({ value: payload }),
    reset: () => initialState,
  },
})

export const { actions } = theme
