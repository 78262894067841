import { useCallback } from 'react'
import { maiar } from 'auth/slices/maiar'
import { useDispatch } from 'react-redux'

export const useResetLoginToken = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(maiar.actions.resetLoginToken({}))
  }, [dispatch])
}
