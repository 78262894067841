import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { actions } from 'modals/auction/bid/slices/place-bid'
import { nominate } from 'components/conversion'

export const usePlaceBid = auction => {
  const dispatch = useDispatch()

  return useCallback(payload => dispatch(actions.attempt({
    input: {
      auctionId: parseInt(auction.id),
      identifier: auction.identifier,
      price: nominate(payload.price),
    },
  })), [dispatch, auction])
}
