import { useTransaction } from 'transaction/hooks'
import { useCallback, useEffect } from 'react'
import { useResetTransaction } from 'transaction/hooks/use-reset-transaction'
import { useHistory } from 'react-router-dom'
import { useTrackTransactionStatus } from '@elrondnetwork/dapp-core/hooks'
import { useFilters } from 'filters/hooks/use-filters'
import { useIsCanceled } from './use-is-canceled'

const useOnCancelled = ({ previousLocation }) => {
  const { push } = useHistory()
  const reset = useResetTransaction()

  return useCallback(() => {
    reset()
    push(previousLocation)
  }, [push, reset, previousLocation])
}

export const useTransactionRedirect = () => {
  const transaction = useTransaction()
  const { search } = useFilters()
  const { status } = search
  const { replace } = useHistory()
  const isCanceled = useIsCanceled()

  useEffect(() => {
    isCanceled() && replace({ ...transaction.previousLocation, state: { ...transaction.previousLocation.state, hydrated: true } })
  }, [replace, status, transaction.previousLocation, isCanceled])

  useTrackTransactionStatus({
    transactionId: transaction.sessionId,
    onCancelled: useOnCancelled(transaction),
  })
}
