import { get } from 'lodash'
import { GhostCollectionCard } from 'components/ghost-collection-card'
import { Component } from 'components/collection-card/content'
import { useMemo } from 'react'

const useComponent = props => {
  const isGhost = get(props, 'isGhost', false)

  if (isGhost) return GhostCollectionCard

  return Component
}

export const CollectionCard = props => {
  const Component = useComponent(props)

  // eslint-disable-next-line
  return useMemo(() => <Component {...props} />, [props?.collection?.collection, props.ghost])
}
