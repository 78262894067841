import { RollingCollection } from 'components/rolling-collection'
import { Links } from 'home/featured/components/links'
import { useFeaturedCollectionAssets } from 'home/featured/hooks/use-featured-collection-assets'
import { useFeaturedCollection } from 'home/featured/hooks/use-featured-collection'
import { useIsLoading } from 'home/featured/hooks/use-is-loading'
import 'home/featured/index.scss'

export const Content = () => {
  const collection = useFeaturedCollection()
  const assets = useFeaturedCollectionAssets()
  const thumbs = assets.map(item => item.thumbnailUrl)
  const isLoading = useIsLoading()

  return <div className={'container'}>
    <div className={'row'}>
      <div className={'col-12 col-md-6'}>
        <RollingCollection collection={collection} thumbs={thumbs} isLoading={isLoading} aspectRatio={1.0989} />
      </div>
      <div className={'col-12 col-md-6'}>
        <Links />
      </div>
    </div>
  </div>
}
