import { Username } from 'components/username'
import { Cover } from 'components/initial-suggestion-card/cover'
import { Description } from 'components/initial-suggestion-card/description'
import { Action } from 'components/initial-suggestion-card/action'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { UserImage } from 'components/user-image/index'
import defaultAvatar from 'common/default-avatar'
import { Link } from 'react-router-dom'
import { accountPath } from 'common/account-path'
import { usePurgedHerotag } from 'components/username/hooks/use-purged-herotag'
import 'components/initial-suggestion-card/style.scss'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const InitialSuggestionCard = props => {
  const purgedHerotag = usePurgedHerotag(props.herotag)
  const location = useLayeredLocation()
  const path = location(accountPath(props.address, purgedHerotag && `@${purgedHerotag}`), 'middleground')

  return <div className={'col-6 col-md-4 col-xl-3 d-flex'}>
    <div className={'initial-suggestion-card card-item w-100 d-flex'}>
      <Link className={'initial-suggestion-card-link'} to={path} />
      <div className={'d-flex flex-column align-items-center flex-grow-1 w-100'}>
        <Cover address={props.address} cover={props.cover} />
        <div className={'initial-suggestion-card-content flex-grow-1'}>
          <UserImage image={get(props.profile, 'url', defaultAvatar)} />
          <Username herotag={props.herotag} address={props.address} />
          <Description description={props.description} />
          <Action address={props.address} isFollowed={props.isFollowed} onFollow={props.onFollow}
                  onUnfollow={props.onUnfollow} />
        </div>
      </div>
    </div>
  </div>
}

InitialSuggestionCard.defaultProps = {
  isFollowed: false,
  onFollow: () => {
  },
  onUnfollow: () => {
  },
  cover: {},
  description: '',
  herotag: '',
  profile: {},
}

InitialSuggestionCard.propTypes = {
  address: PropTypes.string.isRequired,
  cover: PropTypes.object,
  description: PropTypes.string,
  herotag: PropTypes.string,
  profile: PropTypes.object,
  isFollowed: PropTypes.bool,
  onFollow: PropTypes.func,
  onUnfollow: PropTypes.func,
}
