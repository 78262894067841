import { Loading } from 'loading'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const path = 'exploreNfts.nfts.get.list'

export const useExploreNftsList = () => {
  const items = useSelector(state => get(state, path, []))
  const ghosts = Loading.usePayloads(Loading.ID.explore.page)

  return [...items, ...ghosts]
}
