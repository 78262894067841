import { useEffect } from 'react'

import { usePayload } from 'home/trending/hooks/use-payload'
import { actions } from 'home/trending/slices/get'
import { useDispatch } from 'react-redux'

export const useFetchTrending = () => {
  const payload = usePayload()
  const dispatch = useDispatch()

  useEffect(() => dispatch(actions.attempt(payload())), [payload, dispatch])
}
