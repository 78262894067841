import { CreateCollection } from 'account/create-collection'

const defaultDescription = ``

export const headers = {
  collections: {
    title: 'Collections',
    description: defaultDescription,
    action: CreateCollection,
  },
  // creations: {
  //   title: 'Creations',
  //   description: defaultDescription,
  // },
  collected: {
    title: 'Collected',
    description: defaultDescription,
  },
  auctions: {
    title: 'Auctions',
    description: defaultDescription,
  },
  bids: {
    title: 'Bids',
    description: defaultDescription,
  },
  claimable: {
    title: 'Claimable',
    description: defaultDescription,
  },
}
