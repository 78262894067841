import { AssetList } from 'components/collection-card/header/asset-list'
import { EmptyList } from 'components/collection-card/empty-list'
import { get } from 'lodash'

const numberOfAssets = collection => get(collection, 'collectionAsset.totalCount')

export const Content = ({ collection }) => {
  const hasAssets = parseInt(numberOfAssets(collection)) !== 0

  return hasAssets ? <AssetList collection={collection} /> : <EmptyList />
}
