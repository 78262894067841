import { Logo } from './logo'
import { Description } from './description'
import { SocialLinks } from './social-links'
import { PoweredBy } from './powered-by'

export const Brand = () => <div className={'footer-brand col-12 col-lg-4 mt-5'}>
  <Logo />
  <Description />
  <SocialLinks />
  <PoweredBy />
</div>
