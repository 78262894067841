import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

const makePathname = ({ address, tab = 'collected', variant }) => `/${address}/${tab}/modal/${variant}`

export const useProfileModalPath = variant => {
  const urlParams = useParams()

  return useMemo(() => makePathname({ ...urlParams, variant }), [urlParams, variant])
}
