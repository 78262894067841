import { faCube } from '@fortawesome/pro-light-svg-icons'
import { Empty as EmptyState } from 'components/empty'
import { selector as collectedSelector } from 'account/tabs/assets/collected/hooks/use-items'
import { useSelector } from 'react-redux'

const stateProps = {
  icon: faCube,
  message: `No collected assets yet.`,
}

const useCollectedCount = () => useSelector(collectedSelector).length

export const Empty = () => {
  const collectedCount = useCollectedCount()

  return !collectedCount && <EmptyState {...stateProps} />
}
