import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useRetryCallback = () => {
  const history = useHistory()

  return useCallback(() => {
    history.go(0)
  }, [history])
}
