export const Modal = ({ layer, children }) => <div style={style(layer)}>

  {children}

</div>

const style = layer => ({
  zIndex: layer.zIndex,
  position: 'fixed',
  top: '0',
  height: '100%',
  width: '100%',
  overflow: 'auto',
})
