import { useItems } from 'account/tabs/assets/bids/hooks/use-items'
import { flatten, forEach, groupBy, takeRight } from 'lodash'

export const useFlattenBidsItems = () => {
  const bids = useItems()
  const groupedBids = groupBy(bids, 'node.auctionId')

  const newList = []
  forEach(groupedBids, element => {
    newList.push(takeRight(element))
  })

  return flatten(newList)
}
