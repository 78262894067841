import {
  faFacebookSquare,
  faInstagram,
  faLinkedin, faTelegram,
  faTiktok,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { SocialLink } from 'components/footer/brand/social-links/social-link'

export const SocialLinks = () => <div className={'footer-social-links d-flex footer-social-links mt-4 me-5 pe-5'}>
  <SocialLink icon={faTwitter} url={'https://twitter.com/elrondnetwork'} />
  <SocialLink icon={faFacebookSquare} url={'https://www.facebook.com/ElrondNetwork/'} />
  <SocialLink icon={faInstagram} url={'https://www.instagram.com/elrondnetwork'} />
  <SocialLink icon={faTelegram} url={'https://t.me/ElrondNetwork'} />
  <SocialLink icon={faLinkedin} url={'https://www.linkedin.com/company/elrondnetwork/'} />
  <SocialLink icon={faYoutube} url={'https://www.youtube.com/channel/UCRLKQHcjuWW_-JOZ-DqQTXw'} />
  <SocialLink icon={faTiktok} url={'https://www.tiktok.com/@elrondnetwork'} />
</div>
