import { Creator } from 'components/collection-card/creator'
import { Title } from 'components/collection-card/title'
import { Length } from 'components/collection-card/length'
import { get } from 'lodash'
import 'components/collection-card/details/details.scss'

export const Details = ({ collection }) => {
  const collectionName = get(collection, 'name')

  return <div className={'collection-card-details card-body'}>
    <div className={'row flex-sm-nowrap align-items-center'}>
      <Title value={collectionName} />
      <Length collection={collection} />
    </div>
    <Creator collection={collection} />
  </div>
}

