import { Background } from 'home/cta-section/background'
import { Box } from 'home/cta-section/box'
import { Subtitle } from 'home/cta-section/subtitle'
import { Title } from 'home/cta-section/title'
import 'home/cta-section/index.scss'

const boxes = [
  {
    title: 'Create',
    subtitle: <>
      Start your own NFT collection.
      <br />
      {' '}
      Begin in minutes.
    </>,
  },
  {
    title: 'Explore',
    subtitle: 'Follow other creators and collectors, look for NFTs you love.',
  },
  {
    title: 'Collect',
    subtitle: 'Choose favorite NFTs, buy and add them to your collection.',
  },
]

export const CTASection = () => <div className={'cta-section'}>
  <Background />
  <div className={'container'}>
    <Title />
    <Subtitle />
    <div className={'cta-section-boxes row'}>
      {boxes.map((props, index) => <div key={index} className={'col-12 col-sm-12 col-md-4 col-lg-4 d-flex'}>
        <Box {...props} />
      </div>)}
    </div>
  </div>
</div>
