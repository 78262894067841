import 'explore-nfts/nfts/content/list/item.scss'
import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const Item = ({ item }) => {
  const asset = get(item, 'node', {})
  const auction = get(item, 'node.lowestAuction', {})
  const isGhost = get(item, 'isGhost')

  return <div className={'col-6 col-md-4 col-xl-3 card-item'}>
    <AssetCard previewSource={'explore'} asset={asset} auction={auction} className={'explore-nfts-card small-on-mobile'}
               isGhost={isGhost} showEndedBadge />
  </div>
}
