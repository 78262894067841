import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useIsRouteHydratable } from 'startup/hooks/use-subdomain-hydrate-location/use-is-route-hydratable'

const useWereDependenciesFetched = () => {
  const runningLoading = useSelector(state => get(state, 'nft.auctions.running.loading'))
  const claimableLoading = useSelector(state => get(state, 'nft.auctions.claimable.loading'))
  const ownerLoading = useSelector(state => get(state, 'owners.owners.loading'))

  return !runningLoading && !claimableLoading && !ownerLoading
}

const useWasAssetFetched = () => useSelector(state => get(state, 'nft.nftPage.data.assets.edges'))

const useWasFetched = () => {
  const wasAssetFetched = useWasAssetFetched()
  const wereDependenciesFetched = useWereDependenciesFetched()

  return wasAssetFetched && wereDependenciesFetched
}

export const useShouldHydrate = () => {
  const { location: { state } } = useHistory()
  const wasFetched = useWasFetched()
  const isHydratable = useIsRouteHydratable()

  return !state && wasFetched && isHydratable()
}
