import { useRedirectableLogin } from 'sidebar/connect/hooks/use-redirectable-login'
import { useEffect } from 'react'

export const useLogin = initLogin => {
  const login = useRedirectableLogin(() => {
    initLogin()
  }, [])

  useEffect(() => {
    login()
  }, [login])
}
