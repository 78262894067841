import { createContext, useContext as useReactContext, useState } from 'react'

export const Context = createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [current, setCurrent] = useState(0)
  const [loadingIndex, setLoadingIndex] = useState(0)
  const [loadedThumbs, setLoadedThumbs] = useState([])

  return { current, setCurrent, loadingIndex, setLoadingIndex, loadedThumbs, setLoadedThumbs }
}
