import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useBalance } from 'common/hooks/use-balance'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { denominate } from 'components/conversion'

export const useHasInsufficientFunds = auction => {
  const balance = useBalance()
  const minimum = useMinimum(auction)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(balance) <= (parseFloat(denominate(minimum)) && value)
  }, [balance, minimum])
}
