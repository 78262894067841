import { ActualNftOwner } from 'nft-page/details/other-details/owners/actual-nft-owner'
import { AuctionOwner } from 'nft-page/details/other-details/owners/auction-owner'
import { useSelectedAuction } from 'nft-page/auction/hooks/use-selected-auction'
import { get } from 'lodash'
import { useClaimableAuctions } from 'nft-page/details/other-listings/hooks/use-claimable-auctions'

const useSelectedOrClaimableAuction = () => {
  const selectedAuction = useSelectedAuction()
  const claimableAuction = useClaimableAuctions()

  return selectedAuction || claimableAuction
}

export const NftOwner = ({ asset }) => {
  const lowestAuction = get(asset, 'lowestAuction', '')
  const owner = get(asset, 'owner')
  const selectedOrClaimableAuction = useSelectedOrClaimableAuction()
  const auction = lowestAuction || selectedOrClaimableAuction

  return auction ? <AuctionOwner auction={auction} /> : <ActualNftOwner owner={owner} />
}
