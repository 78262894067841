import { ReactComponent as Arrow } from 'startup/assets/section-arrow-down.svg'
import 'featured-pages/components/header/index.scss'

export const Header = ({ children }) => <div className={'featured-page-header'}>
  <div className={'container'}>
    {children}
    <Arrow className={'featured-page-header-arrow'} />
  </div>
</div>

