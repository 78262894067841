import { get } from 'lodash'
import { useFilters } from 'filters/hooks/use-filters'

export const useIsOnSale = () => {
  const filters = useFilters()
  const backgroundOnSale = get(filters, 'background[on-sale]', false)
  const foregroundOnSale = get(filters, 'foreground[on-sale]', false)

  return get(filters.search, 'on-sale', false) === 'true' || backgroundOnSale || foregroundOnSale
}
