import { useCallback } from 'react'

import { denominate } from 'components/conversion'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useMinimumMessage = auction => {
  const minimum = useMinimum(auction)

  return useCallback(() => `Must be greater or equal than ${denominate(minimum)} ${egldLabel()}`, [minimum])
}
