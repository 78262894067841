import { Link } from 'react-router-dom'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const LayeredLink = ({ path, layer, className, children }) => {
  const makeLocation = useLayeredLocation()

  return <Link to={makeLocation(path, layer)} className={className}>
    {children}
  </Link>
}
