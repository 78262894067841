import { combineReducers } from 'redux'
import { list as auctions } from 'account/tabs/assets/auctions/slices/list'
import { list as bids } from 'account/tabs/assets/bids/slices/list'
import { list as collected } from 'account/tabs/assets/collected/slices/list'
// import { list as creations } from 'account/tabs/assets/creations/slices/list'
import { list as collections } from 'account/tabs/assets/collections/slices/list'
import { list as claimable } from 'account/tabs/assets/claimable/slices/list'

export const tabs = combineReducers({
  auctions: auctions.reducer,
  bids: bids.reducer,
  collected: collected.reducer,
  // creations: creations.reducer,
  collections: collections.reducer,
  claimable: claimable.reducer,
})
