import { useContext } from 'react'
import { MultiRangeContext } from './context'

export const SliderValues = () => {
  const { maxVal, minVal } = useContext(MultiRangeContext)

  return <div className={'multi-range-slider-values'}>
    {`${parseFloat(minVal).toFixed(2)} - ${parseFloat(maxVal).toFixed(2)}`}
    <span className={'multi-range-slider-values-label'}> EGLD</span>
  </div>
}
