import { layers } from 'startup/layers'
import { Sidebar } from 'sidebar'
import { Behaviour } from 'subdomain/layout/behaviour'
import { LayeredRouter } from 'components/layered-router'
import './index.scss'
import { Notifications } from 'notifications'
import { SignTransactionsModals } from '@elrondnetwork/dapp-core/UI'
import { Wrapper as TokenWrapper } from 'auth/wrapper/index'
import { AppContext } from 'startup/context'
import { useContext } from 'react'
import { TopNavigation } from 'subdomain/components/top-navigation'
import { ExtensionEdgeCaseFix } from 'layouts/extension-edge-case-fix'

import { useShouldHideConnect } from 'subdomain/layout/hooks/use-should-hide-connect'
import { useShouldHideAccountSidebar } from 'subdomain/layout/hooks/use-should-hide-account-sidebar'

export const SubdomainLayout = () => {
  const { dappReinit } = useContext(AppContext)
  const shouldHideConnect = useShouldHideConnect()
  const shouldHideAccountSidebar = useShouldHideAccountSidebar()

  return <div className={`subdomain-layout d-flex flex-column min-vh-100`}>
    <TokenWrapper key={dappReinit}>
      <LayeredRouter layers={layers}>
        <Behaviour />
        <ExtensionEdgeCaseFix />
        <TopNavigation hideConnect={shouldHideConnect} hideAccountSidebar={shouldHideAccountSidebar} />
        <Sidebar />
        <Notifications />
        <SignTransactionsModals verifyReceiverScam={false} />
      </LayeredRouter>
    </TokenWrapper>
  </div>
}
