import 'featured-pages/components/text-image/index.scss'

const makeClassName = reversed => `featured-page-text-image ${reversed ? 'reversed' : ''}`

export const TextImage = ({ children, image, reversed }) => <div className={makeClassName(reversed)}>
  <div className={'container'}>
    <div className={'row align-items-center'}>
      <div className={'content-col col-12 col-md-6'}>
        <div className={'featured-page-text-image-counter'} />
        {children}
      </div>
      <div className={'image-col col-12 col-md-6 text-center'}>
        <img src={image} />
      </div>
    </div>
  </div>
</div>
