/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions as listActions } from 'nft-page/details/other-listings/slices/list'
import { actions as selectedAuction } from 'nft-page/auction/slices/selected-auction'
import { actions as ordersList } from 'nft-page/auction/bids/slices/orders'
import { useFetchPrice } from 'auction/price/hooks/use-fetch-price'
import { useExecute as useFetchNft } from 'nft-page/hooks/use-fetch-nft'
import { useExecute as useFetchSelectedAuction } from 'nft-page/auction/hooks/use-fetch-selected-auction'
import { useExecute as useFetchRunning } from 'nft-page/details/other-listings/hooks/use-fetch-running-auctions'
import { useExecute as useFetchClaimable } from 'nft-page/details/other-listings/hooks/use-fetch-claimable-auctions'
import { useExecute as useFetchOrders } from 'nft-page/auction/bids/hooks/use-fetch-asset-orders'
import { useFetchNotifications } from '../../../../notifications/account/hooks/use-fetch-notifications'

const useReset = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(listActions.reset({}))
    dispatch(selectedAuction.reset({}))
    dispatch(ordersList.reset({}))
  }, [dispatch])
}

export const useActionCallback = () => {
  const reset = useReset()
  const fetchPrice = useFetchPrice()
  const fetchNft = useFetchNft()
  const fetchSelectedAuction = useFetchSelectedAuction()
  const fetchRunningAuctions = useFetchRunning()
  const fetchClaimableAuctions = useFetchClaimable()
  const fetchOrders = useFetchOrders()
  const fetchNotifications = useFetchNotifications()

  return useCallback(() => {
    reset()
    fetchNft()
    fetchSelectedAuction()
    fetchRunningAuctions()
    fetchClaimableAuctions()
    fetchPrice()
    fetchOrders()
    fetchNotifications()
  }, [
    reset,
    fetchNft,
    fetchSelectedAuction,
    fetchRunningAuctions,
    fetchClaimableAuctions,
    fetchPrice,
    fetchOrders,
    fetchNotifications,
  ])
}

