import { useHexToHsl } from 'common/hooks/use-hex-to-hsl'
import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsBoxColor = () => {
  const data = useCmsData()
  const boxColor = get(data, 'data.data.attributes.boxColor')
  const { h, s, l } = useHexToHsl(boxColor)

  useEffect(() => {
    if (boxColor) {
      document.body.style.setProperty('--bs-card-bg', boxColor)

      const boxColorHover = `hsl(${h},${s}%, calc(${l}% + 3%))`
      document.body.style.setProperty('--bs-card-bg-hover', boxColorHover)

      const boxBorderColor = `hsl(${h},${s}%, calc(${l}% - 4%))`
      document.body.style.setProperty('--bs-card-border-color', boxBorderColor)

      const boxBorderColorHover = `hsl(${h},${s}%, calc(${l}% - 2%))`
      document.body.style.setProperty('--bs-card-hover-border-color', boxBorderColorHover)

      document.body.style.setProperty('--bs-top-nav-bg', boxColor)
    }
  }, [boxColor, h, l, s])
}
