import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useRefreshCounters } from 'account/tabs/tab-header/tab-menu-item/hooks/use-refresh-counters'
import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'
import { useCurrentLayer } from 'components/layered-router/hooks/use-current-layer'

const makeRoute = ({ address, tab }) => {
  const { hash, omitTabInDestination } = tab
  const suffix = omitTabInDestination ? '' : `/${hash}`

  return `/${address}${suffix}`
}

export const useOnClick = tab => {
  const { address } = useParams()
  const refreshCounters = useRefreshCounters()
  const navigate = useLayeredNavigate()
  const layer = useCurrentLayer()

  return useCallback(() => {
    refreshCounters()

    navigate(makeRoute({ address, tab }), layer)
  }, [refreshCounters, address, tab, navigate, layer])
}
