import { matchPath, useLocation } from 'react-router-dom'

import { routes } from 'startup/routes'

export const useParamsGlobal = () => {
  const { pathname } = useLocation()
  const route = routes.find(route => matchPath(pathname, route.path))

  const match = matchPath(pathname, { path: route?.path })

  return match?.params
}
