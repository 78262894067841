import { useFetchPriceRange } from '../../market/hooks/use-fetch-price-range'
import { useFetchAuctionTags } from '../../tags/hooks/use-fetch-auction-tags'
import { useAppendSearchTag } from '../../tags/hooks/use-append-search-tag'

export const Behaviour = () => {
  useFetchPriceRange()
  useFetchAuctionTags()
  useAppendSearchTag('auctions')

  return ''
}
