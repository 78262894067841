import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { faStar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHeroTag } from 'components/asset-card/hooks/use-hero-tag'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import { Username } from 'components/username/index'

export const Creator = () => {
  const asset = useNftSelector()
  const creatorAddress = useNftSelector('nftPage.data.assets.edges[0].node.creatorAddress')

  return <KeyPair icon={<FontAwesomeIcon icon={faStar} />} title={'Creator'}>
    <div className={'d-inline-flex align-items-center'}>
      <Username herotag={useHeroTag(asset)} address={creatorAddress} linkDestination={'creations'} color={'primary'} />
    </div>
  </KeyPair>
}
