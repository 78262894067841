import { NavLink } from 'react-router-dom'

const menuLink = ' nav-item text-center link-item'

export const MenuLink = ({ link }) => <li className={menuLink}>
  <NavLink to={link.path} className={'nav-link'} exact activeClassName={'nav-link active'}>
    {link.label}
  </NavLink>
</li>

