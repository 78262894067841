import { gql } from 'graphql-tag'

export const TRENDING_SORTED_BY_BIDS = gql`
    query trendingSortedByBids($filters: FiltersExpression, $grouping: Grouping, $pagination: ConnectionArgs) {
        auctionsSortByBids(filters: $filters, grouping: $grouping, pagination: $pagination) {
            pageInfo {
                endCursor
                hasNextPage
            }
            edges {
                cursor
                node {
                    asset {
                        creator{
                            address
                            herotag
                            profile
                        }
                        creatorAddress
                        identifier
                        name
                        ownerAddress
                        owner{
                            address
                            herotag
                            profile
                        }
                        media{
                            thumbnailUrl
                            fileType
                            url
                        }
                        type
                        scamInfo{
                            info
                            type
                        }
                        verified
                    }
                    endDate
                    id
                    identifier
                    maxBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    ownerAddress
                    owner {
                        address
                        profile
                        herotag
                    }
                    status
                    tags
                    topBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                    }
                    type
                }
            }
        }
    }
`
