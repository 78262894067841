import { Route, Switch } from 'react-router-dom'
import { NotFound } from 'not-found'
import { curry } from 'lodash'
import { useRenderRoute } from 'components/layered-router/hooks/use-render-route'

const makeKey = (location, index) => location.state === 'reload' ? Math.random() : index

export const Routes = ({ layer, location }) => {
  const renderRoute = curry(useRenderRoute())

  return <Switch location={location}>
    {layer.routes.map((route, index) => <Route key={makeKey(location, index)} path={route.path} exact={route.exact}
                                               render={renderRoute(route)} />)}
    <Route component={NotFound} />
  </Switch>
}
