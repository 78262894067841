import { useEffect } from 'react'
import { getAccountBalance } from '@elrondnetwork/dapp-core/utils'

export const Behaviour = ({ address, setBalance }) => {
  useEffect(() => {
    getAccountBalance(address)
      .then(setBalance)
  }, [address, setBalance])

  return ''
}
