import match from '@nerds.sh/js-pattern'

import { statuses } from 'components/auction-carousel/constants/statuses'
import { Error } from 'components/auction-carousel/error'
import { Content } from 'components/auction-carousel/content'
import { LoadingSpinner } from 'components/loading-spinner'

export const usePickContent = status => match(status)
  .with(statuses.loading, () => LoadingSpinner)
  .with(statuses.noAssets, () => Error)
  .with(statuses.valid, () => Content)
  .execute()
