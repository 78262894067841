import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useHasMore } from 'account/tabs/assets/auctions/hooks/use-has-more'
import { useNext } from 'account/tabs/assets/auctions/hooks/use-next'
import { Row } from 'components/row'
import { List } from 'account/tabs/assets/auctions/list'
import { Empty } from 'account/tabs/assets/auctions/empty'

export const Content = () => <InfiniteScroll hasMore={useHasMore()} next={useNext()}>
  <Row className={'assets-container'}>
    <List />
    <Empty />
  </Row>
</InfiniteScroll>
