import { useRouteMatch } from 'react-router-dom'

import { routeNames } from 'startup/routes'

const zIndex = (layer, layerLink, route) => route && !layerLink ? 20 : layer.zIndex

export const useComputeZIndex = (layer, layerLink) => {
  const nftPageRoute = useRouteMatch(routeNames.nftPage)

  return { ...layer, zIndex: zIndex(layer, layerLink, nftPageRoute) }
}
