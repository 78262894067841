import { useCallback } from 'react'
import { useBalance } from 'common/hooks/use-balance'
import { denominate } from 'components/conversion'
import { get } from 'lodash'

export const useHasInsufficientFunds = auction => {
  const balance = useBalance()
  const maxBid = denominate(get(auction, 'maxBid.amount', '0'))

  return useCallback((value, context) => {
    const totalEgld = !isNaN(context.parent.quantity) ? parseFloat(value) * parseFloat(context.parent.quantity) : value

    return parseFloat(balance) <= (parseFloat(maxBid) && totalEgld)
  }, [balance, maxBid])
}
