import 'account/profile-cover/image/index.scss'
import defaultCover from 'startup/assets/cover.png'
import { useErrorCallback } from 'account/profile-cover/image/hooks/use-error-callback'
import { get } from 'lodash'
import { useProfileData } from 'account/header/hooks/use-profile-data'

export const Image = () => {
  const currentProfileData = useProfileData()
  const coverImage = get(currentProfileData, 'cover.url') || defaultCover
  const classDefault = !coverImage ? 'default' : ''

  return <img className={`hero-cover-image ${classDefault}`}
              alt={'cover_image'} src={coverImage} onError={useErrorCallback()} />
}

