const set = 'are the one shown on your Ledger device screen now.'

const notSet = 'is the one shown on your Ledger device screen now.'

export const TokenMessage = ({ token }) => {
  const message = token ? set : notSet

  return <p className={'m-0'}>
    {message}
  </p>
}
