import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/bids/slices/get'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'
import { makePayload } from 'account/tabs/assets/bids/hooks/make-payload'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'

export const useInit = () => {
  const dispatch = useDispatch()
  const address = useViewerAddress()
  const updateLoadings = useUpdateLoadings(subdomainTabs.bids)
  const marketplaceKey = useCmsMarketplaceKey()
  useEffect(() => {
    updateLoadings()
    address && marketplaceKey && dispatch(actions.attempt(makePayload({ address, cursor: '', marketplaceKey })))
  }, [updateLoadings, address, dispatch, marketplaceKey])
}

