import 'components/rolling-collection/index.scss'
import { useContext } from 'components/rolling-collection/context'
import { Infos } from 'components/rolling-collection/infos'
import { Link } from 'react-router-dom'

const makeClass = (index, current) => {
  const classname = index === current ? 'active' : ''

  return `rolling-collection-image ${classname}`
}

export const Content = ({ collection, isLoading, aspectRatio = 1 }) => {
  const { loadedThumbs, current } = useContext()
  const padTop = `${100 / (aspectRatio)}%`

  return <Link to={`/collections/${collection?.collection}`} className={'rolling-collection'}
               style={{ paddingTop: padTop }}>
    <div className={'rolling-collection-images'}>
      {loadedThumbs.map((image, index) => <img key={index} src={image}
                                               className={makeClass(index, current)} />)}
    </div>
    {!isLoading && <Infos collection={collection} />}
  </Link>
}

