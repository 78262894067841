import * as loginServices from '@elrondnetwork/dapp-core/hooks/login'

export const useMaiarLogin = ({ logoutRoute, callbackRoute, token }) => {
  const [
    initLoginWithWalletConnect,
    { error },
    { uriDeepLink, walletConnectUri },
  ] = loginServices.useWalletConnectLogin({
    logoutRoute,
    callbackRoute,
    token,
    shouldLoginUser: true,
  })

  return { initLogin: initLoginWithWalletConnect, error, uriDeepLink, walletConnectUri }
}
