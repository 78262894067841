import 'sidebar/my-wallet/balance-item/balance-item.scss'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const BalanceItem = ({ title, egldAmount, usdAmount }) => (
  <div className={'sidebar-balance-item d-flex flex-column align-items-center'}>
    <div className={'sidebar-balance-item-title'}>{title}</div>
    <div className={'sidebar-balance-item-egld'}>
      <span className={'sidebar-balance-item-amount'}>{`${egldAmount}`}</span>
      <span className={'sidebar-balance-item-token'}>{egldLabel()}</span>
    </div>
    <div className={'sidebar-balance-item-usd'}>{`~ ${usdAmount} USD`}</div>
  </div>
)
