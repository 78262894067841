import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from '../watchable-action'
import { get } from 'lodash'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { formatToken } from 'common/format-token'

export const Bid = ({ auction, address }) => {
  const bidClick = useOnClick(auction)
  const onClick = usePermittedAction(bidClick, address)
  const minBidToken = get(auction, 'minBid.token')
  const amount = useMinimum(auction)
  const price = `${(humanize(denominate(amount)) * 1)} ${formatToken(minBidToken)}`

  return <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Bid'}
                          onClick={onClick} className={'btn-secondary'} price={price} />
}
