/* eslint-disable max-lines-per-function */
import { Button } from 'components/button/index'
import { Header } from 'featured-pages/components/header/index'
import { TextImage } from 'featured-pages/components/text-image/index'
import buy from 'startup/assets/master-buy.png'
import sell from 'startup/assets/master-selling.png'
import auction from 'startup/assets/master-auction.png'
import { Footer } from 'featured-pages/components/footer/index'
import { useAccountNavigate } from 'featured-pages/hooks/use-account-navigate'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount/index'

export const Master = () => {
  const { address } = useGetAccountInfo()
  const accountNavigate = useAccountNavigate(`${address}/collections`)

  return <>
    <ScrollToTopOnMount />
    <Header>
      <h1>Master your bid game</h1>
      <p>
        NFTs can be bought and sold. And just like fancy art, it can be auctioned off to the highest bidder.
        Decide what price to pay or sell for, and blockchain automagic will happen at the push of a button.
      </p>
      <Button buttonType={'primary'} to={'/explore'}>Explore NFTs</Button>
    </Header>

    <TextImage image={buy}>
      <h2>Buying NFTs</h2>
      <p>Look around before buying. See if there’s something that catches your eye or captures your imagination.</p>
      <p>When you are ready, either make a bid and wait for the auction to end, or buy out the item.</p>
    </TextImage>

    <TextImage image={sell} reversed>
      <h2>Selling NFTs</h2>
      <p>
        Get a feel of the market before putting an item up for sale.
        Don’t give your most valuable NFT away for nothing.
      </p>
      <p>And don’t try to sell your childhood scribblings for a million dollars.</p>
      <button className={'btn btn-link'} onClick={accountNavigate}>My collection</button>
    </TextImage>

    <TextImage image={auction}>
      <h2>Auction like a pro</h2>
      <p>There’s not much to it, really. As easy as 1, 2, 3:</p>
      <p>
        <strong>
          1. Expand the collection
        </strong>
        <br />
        <strong>2. Click on the NFT</strong>
        <br />
        <strong>3. Start an auction</strong>
      </p>

      <p>
        PS: set a higher “Buy Now” price for impatient collectors”
      </p>
      <button className={'btn btn-link'} onClick={accountNavigate}>Create NFT</button>
    </TextImage>

    <Footer />
  </>
}
