import { isEmpty } from 'lodash'

const onlySign = integers => integers.length === 1 && integers.startsWith('-')

export const ensure = integers => {
  if (isEmpty(integers)) return `0`

  if (onlySign(integers)) return `-0`

  return integers
}
