import { trimHash } from 'sidebar/connect/ledger-login/ledger-container/address-row/trim-hash'

const makeProps = index => ({
  htmlFor: `check_${index}`,
  role: 'button',
  className: 'form-check-label, text-nowrap m-0',
})

export const Label = ({ index, address }) => <label {...makeProps(index)}>
  <div className={'d-flex align-items-center text-nowrap'}>
    <span>{trimHash(address)}</span>
  </div>
</label>
