import { useField } from 'formik'
import Creatable from 'react-select/creatable'
import './input.scss'

const options = [
  { value: 5, label: '5%' },
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
]

const labelFormatter = value => `Other: ${parseFloat(value)}%`

const filterOption = (option, inputValue) => !isNaN(inputValue) && option.label.includes(inputValue)

export const Input = ({ name }) => {
  const [field, , { setValue }] = useField(name)

  return <Creatable formatCreateLabel={labelFormatter} options={options} filterOption={filterOption}
                    name={name} classNamePrefix={'react-select'}
                    value={{ value: field.value, label: `${field.value}%` }}
                    onChange={value => setValue(value.value)}
                    onBlur={field.onBlur} />
}
