import { useEndCursor } from 'account/tabs/assets/collections/hooks/use-end-cursor'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'account/tabs/assets/collections/slices/get'
import { useUpdateLoadings } from 'account/tabs/assets/hooks/use-update-loadings'
import { publicTabs } from 'account/tabs/public-tabs'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { makeInput } from 'account/tabs/assets/collections/make-input'

export const useNext = () => {
  const endCursor = useEndCursor()
  const dispatch = useDispatch()
  const updateLoadings = useUpdateLoadings(publicTabs.collections)
  const address = useViewerAddress()

  return useCallback(() => {
    updateLoadings()

    if (address) {
      dispatch(actions.attempt(makeInput(address, endCursor)))
    }
  }, [address, dispatch, endCursor, updateLoadings])
}

