import { useCurrentPrice } from 'components/asset-card/hooks/use-current-price'
import { humanize } from 'components/conversion/humanize'
// import { ReactComponent as ElrondIconBlue } from 'startup/assets/elrond-logo-blue.svg'
// import { ReactComponent as ElrondIconRed } from 'startup/assets/elrond-logo-red.svg'
// import { ReactComponent as ElrondIconGreen } from 'startup/assets/elrond-logo-green.svg'
// import { ReactComponent as ElrondIconYellow } from 'startup/assets/elrond-logo-yellow.svg'
import 'components/asset-card/price/price.scss'
import { getPriceDetails } from 'common/get-price-details'

// const priceIcon = {
//   elrondapes: <ElrondIconRed />,
//   aquaverse: <ElrondIconGreen />,
//   zoidpay: <ElrondIconYellow />,
//   holoride: <ElrondIconBlue />,
//   '': <ElrondIconBlue />,
// }

export const Price = ({ auction }) => {
  const bid = useCurrentPrice(auction)
  const { token, amount, usdAmount } = getPriceDetails(bid)
  // const marketplaceKey = get(auction, 'marketplaceKey', '')

  return <div className={'asset-card-price justify-content-between'}>
    <div className={'asset-card-price-inner'}>
      {/* {priceIcon[marketplaceKey]} */}
      <div className={'asset-card-prices'}>
        <div className={'asset-card-price-value'}>
          <b>{`${humanize(amount)}`}</b>
          {` ${token}`}
        </div>
        {usdAmount !== '0' && <div className={'asset-card-price-usd'}>{`~ ${usdAmount} USD`}</div>}
      </div>
    </div>
  </div>
}
