import 'account/tabs/tab-header/style.scss'
import { headers } from 'account/tabs/headers'
import { useActiveTab } from 'account/tabs/hooks/use-active-tab'
import { Title } from 'account/tabs/tab-header/title'
import { Action } from 'account/tabs/tab-header/action'

export const TabHeader = () => {
  const header = headers[useActiveTab()]

  return <div className={'tab-header d-flex flex-column flex-md-row align-items-center justify-content-between'}>
    <div>
      <div className={'d-flex flex-row'}>
        <Title header={header} />
      </div>
    </div>
    <Action header={header} />
  </div>
}
