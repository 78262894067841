import { Failure } from 'failure'
import { Content } from 'collections/auctions/content'
import { RenderIf } from 'account/common/render-if'
import { Behaviour } from 'collections/auctions/behaviour'
import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import { useCollectionsSelector } from 'collections/hooks/use-collections-selector'

import { useShouldHideFilters } from 'collections/auctions/hooks/use-should-hide-filters'
import { ContractWrapper } from 'components/contract-wrapper'
import { CollectionFilters } from 'filters/collection-filters'

const getTypeOptionsForCollection = collection => {
  const typeOptions = {
    NonFungibleESDT: [],
    SemiFungibleESDT: [
      { value: 'All', name: '' },
      { value: 'Single', name: 'nft' },
      { value: 'Bundles', name: 'sftAll' },
      { value: 'Variable', name: 'sftOnePerPayment' },
    ],
  }

  return typeOptions[collection ? collection.node.type : 'SemiFungibleESDT']
}

export const Auctions = () => {
  const [collection] = useCollectionsSelector()
  const isOnSale = useIsOnSale()
  const filterByTypeOptions = getTypeOptionsForCollection(collection)
  const shouldHide = useShouldHideFilters()

  return <ContractWrapper fullscreen isOnSale={isOnSale}>
    <RenderIf isTrue={collection && isOnSale}>
      <Behaviour />
      { !shouldHide && <CollectionFilters typeOptions={filterByTypeOptions} />}
      <Failure.Partial id={'collection.auctions'}>
        <Content />
      </Failure.Partial>
    </RenderIf>
  </ContractWrapper>
}
