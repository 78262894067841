import { useEffect } from 'react'
import { useNext } from 'collections/assets/hooks/use-next'

export const useFetchInitial = () => {
  const getInitialPage = useNext('')

  useEffect(() => {
    getInitialPage()
  }, [getInitialPage])
}
