import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Seo } from 'components/seo'
import { Content } from 'subdomain/home/content'
import { Behaviour } from 'subdomain/home/behaviour'

export const HomePage = () => <div className={'subdomain-home'}>
  <ScrollToTopOnMount />
  <Seo title={'Home'} />
  <Behaviour />
  <Content />
</div>
