import PropTypes from 'prop-types'
import 'components/bootstrap-modal/bootstrap-modal.scss'
import { useModal } from 'components/bootstrap-modal/hooks/use-modal'
import { useIsVisible } from 'components/bootstrap-modal/hooks/use-is-visible'
import { Content } from 'components/bootstrap-modal/content'

export const BootstrapModal = ({ id, modalSize, children, className, routeModal = true, easyClose = false }) => {
  useModal(id, easyClose)
  const shouldShow = useIsVisible(id) || !routeModal

  return shouldShow ? <Content id={id} modalSize={modalSize} content={children} className={className} /> : null
}

BootstrapModal.propTypes = { modalSize: PropTypes.string }

BootstrapModal.defaultProps = { modalSize: 'modal-md' }

