import React from 'react'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { AddressConfirmation } from 'sidebar/connect/ledger-login/ledger-container/confirm-address/address-confirmation'
import { TokenConfirmation } from 'sidebar/connect/ledger-login/ledger-container/confirm-address/token-confirmation'
import { TokenMessage } from 'sidebar/connect/ledger-login/ledger-container/confirm-address/token-message'
import { Help } from 'sidebar/connect/ledger-login/ledger-container/confirm-address/help'
import { Info } from 'sidebar/connect/ledger-login/ledger-container/confirm-address/info'

export const ConfirmAddress = ({ token }) => {
  const { ledgerAccount } = useGetAccountInfo()

  return <div className={'m-auto'}>
    <div className={'card my-4 text-center'}>
      <div className={'card-body p-4 mx-lg-4'}>
        <AddressConfirmation ledgerAccount={ledgerAccount} />
        <TokenConfirmation token={token} />
        <TokenMessage token={token} />
        <Info />
        <Help />
      </div>
    </div>
  </div>
}
