import { Title } from 'components/forms/nerds-form/title'
import { faGavel } from '@fortawesome/pro-light-svg-icons'
import { Subtitle } from 'components/forms/nerds-form/subtitle'
import { Price } from 'components/forms/place-bid-form/fieldsets/price'
import { FinalFeeField } from 'components/forms/nerds-form/fields/final-fee-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'
import { get } from 'lodash'
import { useOfferFees } from 'components/forms/nerds-form/fields/hooks/use-offer-fees'
import { usePriceValue } from 'components/forms/nerds-form/fields/hooks/use-price-value'
import { InfoField } from 'components/forms/nerds-form/fields/info-field'

export const Fields = ({ callbackUrl, auction, submitErrors }) => {
  const asset = get(auction, 'asset', {})
  const assetFees = useOfferFees(asset)
  const exampleValue = usePriceValue()

  return <div className={'modal-content'}>
    <Title icon={faGavel} title={'Bid'} margins={'mb-2'} />
    <Subtitle subtitle={`Setting bid price for ${auction.identifier}`} />
    <Price auction={auction} />
    <FinalFeeField asset={asset} labelText={'Owner will receive'} assetFees={assetFees} exampleValue={exampleValue} />
    <DefaultActions callbackUrl={callbackUrl} label={'Bid'} />
    <InfoField label={`Bid's can't be cancelled`} />
    <Errors values={submitErrors} />
  </div>
}
