/* eslint-disable max-lines-per-function */
import { Button } from 'components/button/index'
import { Header } from 'featured-pages/components/header/index'
import { TextImage } from 'featured-pages/components/text-image/index'
import art from 'startup/assets/kickstart-digital-art.png'
import culture from 'startup/assets/kickstart-web3-culture.png'
import value from 'startup/assets/kickstart-new-value.png'
import { Footer } from 'featured-pages/components/footer/index'
import { useAccountNavigate } from 'featured-pages/hooks/use-account-navigate'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount/index'

export const Kickstart = () => {
  const { address } = useGetAccountInfo()
  const accountNavigate = useAccountNavigate(`${address}/collections/modal/create-collection/issue`)

  return <div className={'kickstart featured-page'}>
    <ScrollToTopOnMount />
    <Header>
      <h1>
        Kickstart your NFT Journey
      </h1>
      <p>
        Express yourself on your own terms. Hold the joy of what matters closer to your heart.
        Share with millions of people. Connect with those who feel the same about what is important.
      </p>
      <Button buttonType={'primary'} to={'#'} onClick={accountNavigate}>Create Now</Button>
    </Header>

    <TextImage image={art}>
      <h2>Digital Art</h2>
      <p>
        Technology unleashes creativity. The boundary to what’s possible is no longer physical.
        The measure of an artist’s craft is their ability to dream.
      </p>
    </TextImage>

    <TextImage image={culture} reversed>
      <h2>Web3 Culture</h2>
      <p>
        Showcase your personality with like minded people.
        Celebrate your passions like never before. Support your favorite creators and become one yourself.
      </p>
    </TextImage>

    <TextImage image={value}>
      <h2>A New Value</h2>
      <p>
        Unbound creativity births new opportunities. Tap into the value streams flowing in the Age of Creation.
      </p>
    </TextImage>

    <Footer />

  </div>
}
