import { Title } from 'components/forms/nerds-form/title'
import { faCube } from '@fortawesome/pro-light-svg-icons'
import { Textarea } from 'components/forms/nerds-form/fields/textarea'
import { InputField } from 'components/forms/nerds-form/fields/input-field'
import { TagsField } from 'components/forms/nerds-form/fields/tags-field'

export const Identity = () => <>
  <Title title={'Create SFT'} icon={faCube} />
  <InputField label={'Name'} name={'name'} />
  <Textarea label={'Description'} name={'description'} />
  <TagsField label={'Tags'} name={'tags'} />
</>
