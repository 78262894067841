import { get } from 'lodash'
import { useNftSelector } from 'nft-page/hooks/nft/use-nft-selector'
import { faLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KeyPair } from 'nft-page/details/other-details/key-pair'
import 'nft-page/details/other-details/uris/index.scss'
import { useNftScamInfo } from 'nft-page/hooks/nft/use-nft-scam-info'
import { useNftIsNsfw } from 'nft-page/hooks/nft/use-nft-is-nsfw'

const decode = uri => {
  try {
    return window.atob(uri)
  } catch (err) {
    return ''
  }
}

const getProps = (uri, scamInfo, isNsfw) => ({
  href: decode(uri),
  target: '_blank',
  rel: 'noreferrer',
  className: makeClassName(scamInfo, isNsfw),
})

const makeClassName = (scamInfo, isNsfw) => {
  const classname = 'nft-page-details-uris'

  return scamInfo || isNsfw ? `${classname}-disabled` : classname
}

export const Uris = () => {
  const asset = useNftSelector('nftPage.data.assets.edges[0].node')
  const uris = get(asset, 'uris', [])
  const scamInfo = useNftScamInfo()
  const isNsfw = useNftIsNsfw()

  return <KeyPair icon={<FontAwesomeIcon icon={faLink} />} title={'URIs'}>
    <div className={'w-100'}>
      { uris.map(uri => <a key={uri} {...getProps(uri, scamInfo, isNsfw)}>
        {decode(uri)}
      </a>)}
      {!uris.length && 'N/A'}
    </div>
  </KeyPair>
}
