import { denominate } from 'components/conversion/denominate'
import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'

// TODO: this should be used sitewide
export const useRealTimeUsdPrice = egld => {
  const { price } = useEconomicsSelector()
  const decimals = useDenominationDecimals()

  return egld ? parseFloat(`${price * denominate(egld)}`).toFixed(decimals) : 0
}
