import { settings } from 'settings/fetching'
import { useGetAccountInfo, useGetLoginInfo } from '@elrondnetwork/dapp-core/hooks'
import { AccessTokenManager } from '@elrondnetwork/dapp-core-internal'
import { useOnTokenExpired } from '../hooks/use-on-token-expired'
import { useGetIsLoggedIn } from '@elrondnetwork/dapp-core/hooks/account'

const identity = () => `${settings().maiarId}/api/v1`

export const TokenManager = ({ children }) => {
  const { address } = useGetAccountInfo()
  const { loginMethod, tokenLogin } = useGetLoginInfo()
  const isLoggedIn = useGetIsLoggedIn()

  const props = {
    userAddress: address,
    tokenLogin,
    loggedIn: isLoggedIn,
    loginMethod,
    maiarIdApi: identity(),
    onTokenExpired: useOnTokenExpired(),
  }

  return <AccessTokenManager {...props}>
    {' '}
    {children}
    {' '}
  </AccessTokenManager>
}
