import { useCmsBoxColor } from 'subdomain/cms-content/hooks/use-cms-box-color'
import { useCmsFeatureColor } from 'subdomain/cms-content/hooks/use-cms-feature-color'
import { useCmsPageColor } from 'subdomain/cms-content/hooks/use-cms-page-color'
import { useCmsTheme } from 'subdomain/cms-content/hooks/use-cms-theme'

export const Behaviour = () => {
  useCmsFeatureColor()
  useCmsPageColor()
  useCmsBoxColor()
  useCmsTheme()

  return ''
}
