import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const useCloseLayerNavigate = () => {
  const { replace, goBack, location: { state } } = useHistory()
  const location = useCloseLayerLocation()

  return useCallback(path => {
    if (!state?.hydrated) goBack()
    else replace(path || location)
  }, [state?.hydrated, goBack, replace, location])
}
