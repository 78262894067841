import { Body } from 'sidebar/connect/ledger-login/ledger-container/address-table/body'
import { Head } from 'sidebar/connect/ledger-login/ledger-container/address-table/head'
import { Pagination } from 'sidebar/connect/ledger-login/ledger-container/address-table/pagination'
import { Action } from 'sidebar/connect/ledger-login/ledger-container/address-table/action'
import { Loading } from 'sidebar/connect/ledger-login/ledger-container/address-table/loading'
import { Table } from 'sidebar/connect/ledger-login/ledger-container/address-table/table'
import { Card } from 'sidebar/connect/ledger-login/ledger-container/address-table/card'

const addressesPerPage = 10

const bodyProps = props => ({
  selectedAddress: props.selectedAddress,
  onSelectAddress: props.onSelectAddress,
  startIndex: props.startIndex,
  accounts: props.accounts,
  addressesPerPage,
})

const paginationProps = props => ({
  onGoToPrevPage: props.onGoToPrevPage,
  onGoToNextPage: props.onGoToNextPage,
  startIndex: props.startIndex,
})

const actionProps = props => ({
  onConfirmSelectedAddress: props.onConfirmSelectedAddress,
  selectedAddress: props.selectedAddress,
})

export const AddressTable = props => <>
  <Loading loading={props.loading} />
  <Card>
    <Table>
      <Head />
      <Body {...bodyProps(props)} />
    </Table>
    <Pagination {...paginationProps(props)} />
    <Action {...actionProps(props)} />
  </Card>
</>
