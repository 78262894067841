import { useCallback } from 'react'
import { useShouldThrowMaximum } from 'components/forms/place-bid-form/hooks/use-should-throw-maximum'
import { useMaximumMessage } from 'components/forms/place-bid-form/hooks/use-maximum-message'

export const useTestMaximum = auction => {
  const shouldThrow = useShouldThrowMaximum(auction)
  const makeMessage = useMaximumMessage(auction)

  return useCallback((value, context) => {
    if (!shouldThrow(value)) return true

    return context.createError({ message: makeMessage(context), path: context.path })
  }, [shouldThrow, makeMessage])
}
