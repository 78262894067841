import { useMemo } from 'react'
import { useLastCursor } from 'collections/assets/hooks/use-last-cursor'
import { settings } from 'settings/fetching'
import { useParams } from 'react-router-dom'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const { identifier: collection } = useParams()
  const cursor = useCursor(customCursor)
  const marketplaceKey = useCmsMarketplaceKey()

  return useMemo(() => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { collection },
    marketFilters: marketplaceKey ? { marketplaceKey } : undefined,
  }), [collection, cursor, marketplaceKey])
}
