import { useCallback } from 'react'
import { useFilters } from '../../filters/hooks/use-filters'
import { useLastCursor } from './use-last-cursor'
import { buildFilters } from '../../filters/auctions/filters-logic'

export const useFetchPayloadPreview = () => {
  const { background } = useFilters()
  const cursor = useLastCursor()

  return useCallback(() => buildFilters({ ...background, cursor }), [background, cursor])
}
