import PropTypes from 'prop-types'

import { useValidateItems } from 'components/auction-carousel/hooks/use-validate-items'
import { usePickContent } from 'components/auction-carousel/hooks/use-pick-content'

export const AuctionCarousel = ({ items, previewSource }) => {
  const status = useValidateItems(items)
  const Content = usePickContent(status)

  return items.length ? <Content status={status} items={items} previewSource={previewSource} /> : ''
}

AuctionCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  previewSource: PropTypes.string.isRequired,
}
