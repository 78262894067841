import { useContext, useRef } from 'react'
import { MultiRangeContext } from '../context'
import { SliderRange } from '../slider-range'
import { useOnMaxChanged, useOnMinChanged } from './hooks/use-on-max-changed'

export const Inputs = ({ step, max, min }) => {
  const minValRef = useRef(null)
  const maxValRef = useRef(null)
  const { onChange, setMaxVal, setMinVal, maxVal, minVal } = useContext(MultiRangeContext)

  const onMaxChanged2 = useOnMaxChanged({ setMaxVal, onChange, minVal, maxVal })
  const onMinChanged2 = useOnMinChanged({ setMinVal, onChange, minVal, maxVal })

  return <>
    <SliderRange max={max} min={min} maxVal={maxVal} minVal={minVal} />
    <input type={'range'} name={'minVal'} min={min} max={max} value={minVal}
           ref={minValRef}
           onChange={onMinChanged2} step={step} className={`thumb thumb-left `} />
    <input type={'range'} name={'maxVal'} min={min} max={max} value={maxVal}
           ref={maxValRef}
           onChange={onMaxChanged2} step={step} className={'thumb thumb-right '} />
  </>
}
