import { useSetTag } from 'trending/hooks/use-set-tag'
import { useCallback } from 'react'
import { useRouteFilter } from 'common/hooks/use-route-filter'

export const useOnOptionClick = () => {
  const setTag = useSetTag()
  const routeFilter = useRouteFilter()

  return useCallback(option => {
    routeFilter({ name: 'trending', value: option })
    setTag(option)
  }, [routeFilter, setTag])
}
