import { Button } from 'components/button/index'
import { useAccountNavigate } from 'featured-pages/hooks/use-account-navigate'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import 'featured-pages/components/footer/index.scss'

export const Footer = () => {
  const { address } = useGetAccountInfo()
  const accountNavigate = useAccountNavigate(`${address}/collections/modal/create-collection/issue`)

  return <div className={'featured-page-footer container'}>
    <div className={'featured-page-footer-inner'}>
      <h3>Ready to get started?</h3>
      <p>Bring your NFTs to our marketplace, and connect with collectors.</p>
      <Button onClick={accountNavigate}>Create Now</Button>
    </div>
  </div>
}
