import { from, of } from 'rxjs'
import { history } from 'network'
import { ofType } from 'redux-observable'
import { nftCollections } from 'repositories/nft-collections'
import { failure as failureSlice } from 'failure/slices'
import { mergeMap, catchError } from 'rxjs/operators'
import { actions } from 'home/featured/slices'

export const featured = action$ => action$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(action => execute(action.payload)))

const execute = payload => nftCollections.featured(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = payload => from([
  actions.failure(payload),
  failureSlice.actions.show({ route: history.location.pathname }),
])
