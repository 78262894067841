import { Failure } from 'failure'
import { Seo } from 'components/seo'
import 'account/tabs/assets/collections/style.scss'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/collections/content'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { Create } from 'modals/onboarding/create'
import { VisibleIfOwner } from 'account/common/visible-if-owner'

export const Collections = () => <VisibleIfActive tab={'collections'}>
  <Seo {...useSeoProps('collections')} />
  <Failure.Partial id={'account.collections'}>
    <TabHeader />
    <Content />
    <VisibleIfOwner>
      <Create />
    </VisibleIfOwner>
  </Failure.Partial>

</VisibleIfActive>
