import { useTag } from 'home/trending/hooks/use-tag'
import { useCallback } from 'react'
import { settings } from 'settings/fetching'
import moment from 'moment'

export const usePayload = () => {
  const tag = useTag()

  return useCallback(() => ({
    pagination: { first: settings().home.sectionItems },
    grouping: { groupBy: 'IDENTIFIER' },
    filters: {
      operator: 'AND',
      filters: [
        { field: 'tags', values: [tag], op: 'LIKE' },
        { field: 'status', values: ['Running'], op: 'EQ' },
        { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
      ],
    },
  }), [tag])
}
