import { useHadSuccess } from 'modals/account/edit/hooks/use-act-on-description-success/use-had-success'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'modals/account/edit/description/slices/update-description'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useActOnDescriptionSuccess = () => {
  const hadSuccess = useHadSuccess()
  const { replace } = useHistory()
  const address = useViewerAddress()
  const dispatch = useDispatch()

  return useCallback(() => {
    if (hadSuccess) {
      dispatch(actions.reset())
      replace(`/${address}`)
    }
  }, [address, dispatch, hadSuccess, replace])
}
