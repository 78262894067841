import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'

const initialState = {
  attempt: null,
  data: {},
  failure: null,
  loading: false,
}

const attempt = (state, action) => ({ ...state, loading: true, attempt: action.payload })

const success = (state, { payload }) => ({ ...state, loading: false, data: payload.data })

const failure = (state, { payload }) => ({ ...state, loading: false, failure: serializeErrors(payload) })

const reset = () => initialState

export const priceRange = createSlice({
  initialState,
  name: 'filters/auctions/priceRange',
  reducers: {
    attempt,
    success,
    failure,
    reset,
  },
})

export const { actions } = priceRange
