import { useEffect } from 'react'
import axios from 'axios'
import { useInternalAccessToken } from './use-internal-access-token'
import { settings } from 'settings/fetching'

const shouldHandle = config => {
  const urlMatches = config?.url?.startsWith(settings().elrondApi)

  return urlMatches
}
/* eslint-disable */
export const useSetupInterceptors = () => {
  const token = useInternalAccessToken()
  const fetchToken = async () => axios.get(`${settings().extrasApi}/access`).then((res) => res.data)
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(async config => {

      if (shouldHandle(config)) {
        let intermToken = token ? token : await fetchToken()
        config.headers.authorization = `Bearer ${intermToken}`
      }

      return config
    })

    return () => axios.interceptors.request.eject(interceptor)
  }, [token])
}
/* eslint-enable */
