import { useSelectedTag } from 'explore-nfts/hooks/use-selected-tag'
import { useCallback } from 'react'
import { settings } from 'settings/fetching'

export const useFetchPayloadPreview = () => {
  const selectedTag = useSelectedTag()

  return useCallback(({ cursor }) => {
    const tags = selectedTag ? [selectedTag] : undefined

    return ({
      pagination: { first: settings().listRequestSize, after: cursor },
      filters: { tags },
    })
  }, [selectedTag])
}
