import { useHistory, matchPath } from 'react-router-dom'
import { routeNames } from 'startup/routes'

export const useIsNftPageOpened = () => {
  const { location } = useHistory()

  return matchPath(location.pathname, {
    path: routeNames.nftPage,
    exact: false,
  })
}
