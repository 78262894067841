import { Wrapper } from 'home/trending/wrapper'
import { Behaviour } from 'home/trending/behaviour'
import { AuctionCarousel } from 'components/auction-carousel'
import { useAuctions } from 'home/trending/hooks/use-auctions'
import { Empty } from 'home/trending/empty'
import { ContractWrapper } from 'components/contract-wrapper'
import { TagsFilter } from 'home/trending/tags-filter'
import { DataCard } from 'components/data-card'
import { useMarketplaceContractIsPaused } from 'marketplaces/hooks/use-marketplace-contract-is-paused'

export const Trending = () => {
  const auctions = useAuctions()
  const isContractPaused = useMarketplaceContractIsPaused()

  return <DataCard title={'Trending Auctions'} showAllLink={'/trending'}
                   filterComponent={!isContractPaused && <TagsFilter />}>
    <ContractWrapper fullscreen={false}>
      <Behaviour />
      <Wrapper>
        <Empty />
        <AuctionCarousel previewSource={'home-trending'} items={auctions} />
      </Wrapper>
    </ContractWrapper>
  </DataCard>
}
