import 'home/featured/components/featured-link/index.scss'
import { Link } from 'react-router-dom'

export const FeaturedLink = ({ title, subtitle, image, link = '#' }) => <div className={'col-6'}>
  <Link to={link} className={'featured-link'}>
    <div className={'featured-link-background'} style={{ backgroundImage: `url(${image})` }} />
    <div className={'featured-link-gradient'} />
    <div className={'featured-link-content'}>
      <div className={'featured-link-subtitle'}>{subtitle}</div>
      <div className={'featured-link-title'}>{title}</div>
    </div>
  </Link>
</div>
