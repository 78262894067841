import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'home/explore-nfts/slices/collections'
import { settings } from 'settings/fetching'
import { useContext } from 'home/explore-nfts/context'

export const useExploreCollections = () => {
  const dispatch = useDispatch()
  const { selected } = useContext()
  useEffect(() => {
    !selected && dispatch(actions.attempt({ pagination: { first: settings().home.sectionItems } }))
  }, [dispatch, selected])
}
