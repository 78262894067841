import { gql } from 'graphql-tag'

export const ASSET = gql`
    query getAsset($filters: AssetsFilter, $pagination: ConnectionArgs, $marketplaceFilters: MarketplaceFilters) {
        assets(filters: $filters, pagination: $pagination)
        {
            edges {
                cursor
                node{
                    lowestAuction(filters: $marketplaceFilters){
                        availableTokens
                        collection
                        endDate
                        id
                        identifier
                        marketplaceKey
                        marketplace{
                            name
                            url
                        }
                        maxBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        minBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        minBidDiff{
                            amount
                        }
                        nrAuctionedTokens
                        orders{
                            edges{
                                cursor
                            }
                        }
                        ownerAddress
                        owner{
                            address
                            profile
                            herotag
                        }
                        startDate
                        status
                        tags
                        topBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        topBidder{
                            address
                            profile
                            herotag
                        }
                        type
                    }
                    balance
                    collection
                    creationDate
                    creatorAddress
                    creator{
                        address
                        description
                        herotag
                        profile
                        socialLinks{
                            type
                            url
                        }
                    }
                    identifier
                    metadata{
                        description
                        attributes {
                            attribute {
                                key
                                value
                            }
                        }
                    }
                    rarity {
                        rank
                        score
                    }
                    isNsfw
                    media {
                        fileType
                        thumbnailUrl
                        url
                    }
                    name
                    ownerAddress
                    owner{
                        address
                        description
                        herotag
                        profile
                        socialLinks{
                            type
                            url
                        }
                    }
                    royalties
                    tags
                    supply
                    type
                    uris
                    viewsCount
                    scamInfo{
                        info
                        type
                    }
                    verified
                    marketplaces{
                        address
                        name
                        url
                        key
                    }
                }
            }
            pageData{
                count
            }
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
