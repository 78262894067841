import { graph, rest } from 'network'
import { settings } from 'settings/fetching'
import { STATS } from 'repositories/accounts/queries/stats'
import { social } from 'repositories/accounts/social'

const byUsername = payload => rest().get(`${settings().elrondApi}/usernames/${payload}`)
const byAddress = payload => rest().get(`${settings().elrondApi}/accounts/${payload}`)
const getStats = payload => graph().query({ query: STATS, variables: payload })

const byIdMaiarUsername = payload => rest().get(`${settings().maiarId}/api/v1/herotags/${payload}/address`, {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const byIdMaiarAddress = payload => rest().get(`${settings().maiarId}/api/v1/users/${payload}`)

export const accounts = { byUsername, byAddress, getStats, byIdMaiarUsername, byIdMaiarAddress, social }

