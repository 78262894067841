import { DropdownFilter } from '../dropdown-filter'
import { useFilters } from '../../hooks/use-filters'
import { useRouteFilter } from '../../../common/hooks/use-route-filter'

export const SortBy = ({ className }) => {
  const options = [
    { value: 'Newest', name: 'newest' },
    { value: 'Oldest', name: 'oldest' },
    { value: 'Lowest price', name: 'lowestPrice' },
    { value: 'Highest price', name: 'highestPrice' },
    { value: 'Ending first', name: 'endingFirst' },
  ]

  const { search } = useFilters()
  const changeRoute = useRouteFilter()

  return <DropdownFilter label={'Sort by'} initial={search.sortBy} options={options} id={'market-sortby-filter'}
                         onChange={option => {
                           changeRoute({ name: 'sortBy', value: option.name })
                         }} className={className} />
}
