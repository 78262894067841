import { useCallback } from 'react'
import { denominate } from 'components/conversion'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'

export const useShouldThrow = auction => {
  const minimum = useMinimum(auction)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(value) < parseFloat(denominate(minimum))
  }, [minimum])
}
