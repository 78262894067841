import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'
import { useViewerUsername } from 'viewer/hooks/use-viewer-username'

import { useLayeredNavigate } from 'components/layered-router/hooks/use-layered-navigate'

export const useOnClick = () => {
  const { tab } = useParams()
  const address = useViewerAddress()
  const username = useViewerUsername()
  const navigate = useLayeredNavigate()

  return useCallback(() => {
    const path = `/${username || address}/${tab}/modal/create-collection/issue`
    navigate(path, 'modal')
  }, [address, navigate, tab, username])
}
