import { Header } from 'nft-page/header'
import { Auction } from 'nft-page/auction'
import { Details } from 'nft-page/details'
import { Wrapper } from 'nft-page/content/wrapper'
import { SocialStats } from 'nft-page/social-stats'
import { OtherListings } from 'nft-page/details/other-listings'
import 'nft-page/content/index.scss'

export const Content = () => <Wrapper>
  <Header />
  <Auction />
  <SocialStats />
  <OtherListings />
  <Details />
</Wrapper>
