import { ReactComponent as Lighting } from 'startup/assets/lightning-icon.svg'

export const MobileContent = ({ uriDeepLink }) => <div className={'d-flex justify-content-center align-items-center'}>
  <a id={'accessWalletBtn'} className={'btn btn-primary'}
     href={uriDeepLink || undefined}
     rel={'noopener noreferrer nofollow'}
     target={'_blank'}>
    <Lighting style={{
      width: '0.9rem',
      height: '0.9rem',
    }} />
    Maiar Login
  </a>
</div>
