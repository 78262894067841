import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useTopNavigationContext } from 'components/top-navigation/context'
import { useDynamicSearchReset } from 'search/hooks/use-dynamic-search-reset'

export const useResetSearch = () => {
  const { setFieldValue } = useFormikContext()
  const { setSelectedResult } = useTopNavigationContext()
  const { listen } = useHistory()
  const resetSearch = useDynamicSearchReset()

  useEffect(() => listen(() => {
    setFieldValue('search', '')
    setSelectedResult(-1)
    resetSearch()
  }), [listen, resetSearch, setSelectedResult, setFieldValue])
}
