import { get } from 'lodash'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useLayerNames } from 'components/layered-router/hooks/use-layer-names'

const updateLayerLink = ({ layerLink, layerNames, layerName, path }) => {
  let reached = false
  const newLayerLink = { ...layerLink }

  layerNames.forEach(name => {
    if (reached) {
      delete newLayerLink[name]
    }

    if (name === layerName) {
      newLayerLink[name] = path
      reached = true
    }
  })

  return newLayerLink
}

const shouldUseLayers = layerName => layerName && layerName !== 'background'

const keepHydrating = (previousHydrated, layerName) => previousHydrated && shouldUseLayers(layerName)

export const useLayeredLocation = () => {
  const layerNames = useLayerNames()
  const { location: { search, pathname, state } } = useHistory()
  const layerLink = get(state, 'layerLink', { background: `${pathname}${search || ''}` })

  return useCallback((path, layerName, previewSource) => {
    if (!shouldUseLayers(layerName)) return path

    const layerWithPreviewSource = { ...layerLink, ...(previewSource && { previewSource }) }

    const newLayerLink = updateLayerLink({ layerLink: layerWithPreviewSource, layerNames, layerName, path })

    return {
      pathname: path,
      state: {
        ...state,
        forceReset: false,
        hydrated: keepHydrating(state?.hydrated, layerName),
        layerLink: newLayerLink,
      },
    }
  }, [layerLink, state, layerNames])
}
