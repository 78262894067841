import moment from 'moment'

export const byTypeFiltersMap = {
  none: { field: 'type', values: ['None'], op: 'EQ' },
  nft: { field: 'type', values: ['Nft'], op: 'EQ' },
  sftAll: { field: 'type', values: ['SftAll'], op: 'EQ' },
  sftOnePerPayment: { field: 'type', values: ['SftOnePerPayment'], op: 'EQ' },
}

export const filters = ({ tag, byType }) => {
  const filters = [
    { field: 'status', values: ['Running'], op: 'EQ' },
    { field: 'tags', values: [tag], op: 'LIKE' },
    { field: 'startDate', values: [`${moment().unix()}`], op: 'LE' },
  ]
  byTypeFiltersMap[byType] && filters.push(byTypeFiltersMap[byType])

  return filters
}
