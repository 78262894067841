import { createSlice } from '@reduxjs/toolkit'
import { serializeErrors } from 'common/serialize-errors'
import { uniqBy } from 'lodash'

const initialState = {
  attempt: null,
  success: {
    data: {
      auctions: {
        edges: [],
        pageInfo: { hasNextPage: false, endCursor: '' },
        pageData: '',
      },
    },
  },
  list: [],
  failure: null,
  loading: false,
}

const attempt = (state, action) => ({ ...state, loading: true, attempt: action.payload })

const success = (state, { payload }) => ({ ...state, loading: false, success: payload })

const failure = (state, { payload }) => ({ ...state, loading: false, failure: serializeErrors(payload) })

const append = (state, { payload }) => ({ ...state, list: uniqBy([...state.list, ...payload], 'node.id') })

const reset = () => initialState

export const market = createSlice({
  initialState,
  name: 'market',
  reducers: {
    attempt,
    success,
    failure,
    append,
    reset,
  },
})

export const { actions } = market
