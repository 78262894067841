import { Info } from 'sidebar/connect/ledger-login/ledger-container/ledger-connect/info'
import { Error } from 'sidebar/connect/ledger-login/ledger-container/ledger-connect/error'
import { Connect } from 'sidebar/connect/ledger-login/ledger-container/ledger-connect/connect'

export const LedgerConnect = ({ onClick, error }) => <div className={'m-auto'}>
  <div className={'card my-4 text-center border-0'}>
    <div className={'card-body p-4 mx-lg-4'}>
      <Info />

      <div>
        <Error error={error} />
        <Connect onClick={onClick} />
      </div>
    </div>
  </div>
</div>
