import React from 'react'
import { actionTypes } from 'action-statuses/actions-types'
import { ButtonSecondary } from 'components/button-secondary'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { useOnClick } from 'collections/actions/set-roles/hooks/use-on-click'

const CLASS_NAME = 'justify-content-center'

export const SetRolesButton = ({ collection }) => {
  const status = useStatus(collection.node, actionTypes.setRoles)

  return <ButtonSecondary icon={'cubes'} onClick={useOnClick()} className={CLASS_NAME} disabled={!!status}>
    Set Roles
  </ButtonSecondary>
}
