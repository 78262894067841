import 'components/collection-stats/item/index.scss'
import { denominate } from 'components/conversion'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

const featuredClass = featured => featured ? 'featured' : ''

const classname = 'collection-stats-item col-6 col-sm-4 col-lg-3 col-xl-auto'

const makeClassName = featured => `${classname} ${featuredClass(featured)}`

export const Item = ({ label, value, isPrice, featured }) => <div className={makeClassName(featured)}>
  <span className={'collection-stats-item-value'}>
    <span className={'collection-stats-item-value-value'}>{isPrice ? denominate(value, 2) : value}</span>
    {' '}
    {isPrice ? egldLabel() : ''}
  </span>
  <span className={'collection-stats-item-label'}>{label}</span>
</div>
