import { follow } from 'repositories/feed/handle-by-group/follow'
import { like } from 'repositories/feed/handle-by-group/like'
import { bid } from 'repositories/feed/handle-by-group/bid'
import { won } from 'repositories/feed/handle-by-group/won'
import { mintNft } from 'repositories/feed/handle-by-group/mint-nft'
import { startAuction } from 'repositories/feed/handle-by-group/start-auction'
import { photoChanged } from 'repositories/feed/handle-by-group/photo-changed'
import { buy } from 'repositories/feed/handle-by-group/buy'

const variants = {
  follow,
  like,
  bid,
  won,
  mintNft,
  startAuction,
  photoChanged,
  buy,
}

export const handleByGroup = group => variants[group.key](group)
