import { Empty as EmptyState } from 'components/empty'
import { useIsLoading } from 'home/explore-nfts/collections/hooks/use-is-loading'
import { faCompass } from '@fortawesome/pro-light-svg-icons'
import { useExploreCollectionsEdges } from 'home/explore-nfts/collections/hooks/use-explore-collections-edges'

const stateProps = {
  icon: faCompass,
  message: 'No collections.',
}

export const Empty = () => {
  const data = useExploreCollectionsEdges()
  const isLoading = useIsLoading()

  return !isLoading && !data.length && <EmptyState {...stateProps} />
}
