import { gql } from 'graphql-tag'

export const ACCOUNT_CREATIONS = gql`
    query accountCreations($filters: AssetsFilter, $pagination: ConnectionArgs) {
        assets(filters: $filters, pagination: $pagination)
        {
            edges {
                cursor
                node{
                    lowestAuction{
                        collection
                        endDate
                        id
                        identifier
                        marketplaceKey
                        maxBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        minBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        startDate
                        status
                        topBid {
                            amount
                            token
                            usdAmount
                            tokenData{
                                decimals
                                symbol
                            }
                        }
                        topBidder{
                            address
                        }
                        type
                    }
                    creatorAddress
                    creator{
                        address
                        herotag
                        profile
                    }
                    identifier
                    media {
                        fileType
                        thumbnailUrl
                        url
                    }
                    name
                    ownerAddress
                    owner{
                        address
                        herotag
                        profile
                    }
                    type
                    scamInfo{
                        info
                        type
                    }
                    isNsfw
                    verified
                }
            }
            pageData{
                count
            }
            pageInfo{
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
