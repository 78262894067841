import { useCallback } from 'react'
import { useCounters } from 'sidebar/my-wallet/menu/hooks/use-counters'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCurrentProfileHerotag } from 'current-profile/hooks/use-current-profile-herotag'
import { useCurrentProfilePrivacy } from 'current-profile/hooks/use-current-profile-privacy'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

const makeAccountPath = ({ herotag, address, tab, omitTabInDestination }) => {
  const prefix = herotag || address
  const suffix = omitTabInDestination ? '' : `/${tab}`

  return `/${prefix}${suffix}`
}

const useMakeModalLocation = () => {
  const layeredLocation = useLayeredLocation()

  return useCallback(({ herotag, address, modal }) => {
    const prefix = herotag || address
    const suffix = `/modal/${modal}`

    return layeredLocation(`/${prefix}${suffix}`, 'modal')
  }, [layeredLocation])
}

const useMakeLocation = () => {
  const makeModalLocation = useMakeModalLocation()

  return useCallback(props => {
    const accountPath = makeAccountPath(props)
    const layeredModalLocation = makeModalLocation(props)

    return props.tab ? accountPath : layeredModalLocation
  }, [makeModalLocation])
}

export const useMakeCounteredLink = () => {
  const { address } = useGetAccountInfo()
  const counters = useCounters()
  const currentProfileHerotag = useCurrentProfileHerotag()
  const privacy = useCurrentProfilePrivacy()
  const herotag = privacy === 'private' ? '' : currentProfileHerotag
  const makeLocation = useMakeLocation()

  return useCallback(({ id, label, tab, modal, omitTabInDestination }) => ({
    label,
    to: makeLocation({ herotag, address, tab, modal, omitTabInDestination }),
    counter: counters[id],
  }), [address, counters, herotag, makeLocation])
}
