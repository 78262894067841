import { useEffect, useRef } from 'react'
import { useGetPercentage } from './hooks/use-get-percentage'

export const SliderRange = ({ max, min, minVal, maxVal }) => {
  const range = useRef(null)

  const getPercentage = useGetPercentage({ max, min })

  useEffect(() => {
    const minPercent = getPercentage(minVal)
    const maxPercent = getPercentage(+maxVal)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, maxVal, getPercentage, range])

  return <div ref={range} className={'multi-range-slider-range'} />
}
