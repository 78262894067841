import { denominate } from 'components/conversion'
import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { humanize } from 'components/conversion/humanize'

export const useBalance = () => {
  const { account } = useGetAccountInfo()

  const { price } = useEconomicsSelector()
  const egld = denominate(account.balance)
  const decimals = useDenominationDecimals()

  return {
    egld: humanize(egld),
    usd: parseFloat(`${price * egld}`).toFixed(decimals),
  }
}
