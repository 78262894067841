import { gql } from 'graphql-tag'

export const GET_CLAIMABLE_AUCTIONS = gql`
    query claimableAuctions($sorting: [Sorting!], $filters: FiltersExpression, $grouping: Grouping, $pagination: ConnectionArgs) {
        auctions(sorting: $sorting, filters: $filters, grouping: $grouping, pagination: $pagination)
        {
            edges {
                node {
                    asset{
                        attributes
                        balance
                        collection
                        creationDate
                        creator{
                            address
                            profile
                            herotag
                        }
                        creatorAddress

                        identifier
                        metadata{
                            description
                        }
                        name
                        ownerAddress
                        owner{
                            address
                            profile
                            herotag
                        }
                        royalties
                        tags
                        media{
                            thumbnailUrl
                            fileType
                            url
                        }
                        type
                        uris
                    }
                    availableTokens
                    collection
                    endDate
                    id
                    identifier
                    marketplaceKey
                    marketplace{
                        name
                        url
                    }
                    maxBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBidDiff{
                        amount
                    }
                    nrAuctionedTokens
                    orders{
                        edges{
                            cursor
                            node{
                                auctionId
                                from{
                                    address
                                    herotag
                                    profile
                                }
                                id
                                ownerAddress
                                creationDate
                                price{
                                    amount
                                    token
                                    usdAmount
                                    tokenData{
                                        decimals
                                        symbol
                                    }
                                }
                            }
                        }
                        pageData{
                            count
                        }
                        pageInfo{
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                    }
                    ownerAddress
                    owner{
                        address
                        profile
                        herotag
                    }
                    startDate
                    status
                    tags
                    topBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                        profile
                        herotag
                    }
                    type
                }
                cursor
            }
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            pageData{
                count
            }
        }
    }
`
