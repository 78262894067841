import { CreateNftAuctionForm } from 'components/forms/create-nft-auction-form'
import { useCreateSingleAuction } from 'modals/auction/start/hooks/use-create-single-auction'
import { useIsLoading } from 'modals/auction/start/hooks/use-is-loading'
import { useErrors } from 'modals/auction/start/hooks/use-errors'
import { BootstrapModal } from 'components/bootstrap-modal'
import { actions } from './slices/create-auction'
import { useData } from './hooks/use-data'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { settings } from 'settings/fetching'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

const egldLabel = () => settings().dapp.egldLabel

const initialValues = ({ identifier }) => ({
  identifier,
  paymentToken: egldLabel(),
  maxOneSftPerPayment: false,
  quantity: '1',
})

const useProps = (asset, errors) => ({
  onSubmit: useCreateSingleAuction(asset),
  submitErrors: errors,
  loading: useIsLoading(),
  callbackUrl: useCloseLayerLocation(`/nfts/${asset.identifier}`),
  initialValues: initialValues(asset),
  asset,
})

export const StartNftAuction = ({ asset }) => {
  const data = useData()
  const actionIdentifier = createActionIdentifier(asset, actionTypes.startAuction)
  const errors = useErrors()

  useOnSentPayload({
    data,
    callbackUrl: useCloseLayerLocation(`/nfts/${asset.identifier}`),
    resetAction: actions.reset,
    actionIdentifier,
  })
  const props = useProps(asset, errors)

  return <BootstrapModal id={'create-nft-auction'}>
    {asset.identifier && <CreateNftAuctionForm {...props} />}
  </BootstrapModal>
}
