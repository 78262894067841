import { Wrapper } from './wrapper'
import { Filters } from 'home/explore-nfts/filters'
import { Content } from 'home/explore-nfts/content'
import { DataCard } from 'components/data-card'

export const ExploreNFTs = () => <DataCard title={'Explore Amazing Collections'} showAllLink={'/explore'}>
  <Wrapper>
    <Filters />
    <Content />
  </Wrapper>
</DataCard>

