import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useAuthorizedAction } from 'common/hooks/use-authorized-action'

export const AuthorizedLink = ({ link }) => {
  const { push } = useHistory()

  const onNavigate = useCallback(() => push(link.href), [link.href, push])

  const authorizedAction = useAuthorizedAction(onNavigate)

  return <div className={'footer-section-link'} onClick={authorizedAction}>
    {link.title}
  </div>
}
