import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const className = 'd-flex justify-content-center mt-2'

export const Pagination = ({ startIndex, onGoToNextPage, onGoToPrevPage }) => <div className={className}>
  <button type={'button'}
          className={'btn btn-link mx-2'}
          onClick={onGoToPrevPage}
          disabled={startIndex === 0}>
    <FontAwesomeIcon size={'sm'} icon={faChevronLeft} />
    {' '}
    Prev
  </button>
  <button type={'button'}
          className={'btn btn-link mx-2'}
          onClick={onGoToNextPage}>
    Next
    {' '}
    <FontAwesomeIcon size={'sm'} icon={faChevronRight} />
  </button>
</div>
