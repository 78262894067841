import { formatToken } from 'common/format-token'
import { denominate } from 'components/conversion'

export const SuffixLabel = ({ whom }) => <>
  NFT for
  {' '}
  <strong>{denominate(whom.price.amount)}</strong>
  {' '}
  {formatToken(whom.price.token)}
  {' '}
</>
