import { useIsScamOrNsfw } from 'nft-page/auction/hooks/use-is-scam-or-nsfw'
import { SellButton } from 'nft-page/auction/actions/sell/sell-button'

export const Sell = ({ asset, address }) => {
  const isScamOrNotSafeForWork = useIsScamOrNsfw(asset)

  return <div className={'col nft-page-auction'}>
    {!isScamOrNotSafeForWork ? <SellButton asset={asset} address={address} /> : ''}
  </div>
}

Sell.displayName = 'Sell'
