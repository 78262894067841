import * as lodash from 'lodash'
import { TabMenuItem } from 'account/tabs/tab-header/tab-menu-item'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { FreeMode } from 'swiper'
import { Divider } from 'account/divider'
import 'account/tabs/tabs.scss'
import { useIsSubdomain } from '../../startup/hooks/use-is-subdomain'
import { subdomainTabs } from 'account/tabs/subdomain-tabs'
import { publicTabs } from 'account/tabs/public-tabs'

SwiperCore.use([FreeMode])

export const List = () => {
  const tabs = useIsSubdomain() ? subdomainTabs : publicTabs

  return <div className={'account-tabs-header'}>
    <Swiper freeMode slidesPerView={'auto'}>
      {lodash
        .values(tabs)
        .map(tab => <SwiperSlide key={tab.hash}><TabMenuItem tab={tab} /></SwiperSlide>)}
    </Swiper>
    <Divider />
  </div>
}
