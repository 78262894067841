import { useIsActiveUser } from 'common/hooks/use-is-active-user'
import { useEffect } from 'react'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'
import { useHistory } from 'react-router-dom'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useRedirectOnNotActiveUser = () => {
  const isActiveUser = useIsActiveUser()
  const address = useViewerAddress()
  const location = useCloseLayerLocation()
  const { replace } = useHistory()

  useEffect(() => {
    const shouldRedirect = address && !isActiveUser

    shouldRedirect && replace(location)
  }, [address, isActiveUser, location, replace])
}
