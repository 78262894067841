import { useShouldRedirect } from 'account/account-wrapper/hooks/use-should-redirect'
import { useDestination } from 'account/account-wrapper/hooks/use-destination'
import { Redirect } from 'react-router-dom'

export const RedirectToHerotag = ({ children }) => {
  const shouldRedirect = useShouldRedirect()
  const destination = useDestination()

  return shouldRedirect ? <Redirect to={destination} /> : children
}
