import { List } from 'explore-nfts/collections/content/list'
import { useNext } from 'explore-nfts/collections/hooks/use-next'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useExploreCollectionsList } from 'explore-nfts/collections/hooks/use-explore-collections-list'
import { DataCard } from 'components/data-card'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Empty } from 'explore-nfts/collections/empty'
import { useIsLoading } from 'explore-nfts/collections/hooks/use-is-loading'
import { useHasMore } from 'explore-nfts/collections/hooks/use-has-more'
import { Behaviour } from 'explore-nfts/collections/behaviour'
import { Filters } from 'explore-nfts/filters'
import 'explore-nfts/collections/content/index.scss'

const cardProps = () => ({ title: 'Explore NFTs' })

export const Content = () => {
  const collections = useExploreCollectionsList()
  const isLoading = useIsLoading()

  return <div className={'explore-nfts'}>
    <Behaviour />
    <ScrollToTopOnMount />
    <DataCard {...cardProps()}>
      <Filters />
      <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
        <List collections={collections} />
      </InfiniteScroll>
      <Empty />
    </DataCard>
  </div>
}
