import { get } from 'lodash'

export const useExtractPreviewAssets = collection => {
  const assets = get(collection, 'collectionAsset.assets', []) || []

  const first = assets.slice(0, 4)

  const emptyAssets = Array(4 - first.length).fill({})

  return [...first, ...emptyAssets]
}
