import PropTypes from 'prop-types'
import { Layout } from 'components/forms/create-nft-form/layout'
import { Fields } from 'components/forms/create-nft-form/fieldsets/fields'
import { Dropzone } from 'components/forms/create-nft-form/fieldsets/dropzone/dropzone'

export const CreateNftFrom = props => <Layout {...props}>
  <Dropzone />
  <Fields submitErrors={props.submitErrors} />
</Layout>

CreateNftFrom.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  callbackUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  submitErrors: PropTypes.any.isRequired,
  initialValues: PropTypes.object.isRequired,
}
