import { get } from 'lodash'
import 'components/collection-card/length/index.scss'

const numberOfAssets = collection => get(collection, 'collectionAsset.totalCount', '0')

export const Length = ({ collection }) => <div className={'col-5 col-sm text-end d-none d-sm-block'}>
  <div className={'collection-card-length'}>
    {numberOfAssets(collection)}
    {' '}
    Items
  </div>
</div>

