import match from '@nerds.sh/js-pattern'

import { statuses } from 'components/auction-carousel/constants/statuses'

const validateAuctions = items => {
  if (items.find(({ node }) => !node.asset)) {
    return statuses.noAssets
  }

  return statuses.valid
}

export const useValidateItems = items => {
  if (items.length === 0) {
    return statuses.loading
  }

  return match(items[0])
    .like({ __typename: 'Auction' }, () => validateAuctions(items))
    .otherwise(() => statuses.valid)
}

