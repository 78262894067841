import { actions } from 'home/explore-nfts/slices/get'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useContext } from 'home/explore-nfts/context'
import { settings } from 'settings/fetching'

const makeFilters = tag => ({ tags: [tag] })

export const useExploreNfts = () => {
  const dispatch = useDispatch()
  const { selected } = useContext()
  useEffect(() => {
    selected && dispatch(actions.attempt({
      filters: makeFilters(selected),
      pagination: { first: settings().home.sectionItems },
    }))
  }, [dispatch, selected])
}
