import { useState } from 'react'
import { Behaviour } from 'sidebar/connect/ledger-login/ledger-container/address-row/behaviour'
import { Row } from 'sidebar/connect/ledger-login/ledger-container/address-row/row'
import { Radio } from 'sidebar/connect/ledger-login/ledger-container/address-row/radio'
import { Balance } from 'sidebar/connect/ledger-login/ledger-container/address-row/balance'
import { Id } from 'sidebar/connect/ledger-login/ledger-container/address-row/id'

export const AddressRow = ({ address, index, selectedAddress, onSelectAddress }) => {
  const [balance, setBalance] = useState('...')

  return <Row>
    <Behaviour address={address} setBalance={setBalance} />
    <Radio index={index} address={address} onSelectAddress={onSelectAddress} selectedAddress={selectedAddress} />
    <Balance balance={balance} />
    <Id index={index} />
  </Row>
}
