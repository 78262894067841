import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'theme/slices'

export const useSetTheme = () => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    dispatch(actions.set(payload))
  }, [dispatch])
}
