import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { actions } from 'nft-page/details/other-listings/slices/claimable'
import { useParams } from 'react-router-dom'
import { useMakePayload } from 'nft-page/details/other-listings/hooks/use-make-payload'
import { Loading } from 'loading'

export const useExecute = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()
  const showLoading = Loading.useShowLoading()
  const payload = useMakePayload({ identifier, after: '', status: 'Claimable' })

  return useCallback(() => {
    showLoading({}, { id: Loading.ID.nft.auctions, variant: Loading.VARIANT.partial })
    identifier && dispatch(actions.attempt(payload))
  }, [dispatch, identifier, payload, showLoading])
}

export const useFetchClaimableAuctions = () => {
  const { auctionId } = useParams()
  const execute = useExecute()
  useEffect(() => execute(), [execute, auctionId])
}
