import { Layout } from 'components/forms/create-nft-form/fieldsets/fields/layout'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import { Identity } from 'components/forms/create-nft-form/fieldsets/fields/identity'
import { useNerdsFormContext } from 'components/forms/nerds-form/use-nerds-form-context'
import { InputField } from 'components/forms/nerds-form/fields/input-field'
import { Textarea } from 'components/forms/nerds-form/fields/textarea'
import { TagsField } from 'components/forms/nerds-form/fields/tags-field'
import { PercentField } from 'components/forms/nerds-form/fields/percent-field'
import { Errors } from 'components/forms/nerds-form'

export const Fields = ({ submitErrors }) => {
  const { callbackUrl } = useNerdsFormContext()

  return <Layout>
    <Identity />
    <InputField label={'Name'} name={'name'} />
    <Textarea label={'Description'} name={'description'} />
    <TagsField label={'Tags'} name={'tags'} />
    <PercentField label={'Royalties'} name={'royalties'} />
    <DefaultActions callbackUrl={callbackUrl} />
    <Errors values={submitErrors} />
  </Layout>
}
