import { Button } from 'components/button'
import PropTypes from 'prop-types'
import 'components/unlike-button/style.scss'
import { useState } from 'react'

const useProps = ({ onClick, icon, setIcon }) => ({
  className: 'unlike-button',
  icon: 'heart-filled',
  onClick,
  buttonType: 'secondary',
})

export const UnlikeButton = props => {
  const [icon, setIcon] = useState('heart-filled')

  return <Button {...useProps({ ...props, icon, setIcon })}>
    {props.count}
    {' '}
    <span className={'d-none d-sm-inline'}>likes</span>
  </Button>
}

UnlikeButton.propTypes = { onClick: PropTypes.func.isRequired }
