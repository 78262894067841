import { Failure } from 'failure'
import { Seo } from 'components/seo'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/claimable/content'
import { VisibleIfOwner } from 'account/common/visible-if-owner'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { ContractWrapper } from 'components/contract-wrapper'

export const Claimable = () => <VisibleIfActive tab={'claimable'}>
  <VisibleIfOwner>
    <Seo {...useSeoProps('claimable')} />
    <Failure.Partial id={'account.claimable'}>
      <TabHeader />
      <ContractWrapper fullscreen>
        <Content />
      </ContractWrapper>
    </Failure.Partial>
  </VisibleIfOwner>
</VisibleIfActive>
