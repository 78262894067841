import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { BN } from 'bn.js'
import { actions } from 'modals/auction/buy-sft/slices/buy-sft'
import { get } from 'lodash'

export const useBuySft = auction => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const minBidAmount = new BN(get(auction, 'minBid.amount', '0'))
    const totalEgldValue = minBidAmount.mul(new BN(String(payload.quantity)))
      .toString()

    return dispatch(actions.attempt({
      input: {
        auctionId: parseInt(auction.id),
        identifier: auction.identifier,
        price: totalEgldValue,
        quantity: `${payload.quantity}`,
      },
    }))
  }, [dispatch, auction])
}
