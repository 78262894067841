import { ExploreNFTs } from 'home/explore-nfts'
import { Behaviour } from 'home/behaviour'
import { Seo } from 'components/seo'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Featured } from 'home/featured'
import 'home/style.scss'
import { Trending } from './trending'
import { CTASection } from './cta-section'

export const HomePage = () => <>
  <ScrollToTopOnMount />
  <Seo title={'Home'} />
  <Behaviour />
  <Featured />
  <CTASection />
  {/* {!isMainnet && <Campaigns />} */}
  <Trending />
  {/* {!isMainnet && <TrendingCollections />} */}
  <ExploreNFTs />
</>
