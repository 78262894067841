import { useBiddingBalance } from 'sidebar/my-wallet/balance/hooks/use-bidding-balance'
import { BalanceItem } from 'sidebar/my-wallet/balance-item'
import { BalanceSeparator } from 'sidebar/my-wallet/balance-separator'

export const BiddingBalance = () => {
  const biddingBalance = useBiddingBalance()

  return <>
    <BalanceSeparator />
    <BalanceItem title={'Bidding Balance'} egldAmount={biddingBalance.egld} usdAmount={biddingBalance.usd} />
  </>
}
