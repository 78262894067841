import 'account/tabs/assets/style.scss'
import { Bids } from 'account/tabs/assets/bids'
import { DataCard } from 'components/data-card'
import { Auctions } from 'account/tabs/assets/auctions'
// import { Creations } from 'account/tabs/assets/creations'
import { Collected } from 'account/tabs/assets/collected'
import { Claimable } from 'account/tabs/assets/claimable'
import { Collections } from 'account/tabs/assets/collections'
import { Behaviour as CollectedBehaviour } from 'account/tabs/assets/collected/behaviour'
import { Behaviour as CollectionsBehaviour } from 'account/tabs/assets/collections/behaviour'
import { Behaviour as AuctionsBehaviour } from 'account/tabs/assets/auctions/behaviour'
import { Behaviour as BidsBehaviour } from 'account/tabs/assets/bids/behaviour'
import { Behaviour as ClaimableBehaviour } from 'account/tabs/assets/claimable/behaviour'

export const Assets = () => <div>
  <CollectedBehaviour />
  <CollectionsBehaviour />
  <AuctionsBehaviour />
  <BidsBehaviour />
  <ClaimableBehaviour />

  <DataCard disableContentContainer>
    <Collected />
    <Collections />
    <Auctions />
    <Bids />
    <Claimable />
  </DataCard>
</div>
