import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

export const useOnLaunchClick = () => {
  const { go } = useHistory()

  return useCallback(() => {
    go(0)
  }, [go])
}
