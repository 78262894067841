import { FeaturedLink } from 'home/featured/components/featured-link'
import image1 from 'startup/assets/kickstart-nft.jpg'
import image2 from 'startup/assets/learn-nft.jpg'
import image3 from 'startup/assets/create-nft.jpg'
import image4 from 'startup/assets/win-auction.jpg'

const links = [
  {
    title: 'Kickstart your NFT journey',
    subtitle: 'New to NFTs?',
    gradient: 'linear-gradient(234.3deg, #60EFFF 0%, #1A45C2 100%);',
    image: image1,
    link: '/kickstart',
  },
  {
    title: 'Learn about NFTs',
    subtitle: 'How does it work?',
    image: image2,
    link: '/learn',
  },
  {
    title: 'Create your first NFT with ease',
    subtitle: 'NFTize everything',
    image: image3,
    link: '/create',
  },
  {
    title: 'Master your bid game',
    subtitle: 'How to win an auction?',
    image: image4,
    link: '/master',
  },
]

export const Links = () => <div className={'row'}>
  {links.map((item, index) => <FeaturedLink key={index} {...item} />) }
</div>
