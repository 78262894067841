import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'nft-page/details/other-listings/slices/claimable'
import { useClaimableAuctionsPageInfo } from 'nft-page/details/other-listings/hooks/use-claimable-auctions-page-info'
import { useParams } from 'react-router-dom'
import { useMakePayload } from 'nft-page/details/other-listings/hooks/use-make-payload'

export const useClaimableLoadMore = () => {
  const dispatch = useDispatch()
  const { endCursor } = useClaimableAuctionsPageInfo()
  const { identifier } = useParams()
  const payload = useMakePayload({ identifier, after: endCursor, status: 'Claimable' })

  return useCallback(() => dispatch(actions.attempt(payload)), [dispatch, payload])
}
