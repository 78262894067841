import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useCurrentTag } from 'home/trending/tags-filter/hooks/use-current-tag'

const wrapperProps = {
  className: 'dropdown-toggle tags-filter-button',
  id: 'trendingTabDropdownMenu',
  'data-bs-toggle': 'dropdown',
  'aria-expanded': 'false',
}

export const CurrentTag = () => <div {...wrapperProps}>
  {useCurrentTag()}
  <FontAwesomeIcon className={'tags-filter-button-arrow'} icon={faChevronDown} />
</div>
