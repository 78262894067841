import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'home/featured/slices'

export const useFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt())
  }, [dispatch])
}
