import { DropdownFilter } from '../dropdown-filter'
import { useFilters } from '../../hooks/use-filters'
import { useRouteFilter } from '../../../common/hooks/use-route-filter'
import { isEmpty } from 'lodash'

export const Type = ({ id = 'market-type-filter', options }) => {
  const { search } = useFilters()
  const changeRoute = useRouteFilter()

  const onChange = option => changeRoute({ name: 'byType', value: option.name })

  if (isEmpty(options)) return ''

  return <DropdownFilter label={'Type'} initial={search.byType} options={options} id={id} onChange={onChange} />
}
