import { toArray, map } from 'rxjs'
import { emitArrayElements } from 'repositories/feed/common'
import { settings } from 'settings/fetching'
import { getMainnetThumbnailApi } from 'repositories/feed/common/get-mainnet-thumbnail-api'

const format = ({ who, reference, extraInfo, timestamp }) => ({
  who,
  whom: {
    identifier: reference,
    name: extraInfo.nftName,
    media: [
      { thumbnailUrl: `${getMainnetThumbnailApi()}/nfts/${reference}/thumbnail` },
    ],
    price: {
      amount: extraInfo.price,
      token: settings().dapp.egldLabel,
    },
  },
  when: timestamp,
  action: { type: 'bid' },
})

export const bid = group => group
  .pipe(toArray())
  .pipe(emitArrayElements())
  .pipe(map(format))
