import 'components/feed-card/account/index.scss'
import { get } from 'lodash'
import defaultCover from 'startup/assets/cover.png'
import { UserImage } from 'components/user-image'
import { Username } from 'components/username'
import { useHerotag } from 'components/feed-card/hooks/use-herotag'

export const Account = props => <div className={'feed-card-account'}>
  <img className={'feed-card-account-cover'} src={get(props, 'whom.cover.url', defaultCover)} />
  <div className={'feed-card-account-content'}>
    <UserImage image={get(props, 'whom.profile.url')} />
    <div>
      <Username herotag={useHerotag(props.whom.herotag)} address={props.whom.address} />
      <p className={'feed-card-account-description'}>{props.whom.description}</p>
    </div>
  </div>
</div>

