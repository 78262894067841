import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const CardItem = ({ item }) => {
  const bidStatus = get(item, 'node.status')
  const isOutBidded = bidStatus === 'Inactive'
  const asset = get(item, 'node.auction.asset', {})
  const auction = get(item, 'node.auction', {})
  const isGhost = get(item, 'isGhost')

  return <AssetCard asset={asset} auction={auction} previewSource={'account-bids'} className={'small-on-mobile'}
                    outbid={isOutBidded} isGhost={isGhost} />
}
