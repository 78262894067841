import { gql } from 'graphql-tag'

export const COLLECTIONS = gql`
query collections($filters: CollectionsFilter, $pagination: ConnectionArgs, $input: CollectionAssetsRetriveCount) {
  collections(filters: $filters, pagination: $pagination) {
    edges {
      node {
        collection
        name
        type
        canCreate
        canBurn
        canWipe
        canPause
        canAddQuantity
        canTransferRole
        ownerAddress
        owner {
            address
            herotag
            profile
        }
        roles {
            address
            roles
            canCreate
        }
        collectionAsset {
            totalCount
            assets (input: $input) {
                identifier
                thumbnailUrl    
            }
        }
        verified
      }
    }
    pageData {
      count 
      offset
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    } 
  }
}
`
