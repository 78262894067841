import { useDispatch } from 'react-redux'
import { actions } from 'collections/stats/slices/get'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const useFetchCollectionStats = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  useEffect(() => {
    dispatch(actions.attempt({ filters: { identifier } }))
  }, [dispatch, identifier])
}
