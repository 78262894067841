import { Button } from 'components/button'
import PropTypes from 'prop-types'
import 'components/follow-button/style.scss'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'

const useClassName = () => {
  const className = 'follow-button btn-primary'
  const isDark = useIsDarkTheme()

  return isDark ? `${className} dark` : className
}

const useProps = ({ onClick }) => ({
  className: useClassName(),
  icon: 'plus',
  onClick,
})

export const FollowButton = props => <Button {...useProps(props)}>
  Follow
</Button>

FollowButton.propTypes = { onClick: PropTypes.func.isRequired }
