import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import 'components/forms/nerds-form/fields/price-field/usd-price/index.scss'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'

export const UsdPrice = ({ egld }) => {
  const { price } = useEconomicsSelector()
  const decimals = useDenominationDecimals()

  const usdPrice = parseFloat(`${price * egld}`).toFixed(decimals)

  return egld && !isNaN(egld) && <div className={'price-field-usd d-flex align-items-center me-3'}>
    <span className={'spacer'}>{egld}</span>
    {`~ $${usdPrice} USD`}
  </div>
}
