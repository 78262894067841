import { useHistory } from 'react-router-dom'

const isHerotagAccount = pathname => pathname.startsWith('/@')
const isAddressAccount = pathname => pathname.startsWith('/erd')

export const useShouldUseAccountState = () => {
  const { location: { pathname } } = useHistory()

  return isHerotagAccount(pathname) || isAddressAccount(pathname)
}
