import { useBuySft } from 'modals/auction/buy-sft/hooks/use-buy-sft'
import { useBuySftSelector } from 'modals/auction/buy-sft/hooks/use-buy-sft-selector'
import { denominate } from 'components/conversion'
import { actions } from 'modals/auction/buy-sft/slices/buy-sft'
import { get } from 'lodash'
import { BuySftForm } from 'components/forms/buy-sft-form'
import { useDependenciesLoading } from 'modals/auction/hooks/use-dependecies-loading'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { humanize } from 'components/conversion/humanize'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

const makeInitialValues = auction => ({
  price: humanize(denominate(get(auction, 'maxBid.amount', '0'))),
  identifier: get(auction, 'identifier', ''),
  auctionId: parseInt(get(get, 'id', '0')),
  quantity: 1,
})

export const Form = ({ auction }) => {
  const buySft = useBuySft(auction)
  const { loading, errors, data } = useBuySftSelector()
  const callbackUrl = useCloseLayerLocation()
  const dependenciesLoading = useDependenciesLoading()
  const actionIdentifier = createActionIdentifier(auction, actionTypes.buyNowSft)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <BuySftForm onSubmit={buySft} submitErrors={errors} loading={loading || dependenciesLoading}
                     callbackUrl={callbackUrl} initialValues={makeInitialValues(auction)}
                     auction={auction} key={auction.id} />
}
