import { combineEpics } from 'redux-observable'

import { getNft } from 'nft-page/epics/get-nft'
import { owner } from 'nft-page/owner/epics/owner'
import { running } from 'nft-page/details/other-listings/epics/running'
import { selectedAuction } from 'nft-page/auction/epics/selected-auction'
import { claimable } from 'nft-page/details/other-listings/epics/claimable'
import { get as getOrders } from 'nft-page/auction/bids/epics/get'
import { view } from 'nft-page/tracking/epics/view'
import { epic as likes } from 'nft-page/likes/epic'

export const nft = combineEpics(getNft, owner, running, claimable, selectedAuction, getOrders, view, likes)
