import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Loading } from 'loading'

const path = 'collections.assets.get.list'

export const useActualList = () => {
  const items = useSelector(state => get(state, path, []))
  const ghosts = Loading.usePayloads(Loading.ID.collection.page)

  return [...items, ...ghosts]
}
