import { useIsLoading } from 'home/explore-nfts/collections/hooks/use-is-loading'
import { LoadingCards } from 'components/loading-cards'
import { Carousel } from 'components/collection-carousel'
import { Empty } from 'home/explore-nfts/collections/empty'
import { useExploreCollectionsEdges } from 'home/explore-nfts/collections/hooks/use-explore-collections-edges'

export const Collections = () => {
  const collections = useExploreCollectionsEdges()
  const isLoading = useIsLoading()
  const carouselProps = { items: collections, previewSource: 'home-explore-collections' }

  return <div className={'explore-nfts'}>
    {isLoading ? <LoadingCards ghostCardNumber={10} isCollectionCard /> : <Carousel {...carouselProps} />}
    <Empty />
  </div>
}
