import { get } from 'lodash'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

const useBackgroundLinkTag = () => {
  const { location: { state } } = useHistory()
  const link = get(state, 'layerLink.background', '')

  return link && get(queryString.parse(link.split('?')[1]), 'tag', '')
}

export const useSelectedTag = () => {
  const parsed = queryString.parse(useLocation().search)
  const backgroundTag = useBackgroundLinkTag()

  return get(parsed, 'tag', '') || backgroundTag
}
