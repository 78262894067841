import { useCallback } from 'react'
import { useSetTheme } from 'theme/hooks/use-set-theme'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'
import { THEMES } from 'theme/constants/themes'
import { useSetPreference } from '../../../../../preferences/hooks/use-set-preference'

export const useOnClick = () => {
  const isDarkTheme = useIsDarkTheme()
  const setTheme = useSetTheme()
  const setPreferences = useSetPreference()

  return useCallback(() => {
    const theme = isDarkTheme ? THEMES.light : THEMES.dark
    setTheme(theme)
    setPreferences({ theme })
  }, [isDarkTheme, setTheme, setPreferences])
}
