import { settings } from '../../../settings/fetching'
import { customFilters, shouldAddCustomFilter } from './custom-filters'
import { sorting } from './sorting'
import { filters } from './filters'
import { compact } from 'lodash'

export const marketPlaceFilter = marketplaceKey => {
  const filter = { field: 'marketplaceKey', values: [`${marketplaceKey}`], op: 'EQ' }

  return marketplaceKey ? [filter] : []
}

export const priceFilters = filters => {
  const paymentTokenFilter = { field: 'paymentToken', op: 'EQ', values: ['EGLD'] }

  return shouldAddCustomFilter(filters) ? [paymentTokenFilter] : []
}

export const buildFilters = ({ minVal, maxVal, cursor, sortBy, byType, tag }) => ({
  customFilters: customFilters({ minVal, maxVal, sortBy }),
  pagination: { first: settings().listRequestSize, after: cursor },
  sorting: compact([...sorting(sortBy)]),
  grouping: { groupBy: 'IDENTIFIER' },
  filters: {
    operator: 'AND',
    filters: [
      ...filters({ byType, tag }),
      ...priceFilters({ minVal, maxVal, sortBy }),
    ],
  },
})

