import { useIsLoggedIn } from '../common/hooks/use-is-logged-in'
import { useGetLoginInfo } from '@elrondnetwork/dapp-core/hooks'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { setLoginMethod } from '@elrondnetwork/dapp-core/reduxStore/slices'
import { useDispatch } from '@elrondnetwork/dapp-core/reduxStore/DappProviderContext'
import { useAppContext } from '../startup/context'

/* eslint-disable max-lines-per-function, complexity */
export const ExtensionEdgeCaseFix = () => {
  const { isLoggedIn } = useIsLoggedIn()
  const { loginMethod } = useGetLoginInfo()
  const { replace } = useHistory()
  const dispatch = useDispatch()
  const { sidebarOpened } = useAppContext()

  useEffect(() => {
    if (!isLoggedIn && loginMethod === 'extension' && sidebarOpened) {
      dispatch(setLoginMethod(''))
    }
  }, [isLoggedIn, loginMethod, replace, dispatch, sidebarOpened])

  return ''
}
