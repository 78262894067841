import { gql } from 'graphql-tag'

export const PRICE_RANGE = gql`
    query priceRange {
        priceRange {
            maxBid {
                amount
                token
            }
            minBid {
                amount
                token
            }
        }
    }
`
