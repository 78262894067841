import { faHandPaper } from '@fortawesome/pro-light-svg-icons'
import { Empty as EmptyState } from 'components/empty'
import { selector as bidsSelector } from 'account/tabs/assets/bids/hooks/use-items'
import { useSelector } from 'react-redux'

const stateProps = {
  icon: faHandPaper,
  message: 'No bids yet.',
}

const useBidCount = () => useSelector(bidsSelector).length

export const Empty = () => {
  const bidsCount = useBidCount()

  return !bidsCount && <EmptyState {...stateProps} />
}
