import 'account/tabs/tab-header/tab-menu-item/layout/index.scss'
import { useTabProps } from 'account/tabs/hooks/use-tab-props'
import { useShouldDisplay } from 'account/tabs/tab-header/tab-menu-item/hooks/use-should-display'

export const Layout = ({ children, tab }) => {
  const shouldDisplay = useShouldDisplay(tab)
  const tabProps = useTabProps(tab)

  return shouldDisplay && <a {...tabProps}>
    <div className={'d-flex flex-column flex-md-row align-items-center'}>
      {children}
    </div>
  </a>
}
