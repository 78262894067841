import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { get } from 'nft-page/likes/slices'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useParams } from 'react-router-dom'

const usePayload = () => {
  const { identifier } = useParams()
  const { address } = useGetAccountInfo()

  return useMemo(() => ({ filters: { identifier }, address: address || '' }), [address, identifier])
}

export const useFetchLikes = () => {
  const dispatch = useDispatch()
  const payload = usePayload()

  useEffect(() => {
    dispatch(get.actions.attempt(payload))
  }, [dispatch, payload])
}
