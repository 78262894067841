import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsTheme = () => {
  const data = useCmsData()
  const theme = get(data, 'data.data.attributes.theme')

  useEffect(() => {
    const subdomainStylesheet = document.getElementById('subdomain-theme-style')
    subdomainStylesheet.setAttribute('href', `/styles/subdomain-${theme}.css`)

    if (theme === 'dark') {
      document.body.classList.add('dark-theme')
    }
  }, [theme])
}
