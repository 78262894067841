import { useCallback } from 'react'
import { testNumber } from 'components/forms/place-bid-form/hooks/use-tests'
import { denominate } from 'components/conversion'
import { useMaximum } from 'components/forms/place-bid-form/hooks/use-maximum'

export const useShouldThrowMaximum = auction => {
  const maximum = useMaximum(auction)

  return useCallback(value => {
    if (!testNumber(value)) return false

    return parseFloat(denominate(maximum)) !== 0 ? value > parseFloat(denominate(maximum)) : false
  }, [maximum])
}
