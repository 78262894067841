import 'sidebar/connect/connect-button/connect-button.scss'
import { ReactComponent as Maiar } from 'startup/assets/maiar-icon.svg'
import { ReactComponent as Exchange } from 'startup/assets/extension-icon.svg'
import { ArrowRight } from 'sidebar/connect/connect-button/arrow-right'
import { ArrowLeft } from 'sidebar/connect/connect-button/arrow-left'
import { ReactComponent as Elrond } from 'startup/assets/wallet-icon.svg'
import { ReactComponent as Ledger } from 'startup/assets/ledger-symbol.svg'
import { useLoginToken } from 'auth/hooks/use-login-token'

export const ConnectButton = ({ LeftIcon, RightIcon, onClick, children, className }) => {
  const initToken = useLoginToken()

  return (
    <button onClick={onClick} className={'sidebar-connect-button d-flex align-items-center w-100'}
            disabled={!initToken}>
      <div className={'sidebar-connect-button-icon'}>
        <LeftIcon className={className} />
      </div>
      <span>{children}</span>
      <div className={'sidebar-connect-button-arrow'}>
        <RightIcon />
      </div>
    </button>
  )
}

ConnectButton.defaultProps = {
  LeftIcon: () => null,
  RightIcon: ArrowRight,
}

ConnectButton.Icon = {
  ArrowLeft,
  ArrowRight,
  Elrond,
  Maiar,
  Exchange,
  Ledger,
  None: () => null,
}
