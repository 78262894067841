import * as Yup from 'yup'

import { useTests } from 'components/forms/create-nft-auction-form/hooks/use-tests'
import { settings } from 'settings/fetching'

const maxMinBid = () => settings().minBidLimit

export const useMinBidSchema = (requiredText = '') => Yup.string()
  .required(requiredText)
  .test('comma', 'Use dot for decimals', useTests().testComma)
  .test('number', 'Must be a number', useTests().testNumber)
  .test('decimals', 'Too many decimals', useTests().testDecimals)
  .test('startDot', 'Must not start with dot', useTests().testStartDot)
  .test('endDot', 'Must not end with dot', useTests().testEndDot)
  .test('positive', 'Must be higher than 0', useTests().testValue)
  .test('max', `Must be lower than ${maxMinBid()}`, useTests().testMaxMaxBidValue)
  .test('leadingZeros', 'Leading zero is not allowed', useTests().testLeadingZeros)
  .default('')
