import { useDebouncedSearch } from 'components/top-navigation/components/search-form/hooks/use-debounced-search'
import { useMakeLink } from 'components/top-navigation/components/search-form/hooks/use-make-link'
import { useTopNavigationContext } from 'components/top-navigation/context'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDynamicSearchResults } from 'search/hooks/use-dynamic-search-results'
import { useSetSearchParam } from 'search/hooks/use-set-search-param'

export const useOnSubmit = () => {
  const { selectedResult } = useTopNavigationContext()
  const results = useDynamicSearchResults()
  const { push } = useHistory()
  const makeLink = useMakeLink()
  const search = useSetSearchParam()
  const debouncedSearch = useDebouncedSearch()

  return useCallback(values => {
    if (selectedResult !== -1) {
      push(makeLink(results[selectedResult]))
    } else {
      debouncedSearch.cancel()
      search(values.search)
    }
  }, [results, selectedResult, push, makeLink, search, debouncedSearch])
}
