import 'components/followers/index.scss'

export const Followers = ({ count, children, label, onClick }) => {
  const btnClass = onClick ? 'btn btn-button' : ''

  return <span onClick={onClick} className={`${btnClass} profile-followers d-flex`}>
    <div className={'profile-followers-inner'}>
      <span className={'profile-followers-count'}>{count}</span>
      <span className={'profile-followers-label'}>{label || 'Followers'}</span>
    </div>
    {children}
  </span>
}
