import { NftForm } from 'modals/collection/asset/forms/nft-form'
import { Behaviour } from 'modals/collection/asset/behaviour'
import { SftForm } from 'modals/collection/asset/forms/sft-form'
import { BootstrapModal } from 'components/bootstrap-modal'
import { useCollection } from 'modals/collection/asset/hooks/use-collection'

const makeModalId = type => type === 'SemiFungibleESDT' ? 'createSft' : 'createNft'

export const CreateNft = () => {
  const collection = useCollection()

  return <BootstrapModal id={makeModalId(collection.type)} modalSize={'modal-fullscreen'}>
    <Behaviour />
    {collection.type === 'SemiFungibleESDT' ? <SftForm collection={collection} /> : <NftForm collection={collection} />}
  </BootstrapModal>
}
