import { issueNftSchema } from './issue-nft-schema'

export const config = ({ onSubmit, initialValues, initialTouched }) => ({
  initialValues: issueNftSchema().cast(initialValues),
  validationSchema: issueNftSchema(),
  validateOnChange: true,
  enableReinitialize: true,
  initialTouched,
  onSubmit,
})
