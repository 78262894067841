import { useEconomics } from 'economics/hooks/use-economics'
import { useCloseSidebar } from 'sidebar/hooks/use-close-sidebar'
import { useViewportWidth } from 'startup/hooks/use-viewport-width'
import { useKeysControls } from 'startup/hooks/use-keys-controls'
import { useTransactionRedirect } from 'startup/hooks/use-transaction-redirect'
import { useCurrentProfile } from 'current-profile/hooks/use-current-profile'
import { useSubdomainHydrateLocation } from 'startup/hooks/use-subdomain-hydrate-location'
import { useHomeClass } from 'subdomain/home/hooks/use-home-class'
import { useFetchMarketplace } from 'marketplaces/hooks/use-fetch-marketplace'
import { useFavicon } from 'subdomain/layout/hooks/use-favicon'
import { useCmsGtm } from 'subdomain/layout/hooks/use-cms-gtm'

export const Behaviour = () => {
  useEconomics()
  useCloseSidebar()
  useViewportWidth()
  useKeysControls()
  useSubdomainHydrateLocation()
  useTransactionRedirect()
  useCurrentProfile()
  useFetchMarketplace()
  useHomeClass()
  useFavicon()
  useCmsGtm()

  return ''
}
