import { FlexColumn } from 'components/flex-column'

import 'nft-page/auction/price/index.scss'
import { humanize } from 'components/conversion/humanize'
import { usePrice } from 'auction/price/hooks/use-price'
import { getPriceDetails } from 'common/get-price-details'

export const Price = () => {
  const price = usePrice()
  const { token, amount, usdAmount } = getPriceDetails(price)

  return <FlexColumn>
    <span className={`nft-current-bid-egld-price`}>
      {`${humanize(amount)}`}
      {' '}
      <span className={'nft-current-bid-egld-token'}>{`${token}`}</span>
    </span>
    {usdAmount !== '0' && <small className={`nft-current-bid-price`}>{`~ ${usdAmount} USD`}</small>}
  </FlexColumn>
}
