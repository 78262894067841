import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'

export const Price = ({ auction }) => {
  const minimum = useMinimum(auction)
  const price = humanize(denominate(minimum))

  return <fieldset>
    <legend>Price</legend>
    <PriceField name={'price'} placeholder={'Set the amount you want to bid'} price={price} />
  </fieldset>
}
