import { Empty as EmptyState } from 'components/empty'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { CollectionLink } from 'account/tabs/assets/collections/collection-link'
import { selector as collectionsSelector } from 'account/tabs/assets/collections/hooks/use-items'
import { useSelector } from 'react-redux'

const stateProps = {
  icon: faCubes,
  message: 'No collections yet.',
  Action: CollectionLink,
}

const useCollectionCount = () => useSelector(collectionsSelector).length

export const Empty = () => {
  const collectionCount = useCollectionCount()

  return !collectionCount && <EmptyState {...stateProps} />
}
