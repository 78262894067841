import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { FlexRow } from 'components/flex-row'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { formatToken } from 'common/format-token'

export const PlaceBid = ({ auction, address }) => {
  const placeBidClick = useOnClick(auction)
  const onClick = usePermittedAction(placeBidClick, address)
  const minBidToken = get(auction, 'minBid.token')
  const amount = useMinimum(auction)
  const price = `${(humanize(denominate(amount)) * 1)} ${formatToken(minBidToken)}`

  return <FlexRow>
    <WatchableAction entity={auction} actionType={actionTypes.bid} onClick={onClick} text={'Bid'}
                     className={'btn btn-secondary other-auction-action'} price={price} />
  </FlexRow>
}
