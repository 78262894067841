import { denominate } from 'components/conversion'
import { useCurrentPrice } from 'subdomain/components/my-asset-card/hooks/use-current-price'
import { humanize } from 'components/conversion/humanize'
// import { ReactComponent as ElrondIcon } from 'startup/assets/elrond-logo-blue.svg'
import 'subdomain/components/my-asset-card/price/price.scss'
import { formatToken } from 'common/format-token'

export const Price = ({ auction }) => {
  const bid = useCurrentPrice(auction)
  const egldAmount = denominate(bid.amount)

  return <div className={'my-asset-card-price justify-content-between'}>
    <div className={'my-asset-card-prices'}>
      <div className={'my-asset-card-price-value'}>
        {/* <ElrondIcon /> */}
        <span>
          {`${humanize(egldAmount)} `}
          {` ${formatToken(bid.token)}`}
        </span>
      </div>
      <div className={'my-asset-card-price-usd'}>{`~ ${bid?.usdAmount || '0'} USD`}</div>
    </div>
  </div>
}
