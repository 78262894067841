import PropTypes from 'prop-types'

import { NerdsForm } from 'components/forms/nerds-form'
import { Fields } from 'components/forms/create-nft-auction-form/fields'
import { FlexRow } from 'components/flex-row'
import { useConfig } from 'components/forms/create-nft-auction-form/hooks/use-config'

const useFormProperties = props => ({
  loading: props.loading,
  config: useConfig({ onSubmit: props.onSubmit, initialValues: props.initialValues }),
  submitErrors: props.submitErrors,
  callbackUrl: props.callbackUrl,
})

export const CreateNftAuctionForm = props => <NerdsForm {...useFormProperties(props)}>
  <FlexRow className={'justify-content-center'}>
    <Fields callbackUrl={props.callbackUrl} asset={props.asset} submitErrors={props.submitErrors} />
  </FlexRow>
</NerdsForm>

CreateNftAuctionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.any.isRequired,
  callbackUrl: PropTypes.object.isRequired,
}
