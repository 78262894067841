import { Qr } from 'sidebar/connect/maiar-login/code/qr'
import { Error } from 'sidebar/connect/maiar-login/code/error'
import { ContentByDevice } from 'sidebar/connect/maiar-login/code/content-by-device'
import { Title } from 'sidebar/connect/maiar-login/code/title'
import { useMaiarLogin } from 'sidebar/connect/maiar-login/code/hooks'
import { Wrapper } from 'sidebar/connect/maiar-login/code/wrapper'
import { Behaviour } from 'sidebar/connect/maiar-login/code/behaviour'

export const Code = ({ callbackRoute, logoutRoute, token }) => {
  const { error, initLogin, uriDeepLink, walletConnectUri } = useMaiarLogin({ callbackRoute, logoutRoute, token })

  return <Wrapper>
    <Behaviour initLogin={initLogin} />
    <Qr walletConnectUri={walletConnectUri} />
    <Title />
    <ContentByDevice uriDeepLink={uriDeepLink} />
    <Error error={error} />
  </Wrapper>
}
