import { Link as AssetLink } from 'react-router-dom'
import 'subdomain/components/my-asset-card/link/index.scss'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const Link = props => {
  const layeredLocation = useLayeredLocation()

  return (
    <AssetLink className={'my-asset-card-link'} replace
               to={layeredLocation(props.link, 'foreground', props.previewSource)} />)
}
