import { useCallback } from 'react'
import { maiar } from 'auth/slices/maiar'
import { useDispatch } from 'react-redux'

export const useSetLoginToken = () => {
  const dispatch = useDispatch()

  return useCallback(token => {
    dispatch(maiar.actions.setLoginToken(token))
  }, [dispatch])
}
