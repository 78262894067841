import { hasRequiredRoles } from './has-required-roles'
import { SetRolesButton } from './set-roles'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'
import { CreateNftOrSftButton } from 'collections/actions/create-nft-or-sft-button'

export const ActionSelector = ({ collection }) => {
  const hasRoles = hasRequiredRoles(collection)
  const isSubdomain = useIsSubdomain()

  if (!hasRoles) {
    return <SetRolesButton collection={collection} />
  } else {
    return !isSubdomain ? <CreateNftOrSftButton collection={collection} /> : ''
  }
}

