import { useDappLogout } from '../../logout/hooks/use-dapp-logout'
import { useCallback } from 'react'

export const useOnTokenExpired = () => {
  const logout = useDappLogout()

  return useCallback(() => {
    console.log('session Expired')// leave this for a moment
    logout('from token expired')
  }, [logout])
}
