// eslint-disable-next-line max-lines-per-function
export const menuSections = address => [{
  title: 'Essentials',
  links: [
    {
      title: 'Get EGLD',
      href: 'https://buy.elrond.com',
      external: true,
    },
    {
      title: 'Maiar app (free)',
      href: 'https://getmaiar.com/app',
      external: true,
    },
    {
      title: 'Web extension (free)',
      href: 'https://getmaiar.com/defi',
      external: true,
    },
    {
      title: 'Elrond explorer',
      href: 'https://explorer.elrond.com',
      external: true,
    },
  ],
},
{
  title: 'My Account',
  links: [
    {
      title: 'My NFTs',
      href: `/${address}`,
      authorized: true,
    },
    {
      title: 'My Collections',
      href: `/${address}/collections`,
      authorized: true,
    },
    {
      title: 'Value Feed',
      href: `/feed`,
      authorized: true,
    },
    {
      title: 'Auctions',
      href: `/${address}/auctions`,
      authorized: true,
    },
  ],
},
{
  title: 'Discover',
  links: [
    {
      title: 'Kickstart your NFT journey',
      href: `/kickstart`,
    },
    {
      title: 'Learn about NFTs',
      href: '/learn',
    },
    {
      title: 'Create your first NFT',
      href: '/create',
    },
    {
      title: 'Master your bid game',
      href: '/master',
    },
  ],
},
{
  title: 'Legal',
  links: [
    {
      title: 'Privacy Policy',
      href: '/privacy',
    },
    {
      title: 'Terms & Conditions',
      href: '/terms',
    },
    {
      title: 'Security',
      href: 'https://report.elrond.com/',
      external: true,
    },
  ],
}]
