import { useCallback } from 'react'

import { useShouldThrow } from 'components/forms/place-bid-form/hooks/use-should-throw'
import { useMinimumMessage } from 'components/forms/place-bid-form/hooks/use-minimum-message'

export const useTestMinimum = auction => {
  const shouldThrow = useShouldThrow(auction)
  const makeMessage = useMinimumMessage(auction)

  return useCallback((value, context) => {
    if (!shouldThrow(value)) return true

    return context.createError({ message: makeMessage(context), path: context.path })
  }, [shouldThrow, makeMessage])
}
