import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

export const useRouteFilter = () => {
  const { push, location: { pathname, search } } = useHistory()

  return useCallback(({ name, value }, reset) => {
    const parsedSearch = new URLSearchParams(search)

    if (!reset) {
      if (value) {
        parsedSearch.set(name, value)
      } else {
        parsedSearch.delete(name)
      }

      push({ pathname, search: parsedSearch.toString(), state: { forceReset: true } })
    } else {
      const newSearch = new URLSearchParams()
      push({ pathname, search: newSearch.toString(), state: { forceReset: true } })
    }
  }, [push, search, pathname])
}
