import React from 'react'
import { useCollectionsSelector } from '../hooks/use-collections-selector'
import { ActionSelector } from './action-selector'
import { OnSaleFilter } from 'collections/actions/on-sale-filter'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { get } from 'lodash'

export const Actions = () => {
  const [collection] = useCollectionsSelector()
  const { address } = useGetAccountInfo()
  const ownerAddress = get(collection, 'node.ownerAddress', '')

  return <>
    <OnSaleFilter />
    {ownerAddress === address ? <ActionSelector collection={collection} /> : ''}
  </>
}
