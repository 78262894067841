import { useNerdsFormContext } from '../../../nerds-form/use-nerds-form-context'
import { Layout } from './layout'
import { Identity } from './identity'
import { Details } from './details'
import { DefaultActions } from '../../../nerds-form/fields/default-actions'
import { Errors } from 'components/forms/nerds-form'

export const Fields = ({ submitErrors }) => {
  const { callbackUrl } = useNerdsFormContext()

  return <Layout>
    <Identity />
    <Details />
    <DefaultActions callbackUrl={callbackUrl} />
    <Errors values={submitErrors} />
  </Layout>
}
