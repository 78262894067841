import { Empty as EmptyState } from 'components/empty'
import { useIsLoading } from 'home/explore-nfts/filters/hooks/use-is-loading'
import { faCompass } from '@fortawesome/pro-light-svg-icons'
import { useExploreNftsEdges } from 'home/explore-nfts/nfts/hooks/use-explore-nfts-edges'

const stateProps = {
  icon: faCompass,
  message: 'No tokens found.',
}

export const Empty = () => {
  const data = useExploreNftsEdges()
  const isLoading = useIsLoading()

  return !isLoading && !data.length && <EmptyState {...stateProps} />
}
