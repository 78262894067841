import { QuantityField } from 'components/forms/nerds-form/fields/quantity-field'
import { PriceField } from 'components/forms/nerds-form/fields/price-field'
import { DateTimeField } from 'components/forms/nerds-form/fields/date-time-field'
import { DefaultActions } from 'components/forms/nerds-form/fields/default-actions'
import 'components/forms/create-sft-auctions/variable-form/fields.scss'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { Errors } from 'components/forms/nerds-form'
import { FinalFeeField } from 'components/forms/nerds-form/fields/final-fee-field'
import { useAssetFees } from 'components/forms/nerds-form/fields/hooks/use-asset-fees'
import { useMinOrMaxBidValue } from 'components/forms/nerds-form/fields/hooks/use-min-or-max-bid-value'

export const Fields = ({ callbackUrl, asset, assetOwner, submitErrors }) => {
  const { resetForm } = useFormikContext()
  const assetFees = useAssetFees(asset)
  const exampleValue = useMinOrMaxBidValue()

  useEffect(() => resetForm, [resetForm])

  return <div className={'variable-auction-form'}>
    <PriceField name={'minBid'} label={'Price per piece'} />
    <QuantityField name={'quantity'} label={'How many to sell?'} pieces={assetOwner.balance} />
    <DateTimeField name={'deadline'} label={'Expires on'} disablePast />
    <FinalFeeField asset={asset} assetFees={assetFees} exampleValue={exampleValue} labelText={'You will receive'} />
    <DefaultActions callbackUrl={callbackUrl} label={'Start auction'} />
    <Errors values={submitErrors} />
  </div>
}
