import { SingleForm } from 'components/forms/create-sft-auctions/single-form'
import { useCreateSingleAuction } from 'modals/auction/start/hooks/use-create-single-auction'
import { useIsLoading } from 'modals/auction/start/hooks/use-is-loading'
import { useErrors } from 'modals/auction/start/hooks/use-errors'
import { useData } from 'modals/auction/start/hooks/use-data'
import { actions } from 'modals/auction/start/slices/create-auction'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { settings } from 'settings/fetching'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

const egldLabel = () => settings().dapp.egldLabel

const initialValues = (asset, assetOwner) => ({
  identifier: asset.identifier,
  paymentToken: egldLabel(),
  maxOneSftPerPayment: false,
  quantity: '1',
  balance: assetOwner.balance,
})

export const Single = ({ asset, assetOwner }) => {
  const createAuction = useCreateSingleAuction(asset)
  const loading = useIsLoading()
  const errors = useErrors()
  const data = useData()
  const callbackUrl = useCloseLayerLocation(`/nfts/${asset.identifier}`)
  const actionIdentifier = createActionIdentifier(asset, actionTypes.startSftAuction)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <SingleForm onSubmit={createAuction} submitErrors={errors} loading={loading}
                     callbackUrl={callbackUrl} initialValues={initialValues(asset, assetOwner)} asset={asset}
                     assetOwner={assetOwner} />
}
