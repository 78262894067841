import { ReactComponent as Cup } from 'startup/assets/cup.svg'
import { ReactComponent as Triangle } from 'startup/assets/outbidded-badge.svg'
import { ReactComponent as Ended } from 'startup/assets/ended-badge.svg'

/* eslint-disable max-lines-per-function */
export const makeNotification = (name, type) => {
  const texts = {
    Ended: <>
      <span className={'account-notifications-item-icon'}><Ended /></span>
      <span className={'account-notifications-item-text'}>
        Auction for
        {' '}
        <span className={'account-notifications-item-name'}>{name}</span>
        {' '}
        has ended.
      </span>
    </>,

    Outbidded: <>
      <span className={'account-notifications-item-icon'}><Triangle /></span>
      <span className={'account-notifications-item-text'}>
        You were outbidded in the
        {' '}
        <span className={'account-notifications-item-name'}>{name}</span>
        {' '}
        auction.
      </span>
    </>,
    Won: <>
      <span className={'account-notifications-item-icon'}><Cup /></span>
      <span className={'account-notifications-item-text'}>
        You won the auction for
        {' '}
        <span className={'account-notifications-item-name'}>{name}</span>
        {' '}
        !
      </span>
    </>,
  }

  return texts[type]
}
