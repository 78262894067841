import { useEffect } from 'react'
import { useSetTheme } from 'theme/hooks/use-set-theme'
import { THEMES } from 'theme/constants/themes'
import { usePreferences } from '../../preferences/hooks/use-preferences'

export const useSetInitialTheme = () => {
  const setTheme = useSetTheme()
  const userDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const { theme } = usePreferences()

  useEffect(() => {
    if (theme) {
      setTheme(theme)
    } else if (userDarkTheme) {
      setTheme(THEMES.dark)
    }
  }, [setTheme, userDarkTheme, theme])
}
