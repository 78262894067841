import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useLoginToken } from 'auth/hooks/use-login-token'
import { useContext } from 'sidebar/connect/context'
import { useSetStep } from 'sidebar/connect/hooks/use-set-step'
import { STEP } from 'sidebar/connect/constants'
import { ConnectButton } from 'sidebar/connect/connect-button'
import { LedgerContainer } from 'sidebar/connect/ledger-login/ledger-container'
import 'sidebar/connect/ledger-login/ledger.scss'

const useProperties = () => ({
  callbackRoute: useCallbackRoute(),
  token: useLoginToken(),
})

export const Ledger = () => {
  const props = useProperties()
  const { step } = useContext()
  const handleBack = useSetStep(STEP.loginButtons)

  return step === STEP.ledgerLogin && <div className={'maiar-ledger'}>
    <LedgerContainer {...props} />
    <ConnectButton LeftIcon={ConnectButton.Icon.ArrowLeft} RightIcon={ConnectButton.Icon.None} onClick={handleBack}>
      Back
    </ConnectButton>
  </div>
}
