import { get } from 'lodash'
import { formatAmount } from '@elrondnetwork/dapp-core/utils'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const getPriceDetails = price => {
  const tokenDataDecimals = get(price, 'tokenData.decimals', 18)
  const tokenSymbol = get(price, 'tokenData.symbol', egldLabel())
  const amount = get(price, 'amount', '')
  const usdAmount = get(price, 'usdAmount')
  const formattedAmount = formatAmount({ input: amount, decimals: tokenDataDecimals })

  return { token: tokenSymbol, amount: formattedAmount, usdAmount }
}
