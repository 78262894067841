import 'components/ghost-collection-card/index.scss'

export const GhostCollectionCard = ({ className }) => <div className={`ghost-collection-card ${className}`}>
  <div className={'ghost-collection-card-images'}>
    <div className={'ghost-collection-card-image'} />
    <div className={'ghost-collection-card-image'} />
    <div className={'ghost-collection-card-image'} />
    <div className={'ghost-collection-card-image'} />
  </div>
  <div className={'ghost-collection-card-content'} />
</div>

