import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'collections/assets/slices/get'
import { useParams } from 'react-router-dom'

export const useResetState = () => {
  const dispatch = useDispatch()
  const { identifier } = useParams()

  useEffect(() => () => {
    dispatch(actions.reset({}))
  }, [dispatch, identifier])
}
