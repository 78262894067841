import 'nft-page/details/other-details/key-pair.scss'

export const KeyPair = ({ icon, title, children }) => <div className={`nft-page-key-pair`}>
  <div className={'nft-page-key-pair-title'}>
    <div className={'nft-page-key-pair-icon'}>{icon}</div>
    <span>{title}</span>
  </div>
  <div className={'nft-page-key-pair-value'}>
    {children}
  </div>
</div>
