import { Wrapper } from 'components/collection-card/header/wrapper'
import { Content } from 'components/collection-card/header/content'

import 'components/collection-card/header/header.scss'

export const Header = ({ collection }) => <Wrapper collection={collection}>
  <div className={'collection-card-header-inner d-flex flex-wrap'}>
    <Content collection={collection} />
  </div>
</Wrapper>
