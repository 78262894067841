import { combineReducers } from 'redux'
import { running as auctionsSlice } from 'nft-page/details/other-listings/slices/running'
import { claimable as claimableSlice } from 'nft-page/details/other-listings/slices/claimable'
import { list } from '../slices/list'

export const auctions = combineReducers({
  running: auctionsSlice.reducer,
  claimable: claimableSlice.reducer,
  list: list.reducer,
})
