import { useWatchTransaction } from 'campaign/hooks/use-watch-transaction'
import { useFetch } from 'campaign/hooks/use-fetch'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useResetState } from 'campaign/hooks/use-reset-state'

const useCleanSearch = () => {
  const { replace, location } = useHistory()

  useEffect(() => {
    if (location.search) {
      replace(location.pathname)
    }
  }, [replace, location])
}

export const Behaviour = () => {
  useResetState()
  useCleanSearch()
  useWatchTransaction()
  useFetch()

  return ''
}
