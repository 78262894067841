import { actions } from 'modals/collection/asset/slices/create-nft'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

export const useCreate = () => {
  const dispatch = useDispatch()

  return useCallback(payload => dispatch(actions.attempt({
    file: payload.file,
    input: {
      attributes: {
        description: payload.description,
        tags: payload.tags.split(/\s+/),
      },
      collection: payload.collection,
      name: payload.name,
      quantity: `${payload.quantity}`,
      royalties: (payload.royalties * 100).toString(),
    },
  })), [dispatch])
}
