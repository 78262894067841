import { Redirect } from 'react-router-dom'
import { useFirstExactMatch } from 'search/hooks/use-first-exact-match'
import { useSearchInput } from 'search/hooks/use-search-input'

export const AccountRedirect = () => {
  const input = useSearchInput()
  const result = useFirstExactMatch()

  if (result === 'address') return <Redirect to={`/${input}`} />

  return ''
}
