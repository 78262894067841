import 'components/player/content.scss'
import { useContext } from 'components/player/context'

export const Content = () => {
  const { container } = useContext()

  return <div className={'w-100 h-100 d-flex justify-content-center align-items-center bg-transparent'}>
    <video ref={container} className={'video-js vjs-16-9 bg-transparent player__video-container'} />
  </div>
}
