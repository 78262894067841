import { useResetState } from 'explore-nfts/behaviour/hooks/use-reset-state'
import { useFetchAssetTags } from 'tags/hooks/use-fetch-asset-tags'
import { useAppendSearchTag } from '../../tags/hooks/use-append-search-tag'

export const Behaviour = () => {
  useResetState()
  useFetchAssetTags()
  useAppendSearchTag('assets')

  return ''
}
