import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'account/tabs/assets/collections/slices/get'

export const useResetCollections = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(actions.cleanUp())
  }, [dispatch])
}
