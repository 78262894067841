import { BootstrapModal } from '../../../components/bootstrap-modal'
import { SetSftRoles } from './existing/sft'
import { SetRoles as SetNftRoles } from './existing/nft'
import { useCollectionsSelector } from '../../../collections/hooks/use-collections-selector'

export const SetRoles = () => {
  const [collection] = useCollectionsSelector()

  return <BootstrapModal id={'set-collection-roles'}>
    {collection?.node?.type === 'NonFungibleESDT' && <SetNftRoles /> }
    {collection?.node?.type === 'SemiFungibleESDT' && <SetSftRoles /> }
  </BootstrapModal>
}
