import { useMakeCounteredLink } from 'sidebar/my-wallet/menu/hooks/use-make-countered-link'
import { useIsSubdomain } from 'startup/hooks/use-is-subdomain'

const publicCounterLinks = [
  { id: 'collected', label: 'Gallery', tab: 'collected', omitTabInDestination: true },
  { id: 'collections', label: 'Collections', tab: 'collections' },
]

const subdomainCounterLinks = [
  { id: 'collected', label: 'My NFTs', modal: 'my-nfts' },
  { id: 'auctions', label: 'My Auctions', modal: 'my-auctions' },
  { id: 'bids', label: 'My Bids', modal: 'my-bids' },
  { id: 'claimable', label: 'My Claimable', modal: 'my-claimable' },
]

const staticUrls = [
  {
    label: 'Logout',
    to: '/logout',
  },
]

export const useMenuLinks = () => {
  const makeCounteredLink = useMakeCounteredLink()
  const links = useIsSubdomain() ? subdomainCounterLinks : publicCounterLinks

  return [
    ...links.map(makeCounteredLink),
    ...staticUrls,
  ]
}
