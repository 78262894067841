import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { actions as dataActions } from 'home/explore-nfts/slices/get'
import { useContext } from 'home/explore-nfts/context'

export const useResetData = () => {
  const { shouldReset, setShouldReset } = useContext()
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldReset) {
      dispatch(dataActions.reset())
      setShouldReset(false)
    }
  }, [shouldReset, dispatch, setShouldReset])
}
