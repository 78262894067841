import React from 'react'

export const Help = () => <p>
  Or, if it does not match, close this page and
  {' '}
  <a href={'https://help.elrond.com/en/'} target={'_blank'} rel={'noreferrer'}>
    contact support
  </a>
  .
</p>
