import { from, throwError, of } from 'rxjs'
import { getAddress } from '@elrondnetwork/dapp-core/utils'
import { catchError, mergeMap } from 'rxjs/operators'
import { settings } from 'settings/fetching'
import { denominate } from 'components/conversion'
import { humanize } from 'components/conversion/humanize'
import { rest } from 'network'
import { get } from 'lodash'

const hasEnoughBalance = ({ data, balance, payload, paymentToken }) => {
  const price = get(payload, 'price')
  const tokenName = paymentToken?.split('-')[0]
  const totalCosts = parseFloat(denominate(price))
  const humanizedTotalValue = humanize(denominate(price))
  const error = {
    graphQLErrors:
      [{ message: `Not enough token balance - Required ${humanizedTotalValue} ${tokenName}` }],
  }
  const parsedBalance = balance ? parseFloat(denominate(balance)) : 0

  return totalCosts <= parsedBalance ? of(data) : throwError(error)
}

const makeRestError = error => {
  if (error.graphQLErrors) {
    return error
  }

  return ({ result: { networkError: { result: { errors: [{ message: error.statusText }] } } } })
}

export const throwOnTokenInsufficientFunds = ({ data, payload, paymentToken }) => from(getAddress())
  .pipe(mergeMap(address => rest().get(`${settings().elrondApi}/accounts/${address}/tokens/${paymentToken}`)))
  .pipe(mergeMap(response => from(response.json())))
  .pipe(mergeMap(({ balance }) => hasEnoughBalance({ data, balance, payload, paymentToken })))
  .pipe(catchError(error => throwError(makeRestError(error))))
