import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { humanize } from 'components/conversion/humanize'
import { settings } from 'settings/fetching'

const decimals = () => settings().dapp.decimals

const egldLabel = () => settings().dapp.egldLabel

export const Description = ({ assetFees }) => {
  const { price } = useEconomicsSelector()
  const totalEgld = assetFees.toFixed(decimals())
  const usdTotal = (totalEgld * price).toFixed(decimals())

  return <div>
    <span>Total cash in after fees will be</span>
    {` `}
    <span className={'example-fee-item-text'}>{`~${humanize(totalEgld)} ${egldLabel()}`}</span>
    {` `}
    <span>{`(~${humanize(usdTotal)} USD).`}</span>
  </div>
}
