import { nominate } from 'components/conversion'
import { get } from 'lodash'

export const customSortFilters = {
  highestPrice: { direction: 'DESC', field: 'CURRENTPRICE' },
  lowestPrice: { direction: 'ASC', field: 'CURRENTPRICE' },
}
export const customFilters = (filters, dPriceRange) => [...currentPrice(filters, dPriceRange)]
const currentPrice = (filters, dPriceRange) => shouldAddCustomFilter(filters) ? [price(filters, dPriceRange)] : []
export const shouldAddCustomFilter = filters => hasValues(filters) || hasSortBy(filters)
const sortByPrice = sortBy => get(customSortFilters, sortBy, { direction: 'ASC', field: 'CURRENTPRICE' })
const hasValues = ({ minVal, maxVal }) => (minVal || maxVal)
const hasSortBy = ({ sortBy }) => (sortBy === 'highestPrice' || sortBy === 'lowestPrice')

const price = (filters, dPriceRange) => {
  const minVal = filters.minVal ? nominate(filters.minVal) : dPriceRange.minVal
  const maxVal = filters.maxVal ? nominate(filters.maxVal) : dPriceRange.maxVal

  return {
    field: 'CURRENTPRICE',
    op: 'BETWEEN',
    values: [minVal, maxVal],
    sort: sortByPrice(filters.sortBy),
  }
}

