import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useHasMore } from 'account/tabs/assets/bids/hooks/use-has-more'
import { useNext } from 'account/tabs/assets/bids/hooks/use-next'
import { Row } from 'components/row'
import { List } from 'account/tabs/assets/bids/list'
import { Empty } from 'account/tabs/assets/bids/empty'
import { useInit } from 'account/tabs/assets/bids/hooks/use-init'

export const Content = () => {
  useInit()

  return <InfiniteScroll hasMore={useHasMore()} next={useNext()}>
    <Row className={'assets-container'}>
      <List />
      <Empty />
    </Row>
  </InfiniteScroll>
}
