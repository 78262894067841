import { createContext, useContext, useState } from 'react'

export const AppContext = createContext({})

export const useAppContext = () => useContext(AppContext)

export const useAppContextValue = () => {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [footerVisible, setFooterVisible] = useState(true)

  const [accessToken, setAccessToken] = useState(false)
  const [dappReinit, setDappReinit] = useState('')

  return {
    sidebarOpened,
    setSidebarOpened,
    footerVisible,
    setFooterVisible,
    mobileMenuOpen,
    setMobileMenuOpen,
    accessToken,
    setAccessToken,
    dappReinit,
    setDappReinit,
  }
}
