import { Loading } from 'loading'
import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { actions } from 'explore-nfts/collections/slices/get'
import { useExploreCollectionsSelector } from 'explore-nfts/collections/hooks/use-explore-collections-selector'
import { useHistory } from 'react-router-dom'
import { settings } from 'settings/fetching'

const useIssueServer = () => {
  const { attempt } = actions
  const dispatch = useDispatch()
  const { cursor } = useExploreCollectionsSelector()

  return useCallback(() => {
    const payload = { pagination: { first: settings().listRequestSize, after: cursor } }
    dispatch(attempt(payload))
  }, [dispatch, attempt, cursor])
}

export const useAssetsFetching = () => {
  const hideGhosts = Loading.useHideGhosts()
  const showGhosts = Loading.useShowGhosts()
  const { location: { pathname } } = useHistory()
  const issueServer = useIssueServer()

  useEffect(() => {
    hideGhosts(Loading.ID.explore.page)
    showGhosts(Loading.ID.explore.page, settings().listRequestSize)
    issueServer()
  }, [pathname, hideGhosts, issueServer, showGhosts])
}
