import { TagButton } from 'components/tag-button'
import { useOnTagClick } from 'home/explore-nfts/filters/hooks/use-on-tag-click'
import { useIsLoading } from 'home/explore-nfts/filters/hooks/use-is-loading'
import { useIsTagSelected } from 'home/explore-nfts/filters/hooks/use-is-tag-selected'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { useAssetTags } from 'tags/hooks/use-asset-tags'

export const TagsSelector = () => {
  const onClick = useOnTagClick()
  const isSelected = useIsTagSelected()
  const isLoading = useIsLoading()
  const tags = useAssetTags()

  return <Swiper freeMode slidesPerView={'auto'}>
    {tags.map(tag => <SwiperSlide key={tag.label}>
      <TagButton {...tag} selected={isSelected(tag)} disabled={isLoading} onClick={() => onClick(tag)} />
    </SwiperSlide>)}
  </Swiper>
}
