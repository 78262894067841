import { BN } from 'bn.js'
import { get } from 'lodash'

export const decimals = value => {
  const split = value?.split('.')

  return get(split, '[1].length', 0)
}

export const nominate = value => {
  const power = 18 - decimals(value)
  const exponent = new BN('10').pow(new BN(power))
  const result = new BN(value?.replace('.', '')).mul(exponent)

  return result.isZero() ? '0000000000000000000' : result.toString()
}
