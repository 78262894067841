import { crop } from './crop'
import { ensure } from './ensure'
import { settings } from 'settings/fetching'

const getDenominationDecimals = () => settings().dapp.decimals

export const denominate = (value, customDecimals = getDenominationDecimals()) => {
  value = value.padStart(18, '0')
  const decimals = value.slice(-18)
  const integers = value.substring(0, value.length - 18)

  return `${ensure(integers)}.${crop(decimals, customDecimals)}`
}
