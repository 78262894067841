import { Failure } from 'failure'
import { Seo } from 'components/seo'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/bids/content'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { ContractWrapper } from 'components/contract-wrapper'

export const Bids = () => <VisibleIfActive tab={'bids'}>
  <Seo {...useSeoProps('bids')} />

  <Failure.Partial id={'account.bids'}>
    <TabHeader />
    <ContractWrapper fullscreen>
      <Content />
    </ContractWrapper>
  </Failure.Partial>
</VisibleIfActive>
