/* eslint-disable max-lines-per-function */
import { Button } from 'components/button/index'
import { Header } from 'featured-pages/components/header/index'
import { TextImage } from 'featured-pages/components/text-image/index'
import maiar from 'startup/assets/create-get-maiar.png'
import collection from 'startup/assets/create-collection.png'
import nft from 'startup/assets/create-nft.png'
import { Footer } from 'featured-pages/components/footer/index'
import { useAccountNavigate } from 'featured-pages/hooks/use-account-navigate'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount/index'

export const Create = () => {
  const { address } = useGetAccountInfo()
  const collectionNavigate = useAccountNavigate(`${address}/collections/modal/create-collection/issue`)
  const nftNavigate = useAccountNavigate(`${address}/collections`)

  return <>
    <ScrollToTopOnMount />
    <Header>
      <h1>Create your first NFT</h1>
      <p>
        Are you ready to etch your style into the internet, forever? It’s much easier than you think.
        All you need to make Web3 art is a pic and Maiar.
      </p>
      <Button buttonType={'primary'} onClick={collectionNavigate}>Create Now</Button>
    </Header>

    <TextImage image={maiar}>
      <h2>Get Maiar</h2>
      <p>Maiar is a free mobile app that works on any phone. It teleports you to Web3 in a few seconds.</p>
      <a href={'https://maiar.com/'} target={'_blank'} rel={'noreferrer'}>Start here</a>
    </TextImage>

    <TextImage image={collection} reversed>
      <h2>Start a collection</h2>
      <p>Organize your creativity into sets. Like shelves, but digital. Collections make it easy to group NFTs.</p>
      <button className={'btn btn-link'} onClick={collectionNavigate}>Create collection</button>
    </TextImage>

    <TextImage image={nft}>
      <h2>Create NFTs</h2>
      <p>When creating an NFT, you supply the media, name and royalties percentage. xSpotlight does the rest.</p>
      <p>PS: tag your creations meaningfully, so others may find them.</p>
      <button className={'btn btn-link'} onClick={nftNavigate}>Create NFT</button>
    </TextImage>

    <Footer />
  </>
}
