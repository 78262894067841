import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const Item = ({ item, previewSource }) => {
  const auction = get(item, 'node', {})
  const asset = get(item, 'node.asset', {})
  const isGhost = get(item, 'isGhost')

  return <AssetCard asset={asset} auction={auction} previewSource={previewSource} isGhost={isGhost} />
}
