import { EgldBalance } from 'sidebar/my-wallet/balance/egld-balance'
import { BiddingBalance } from 'sidebar/my-wallet/balance/bidding-balance'
import { useSidebarStats } from 'sidebar/stats/hooks/use-sidebar-stats'
import 'sidebar/my-wallet/balance/balance.scss'
// import { ReactComponent as Icon } from 'startup/assets/elrond-logo-blue.svg'

export const Balance = () => {
  const { biddingBalance } = useSidebarStats()
  const hasBiddingBalance = biddingBalance && parseFloat(biddingBalance) !== 0

  return <div className={'sidebar-balance d-flex justify-content-between'}>
    {/* <div className={'sidebar-balance-icon'}>
      <Icon />
    </div> */}
    <EgldBalance />
    {hasBiddingBalance ? <BiddingBalance /> : ''}
  </div>
}
