import { useParams } from 'react-router-dom'
import { useResetList } from 'account/tabs/assets/collections/hooks/use-reset-list'
import { useResetCollections } from 'account/tabs/assets/collections/hooks/use-reset-collections'
import { useWatchAction } from 'action-statuses/hooks/use-watch-action'
import { useRefetch } from 'account/tabs/assets/collections/hooks/use-refetch'
import { useFetchNotifications } from '../../../../../../notifications/account/hooks/use-fetch-notifications'

export const useTransactionWatch = () => {
  const { address } = useParams()
  const refetch = useRefetch()
  const resetList = useResetList()
  const resetCollections = useResetCollections()
  const fetchNotifications = useFetchNotifications()

  useWatchAction({
    prefix: `${address}`,
    onChange: () => {
      resetList()
      resetCollections()
      refetch()
      fetchNotifications()
    },
  })
}
