import { useEffect } from 'react'
import { useIdentityInit } from '../../auth/hooks/use-identity-init'
import { useResetLoginToken } from '../../auth/hooks/use-reset-login-token'
import { useAppContext } from '../../startup/context'

export const useInitTokenOnSidebar = () => {
  const { sidebarOpened } = useAppContext()
  const identityInit = useIdentityInit()
  const resetLoginToken = useResetLoginToken()

  useEffect(() => {
    sidebarOpened && identityInit()
    !sidebarOpened && resetLoginToken()
  }, [sidebarOpened, identityInit, resetLoginToken])
}
