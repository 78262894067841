import { from, map } from 'rxjs'
import { extras } from '../../repositories/extras'
import { catchError } from 'rxjs/operators'

const restPayload = (payload, accessToken) => ({
  ...payload,
  headers: { ...payload.headers, authorization: `Bearer ${accessToken}` },
})

const makePayload = payload => from(extras.getAccessToken())
  .pipe(map(accessToken => restPayload(payload, accessToken)))
  .pipe(catchError(e => console.log('Could not get internal api access token', e)))

export const internal = { makePayload }
