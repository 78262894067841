import { get } from 'lodash'
import defaultCover from 'startup/assets/cover.png'

const makeProps = ({ cover, address }) => ({
  style: { backgroundImage: `url(${get(cover, 'url', defaultCover)})` },
  alt: `${address}'s cover`,
  className: 'initial-suggestion-card-cover',
})

export const Cover = props => <div {...makeProps(props)} />
