import { Link as RouterLink } from 'react-router-dom'

import { Counter } from 'sidebar/my-wallet/menu/counter'

const defaultLinkClassname = 'sidebar-menu-link'
const alignClass = 'd-flex align-items-center justify-content-center'

const makeLinkClassname = () => `${defaultLinkClassname} ${alignClass}`

export const Link = ({ to, label, counter }) => <li className={'sidebar-menu-item'}>
  <RouterLink to={to} className={makeLinkClassname()}>
    {label}
    {counter !== undefined && <Counter counter={counter} />}
  </RouterLink>
</li>
