import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

export const useOnClick = () => {
  const { push } = useHistory()

  return useCallback(tag => {
    const parsed = new URLSearchParams('')
    parsed.append('tag', tag)

    push({ pathname: `/explore`, search: parsed.toString(), state: { forceReset: true } })
  }, [push])
}
