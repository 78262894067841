/* eslint-disable max-lines-per-function */
import { Button } from 'components/button/index'
import { Header } from 'featured-pages/components/header/index'
import { TextImage } from 'featured-pages/components/text-image/index'
import nft from 'startup/assets/learn-nft.png'
import useful from 'startup/assets/learn-useful.png'
import streams from 'startup/assets/learn-streams.png'
import { Footer } from 'featured-pages/components/footer/index'
import { useAccountNavigate } from 'featured-pages/hooks/use-account-navigate'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount/index'

export const Learn = () => {
  const { address } = useGetAccountInfo()
  const accountNavigate = useAccountNavigate(`${address}/collections/modal/create-collection/issue`)

  return <>
    <ScrollToTopOnMount />
    <Header>
      <h1>Learn about NFTs</h1>
      <p>“NFTs” in art might seem esoteric. Understanding them looks like an endeavor for the initiated. It is.</p>
      <p>Same goes for “terroir” in wine and “SMTP” in email. You don’t need to understand them to enjoy them.</p>
      <Button buttonType={'primary'} onClick={accountNavigate}>Create Now</Button>
    </Header>

    <TextImage image={nft}>
      <h2>But still, what is an NFT?</h2>
      <p>
        NFTs are unique digital items.
        They live on a new kind of internet, for everyone to see, and only you to own.
      </p>
      <p>NFT stands for “non-fungible token”. And this new kind of internet is powered by “blockchain”.</p>
    </TextImage>

    <TextImage image={useful} reversed>
      <h2>New ways of useful</h2>
      <p>
        NFTs can be art. But they can also be other things.
        If they can be digitized and their authenticity is important, they can be NFTized.
      </p>
      <p>Think paintings and photos and music, but also identity, deeds, certificates, concert tickets.</p>
    </TextImage>

    <TextImage image={streams}>
      <h2>Novel income streams</h2>
      <p>
        Utility means value. NFTs can be bought and sold.
        Their creators enjoy royalties forever. Automatic, inexpensive and immediately after each sale.
      </p>
    </TextImage>

    <Footer />
  </>
}
