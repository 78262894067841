import { useCallback } from 'react'
import { AdminSidebarEnabled } from 'components/layered-router/hooks/use-render-route/admin-sidebar-enabled'
import { RouteComponent } from 'components/layered-router/hooks/use-render-route/route-component'
import match from '@nerds.sh/js-pattern'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useIsPrinceCharming } from 'auth/mega-authorized/index'
import { useParamsGlobal } from 'components/layered-router/hooks/use-params-global'

const checkNotAuthenticated = (route, isPrinceCharming) => (route.authenticatedRoute && !isPrinceCharming)

const checkDirectNavigation = (route, state) => (route.preventDirectNavigation && !state)

export const useRenderRoute = () => {
  const isPrinceCharming = useIsPrinceCharming()
  const { replace } = useHistory()
  const params = useParamsGlobal()
  const location = useLocation()

  return useCallback((route, props) => {
    const redirectPath = generatePath(route.redirectPath, params)
    const redirectNotAuthenticated = checkNotAuthenticated(route, isPrinceCharming)
    const redirectDirectNavigation = checkDirectNavigation(route, location.state)
    const shouldRedirect = redirectNotAuthenticated || redirectDirectNavigation

    return match(route)
      .with(shouldRedirect, () => replace(redirectPath))
      .with(route.adminSidebar && !shouldRedirect, () => <AdminSidebarEnabled route={route} {...props} />)
      .otherwise(() => <RouteComponent {...route} {...props} />)
  }, [isPrinceCharming, location, params, replace])
}

