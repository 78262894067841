import { useHistory } from 'react-router-dom'

import queryString from 'query-string'
import { useMemo } from 'react'

export const useFilters = () => {
  const { location: { state, search } } = useHistory()

  return useMemo(() => ({
    search: queryString.parse(search),
    background: queryString.parse(state?.layerLink?.background?.split('?')[1]),
    foreground: queryString.parse(state?.layerLink?.foreground?.split('?')[1]),
  }), [search, state])
}
