import videojs from 'video.js'
import { useEffect } from 'react'
import { useContext } from 'components/player/context'

export const useSetup = () => {
  const { player, container, options, onReady, sources } = useContext()

  useEffect(() => {
    if (player.current) return

    player.current = videojs(container.current, options, onReady)
  }, [player, container, options, onReady, sources])
}
