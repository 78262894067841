import { range } from 'lodash'
import asset from 'loading/ghosts/asset.json'
import order from 'loading/ghosts/order.json'
import auction from 'loading/ghosts/auction.json'
import collection from 'loading/ghosts/collection.json'

const assets = count => range(0, count).map(() => asset)

const auctions = count => range(0, count).map(() => auction)

const orders = count => range(0, count).map(() => order)

const collections = count => range(0, count).map(() => collection)

export const ghosts = { assets, auctions, orders, collections }
