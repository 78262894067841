import { useOnChange } from 'collections/actions/on-sale-filter/hooks/use-on-change'
import { useIsOnSale } from 'collections/actions/on-sale-filter/hooks/use-is-on-sale'
import 'collections/actions/on-sale-filter/index.scss'

export const OnSaleFilter = () => {
  const onChange = useOnChange()
  const isChecked = useIsOnSale()

  return <div className={'on-sale-filter'}>
    <label className={'on-sale-filter-inner'} htmlFor={'onSaleFilterInput'}>
      <div className={'form-check form-switch'}>
        <label className={'form-check-label'} htmlFor={'onSaleFilterInput'}>On Sale</label>
        <input className={'form-check-input primary-bg'} type={'checkbox'} id={'onSaleFilterInput'} onChange={onChange}
               defaultChecked={isChecked} />
      </div>
    </label>
  </div>
}
