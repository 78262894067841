import { get, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

export const useCurrentLayer = () => {
  const { location: { pathname, state } } = useHistory()
  const layerLink = get(state, 'layerLink', {})

  if (isEmpty(layerLink)) return

  return Object.keys(layerLink).find(key => layerLink[key] && layerLink[key].startsWith(pathname))
}
