import { useHadSuccess } from 'modals/account/edit/hooks/use-act-on-social-links-success/use-had-success'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'modals/account/edit/social-links/slices/update-social-links'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useActOnSocialLinksSuccess = () => {
  const hadSuccess = useHadSuccess()
  const { replace } = useHistory()
  const address = useViewerAddress()
  const dispatch = useDispatch()

  return useCallback(() => {
    if (hadSuccess) {
      dispatch(actions.reset())
      replace(`/${address}`)
    }
  }, [hadSuccess, dispatch, replace, address])
}
