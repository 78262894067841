import { useContext } from 'home/explore-nfts/context'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const isSelected = (urlTags, tag) => urlTags.split(',').find(urlTag => urlTag === tag.value)

const formatPrevious = parsed => tag => isSelected(parsed.get('tags'), tag) ? ({ ...tag, selected: true }) : tag

export const useSetTags = () => {
  const { setTags } = useContext()
  const { search } = useLocation()

  useEffect(() => {
    const parsed = new URLSearchParams(search)
    parsed.get('tags') && setTags(previous => previous.map(formatPrevious(parsed)))
  }, [search, setTags])
}
