import { useBadgeVisibility } from 'components/asset-card/badges/hooks/use-badge-visibility'
import 'components/asset-card/badges/index.scss'
import { Badge, BadgeType } from 'components/asset-card/badge'
import { TimeLeftBadge } from 'components/asset-card/badges/time-left-badge'
import { VerifiedBadge } from 'components/verified-badge/index'
import { get } from 'lodash'

export const Badges = props => {
  const { timeLeft, outBidded, auctionEnded, topBidder } = useBadgeVisibility(props)
  const verified = get(props, 'asset.verified')

  return <div className={'asset-card-badges'}>
    <TimeLeftBadge auctionEnded={auctionEnded} auction={props.auction} timeLeft={timeLeft} />
    <Badge label={'Outbidded'} type={BadgeType.Outbidded} condition={outBidded} />
    <Badge label={'Ended'} type={BadgeType.Ended} condition={auctionEnded && props.showEndedBadge} />
    <Badge label={'TopBidder'} type={BadgeType.TopBidder} condition={topBidder} />
    {verified && <VerifiedBadge />}
  </div>
}
