import PropTypes from 'prop-types'
import 'nft-page/controls/button/control-button.scss'
import { getIcon } from 'nft-page/controls/button/get-icon'
import { Link, useHistory } from 'react-router-dom'

const hidden = disabled => disabled ? 'd-none' : ''

export const ControlButton = ({ disabled, to, type }) => {
  const className = `btn-control type-${type} d-flex align-items-center justify-content-center ${hidden(disabled)}`
  const { replace, goBack, location: { state } } = useHistory()

  const handleClick = event => {
    event.preventDefault()

    if (type === 'close' && !state.hydrated) {
      goBack()
    } else {
      replace(to)
    }
  }

  return <Link disabled={disabled} to={to} className={className} onClick={handleClick}>
    <span>{getIcon(type)}</span>
  </Link>
}

ControlButton.defaultProps = {
  to: '/',
  disabled: false,
}

ControlButton.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

