import { makeNotification } from 'notifications/account/make-notification'
import { Link } from 'react-router-dom'

export const Item = ({ identifier, name, auctionId, type }) => {
  const destination = `/nfts/${identifier}/auction/${auctionId}`

  return (
    <Link to={destination} className={`account-notifications-item dropdown-item ${type.toLowerCase()}`}>
      {makeNotification(name, type)}
    </Link>
  )
}
