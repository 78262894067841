import { Helmet } from 'react-helmet-async'
import { useMakeTitle } from 'components/seo/hooks/use-make-title'

export const Seo = ({ title, description = '', image = '' }) => {
  const seoTitle = useMakeTitle(title)

  return <Helmet>
    <title>{seoTitle}</title>
    <meta property={'og:title'} content={seoTitle} />

    <meta name={'description'} content={description} />
    <meta property={'og:description'} content={description} />

    <meta property={'og:image'} content={image} />
  </Helmet>
}
