import PropTypes from 'prop-types'
import { Layout } from './layout'
import { Dropzone } from './fieldsets/dropzone/dropzone'
import { Fields } from './fieldsets/fields'

export const CreateSftForm = props => <Layout {...props}>
  <Dropzone />
  <Fields submitErrors={props.submitErrors} />
</Layout>

CreateSftForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.any.isRequired,
  initialValues: PropTypes.any.isRequired,
  callbackUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}
