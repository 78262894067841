import { graph } from 'network'
import { GET_AUCTIONS, COUNT, GET_CLAIMABLE, CLAIMABLE_COUNT, PRICE } from 'repositories/auctions/queries'
import { CREATE_AUCTION, END_AUCTION, PLACE_BID, BUY_SFT, WITHDRAW } from 'repositories/auctions/mutations'
import { HOME_TRENDING_SORTED_BY_BIDS } from 'repositories/auctions/queries/home-trending-sorted-by-bids'
import { TRENDING_SORTED_BY_BIDS } from 'repositories/auctions/queries/trending-sorted-by-bids'
import { GET_MARKET_PLACE_AUCTIONS } from 'repositories/auctions/queries/market-place-auctions'
import { GET_ACCOUNT_AUCTIONS } from 'repositories/auctions/queries/account-auctions'
import { GET_SELECTED_AUCTION } from 'repositories/auctions/queries/selected-auction'
import { GET_CLAIMABLE_AUCTIONS } from 'repositories/auctions/queries/claimable-auctions'
import { GET_RUNNING_AUCTIONS } from 'repositories/auctions/queries/running-auctions'
import { GET_COLLECTION_AUCTIONS } from 'repositories/auctions/queries/account-collection-auctions'

export const auctions = {
  getHomeTrendingSortedByBids: variables => graph().query({ query: HOME_TRENDING_SORTED_BY_BIDS, variables }),
  getTrendingSortedByBids: variables => graph().query({ query: TRENDING_SORTED_BY_BIDS, variables }),
  getMarketPlaceAuctions: payload => graph().query({ query: GET_MARKET_PLACE_AUCTIONS, variables: payload }),
  getAccountAuctions: payload => graph().query({ query: GET_ACCOUNT_AUCTIONS, variables: payload }),
  getCollectionAuctions: payload => graph().query({ query: GET_COLLECTION_AUCTIONS, variables: payload }),
  getSelectedAuction: payload => graph().query({ query: GET_SELECTED_AUCTION, variables: payload }),
  getClaimableAuction: payload => graph().query({ query: GET_CLAIMABLE_AUCTIONS, variables: payload }),
  getRunningAuction: payload => graph().query({ query: GET_RUNNING_AUCTIONS, variables: payload }),
  getAuctions: payload => graph().query({ query: GET_AUCTIONS, variables: payload }),
  createAuction: payload => graph().mutate({ mutation: CREATE_AUCTION, variables: { input: payload } }),
  endAuction: payload => graph().mutate({ mutation: END_AUCTION, variables: payload }),
  placeBid: payload => graph().mutate({ mutation: PLACE_BID, variables: payload }),
  buySft: payload => graph().mutate({ mutation: BUY_SFT, variables: payload }),
  withdraw: payload => graph().mutate({ mutation: WITHDRAW, variables: payload }),
  count: variables => graph().query({ query: COUNT, variables }),
  claimable: variables => graph().query({ query: GET_CLAIMABLE, variables }, true),
  claimableCount: variables => graph().query({ query: CLAIMABLE_COUNT, variables }, true),
  price: variables => graph().query({ query: PRICE, variables }),
}
