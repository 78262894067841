import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useLayeredLocation } from 'components/layered-router/hooks/use-layered-location'

export const useLayeredNavigate = () => {
  const { push } = useHistory()
  const layeredLocation = useLayeredLocation()

  return useCallback((path, layerName, previewSource) => {
    push(layeredLocation(path, layerName, previewSource))
  }, [push, layeredLocation])
}
