import { useCallback } from 'react'
import { denominate } from 'components/conversion'
import { useMaximum } from 'components/forms/place-bid-form/hooks/use-maximum'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const useMaximumMessage = auction => {
  const maximum = useMaximum(auction)

  return useCallback(() => `Must be less or equal than ${denominate(maximum)} ${egldLabel()}`, [maximum])
}
