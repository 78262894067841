import { Buy } from 'nft-page/auction/actions/buy-or-bid/buy'
import { Bid } from 'nft-page/auction/actions/buy-or-bid/bid'
import { Details } from 'nft-page/auction/details'
import 'nft-page/auction/actions/buy-or-bid/index.scss'

export const BuyOrBid = ({ auction, address }) => <div className={'nft-page-auction-inner buy-or-bid-actions'}>
  <Details auction={auction} />
  <div className={'d-flex'}>
    <Buy auction={auction} address={address} />
    <Bid auction={auction} address={address} />
  </div>
</div>

BuyOrBid.displayName = 'BuyOrBid'
