import { Button } from 'components/button'
import PropTypes from 'prop-types'
import 'components/like-button/style.scss'

const useProps = ({ onClick }) => ({
  className: 'like-button',
  icon: 'heart',
  onClick,
  buttonType: 'secondary',
})

export const LikeButton = props => <Button {...useProps(props)}>
  {props.count}
  {' '}
  <span className={'d-none d-sm-inline'}>likes</span>
</Button>

LikeButton.propTypes = { onClick: PropTypes.func.isRequired }
