import { Label } from './label'
import { Dropdown } from '../../../components/dropdown'
import { FlexRow } from '../../../components/flex-row'

export const DropdownFilter = ({ label, options, id, initial, onChange = () => {}, className = '' }) => (
  <FlexRow className={`align-items-center ${className}`}>
    <Label label={label} />
    <Dropdown id={id} initial={initial} onChange={onChange} options={options} />
  </FlexRow>
)

