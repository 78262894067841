import { Header } from 'components/forms/nerds-form/fields/final-fee-field/header'
import { RoyaltiesInformation } from 'components/forms/nerds-form/fields/final-fee-field/royalties-information'
import { CutPercentage } from 'components/forms/nerds-form/fields/final-fee-field/cut-percentage'
import { ExampleFee } from 'components/forms/nerds-form/fields/final-fee-field/example-fee'
import { Label } from 'components/forms/nerds-form/fields/label'
import 'components/forms/nerds-form/fields/final-fee-field/index.scss'
import { useIsCreator } from 'components/forms/nerds-form/fields/hooks/use-is-creator'

export const FinalFeeField = ({ asset, labelText, assetFees, exampleValue }) => {
  const isCreator = useIsCreator(asset)

  return !isNaN(assetFees) && <div className={'accordion'}>
    <Label className={'form-label label-field'}>{labelText}</Label>
    <div className={'accordion-item final-fee-field'}>
      <Header assetFees={assetFees} exampleValue={exampleValue} />
      <div id={'finalFees'} className={'accordion-collapse collapse'}>
        <div className={'accordion-body'}>
          {!isCreator && <RoyaltiesInformation asset={asset} />}
          <CutPercentage />
          <ExampleFee exampleValue={exampleValue} assetFees={assetFees} />
        </div>
      </div>
    </div>
  </div>
}
