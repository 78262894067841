import './description.scss'

export const Description = () => <div className={'footer-description mt-3 pe-5'}>
  <p className={'fw-bolder'}>
    Own a piece of Web3 culture.
  </p>
  <p>
    The front-page of the Elrond NFT ecosystem. Create, buy and sell NFTs.
    Collaborate with fellow collectors and creators.
    Be part of the genesis of Web3 culture and own a piece of it.
  </p>
</div>
