import { MyNfts } from 'subdomain/modals/account/my-nfts'
import { MyAuctions } from 'subdomain/modals/account/my-auctions'
import { MyBids } from 'subdomain/modals/account/my-bids'
import { MyClaimable } from 'subdomain/modals/account/my-claimable'
import { useRedirectAccountModals } from 'subdomain/modals/hooks/use-redirect-account-modals'

export const Account = () => {
  useRedirectAccountModals()

  return <>
    <MyNfts />
    <MyAuctions />
    <MyBids />
    <MyClaimable />
  </>
}

export * from 'subdomain/modals/account/reducers'
export * from 'subdomain/modals/account/epics'
