import { useLoginToken } from 'auth/hooks/use-login-token'
import * as loginServices from '@elrondnetwork/dapp-core/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useRedirectableLogin } from 'sidebar/connect/hooks/use-redirectable-login'

export const useExtensionLogin = () => {
  const loginToken = useLoginToken()
  const callbackRoute = useCallbackRoute()

  const [onInitiateLogin] = loginServices.useExtensionLogin({
    callbackRoute,
    token: loginToken,
  })

  return useRedirectableLogin(() => {
    onInitiateLogin()
  }, [onInitiateLogin])
}
