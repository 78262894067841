import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import * as loginServices from '@elrondnetwork/dapp-core/hooks/login'
import { useRedirectableLogin } from 'sidebar/connect/hooks/use-redirectable-login'
import { Content } from 'sidebar/connect/ledger-login/ledger-container/content'
import 'sidebar/connect/ledger-login/ledger-container/style.scss'

export const LedgerContainer = ({ callbackRoute, token }) => {
  const { ledgerAccount } = useGetAccountInfo()
  const [onStartLogin, state, options] = loginServices.useLedgerLogin({ callbackRoute, token })
  const login = useRedirectableLogin(() => {
    onStartLogin()
  }, [])

  return <Content ledgerAccount={ledgerAccount} token={token} login={login} {...state} {...options} />
}
