import { useOnClick } from 'auction/actions/buy-sft/hooks/use-on-click'
import { FlexRow } from 'components/flex-row'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/details/other-listings/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { formatToken } from 'common/format-token'

export const BuySft = ({ auction, address }) => {
  const buySftClick = useOnClick(auction)
  const onClick = usePermittedAction(buySftClick, address)
  const maxBidAmount = get(auction, 'maxBid.amount')
  const maxBidToken = get(auction, 'maxBid.token')
  const price = `${(humanize(denominate(maxBidAmount)) * 1)} ${formatToken(maxBidToken)}`

  return <FlexRow>
    <WatchableAction entity={auction} actionType={actionTypes.buyNowSft} text={'Buy'} onClick={onClick}
                     className={'btn btn-secondary other-auction-action'} price={price} />
  </FlexRow>
}
