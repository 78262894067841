import { useCallback } from 'react'
import { actions } from '../../slices/tag'
import { useDispatch } from 'react-redux'

export const useOnOptionClick = () => {
  const dispatch = useDispatch()

  return useCallback(option => {
    dispatch(actions.set(option))
  }, [dispatch])
}
