import { useFilters } from '../../hooks/use-filters'
import { useDebounceChange } from './use-debounce-change'
import { denominate } from '../../../components/conversion'
import { useMaxValue } from './hooks/use-max-value'
import { useMinValue } from './hooks/use-min-value'
import { useCallback } from 'react'
import { MultiRange } from 'filters/auctions/multi-range'
import { usePriceRange } from 'collections/auctions/hooks/use-price-range'

export const PriceRange = () => {
  const { search } = useFilters()
  const onChange = useDebounceChange(500)

  const { maxBid, minBid } = usePriceRange()

  const maxValue = useMaxValue(search, maxBid)
  const minValue = useMinValue(search, minBid)

  const props = useCallback(() => ({
    step: 0.0001,
    min: parseFloat(denominate(minBid.amount)),
    max: parseFloat(denominate(maxBid.amount)),
    initialMin: minValue(),
    initialMax: maxValue(),
    onChange,
  }), [minBid.amount, maxBid.amount, minValue, maxValue, onChange])

  return <div className={`d-flex flex-grow-1`}>
    {maxBid && minBid && <MultiRange {...props()} />}
  </div>
}
