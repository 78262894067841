import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsPageColor = () => {
  const data = useCmsData()
  const pageColor = get(data, 'data.data.attributes.pageColor')

  useEffect(() => {
    if (pageColor) {
      document.body.style.setProperty('--bs-body-bg', pageColor)
    }
  }, [pageColor])
}
