import { issueSftSchema } from './issue-sft-schema'

export const config = ({ onSubmit, initialValues, initialTouched }) => ({
  initialValues: issueSftSchema().cast(initialValues),
  validationSchema: issueSftSchema(),
  validateOnChange: true,
  enableReinitialize: true,
  initialTouched,
  onSubmit,
})
