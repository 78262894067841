import { useSetStep } from 'sidebar/connect/hooks/use-set-step'
import { STEP } from 'sidebar/connect/constants'
import { ConnectButton } from 'sidebar/connect/connect-button'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'

export const Button = () => {
  const className = useIsDarkTheme() ? 'dark-mode' : ''

  return <ConnectButton LeftIcon={ConnectButton.Icon.Ledger} onClick={useSetStep(STEP.ledgerLogin)}
                        className={className}>
    Ledger
  </ConnectButton>
}
