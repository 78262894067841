import { Behaviour } from 'modals/auction/behaviour'
import { Start } from 'modals/auction/start'
import { End } from 'modals/auction/end'
import { Claim } from 'modals/auction/claim'
import { BuySft } from 'modals/auction/buy-sft'
import { Withdraw } from 'modals/auction/withdraw'
import { Bid } from 'modals/auction/bid'
import { Suspense } from 'modals/auction/suspense'
import { AuctionBehaviour } from 'modals/auction/auction-behaviour'

export const Auction = () => <>
  <Behaviour />
  <Start />
  <Suspense>
    <AuctionBehaviour />
    <End />
    <Claim />
    <BuySft />
    <Withdraw />
    <Bid />
  </Suspense>
</>

export * from 'modals/auction/reducers'
export * from 'modals/auction/epics'
