import { useCallback } from 'react'
import { useRouteFilter } from 'common/hooks/use-route-filter'

export const useOnTagClick = () => {
  const changeRoute = useRouteFilter()

  return useCallback(({ value: tag }) => {
    changeRoute({ name: 'tag', value: tag })
  }, [changeRoute])
}
