import { Details } from 'nft-page/auction/details'
import { useOnClick } from 'auction/actions/place-bid/buy/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from 'nft-page/auction/actions/watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { formatToken } from 'common/format-token'

export const BuyNft = ({ auction, address }) => {
  const buyClick = useOnClick(auction)
  const onClick = usePermittedAction(buyClick, address)
  const maxBidAmount = get(auction, 'maxBid.amount')
  const maxBidToken = get(auction, 'maxBid.token')
  const price = `${(humanize(denominate(maxBidAmount)) * 1)} ${formatToken(maxBidToken)}`

  return <div className={'col nft-page-auction-inner'}>
    <Details auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Buy Now'} onClick={onClick} price={price} />
  </div>
}

BuyNft.displayName = 'BuyNft'
