import PropTypes from 'prop-types'
import { Header } from 'components/data-card/header'
import 'components/data-card/data-card.scss'

const subdomainClassName = centeredTitle => centeredTitle ? 'd-flex justify-content-center mb-4' : ''

export const DataCard = props => {
  const { subtitle } = props

  return <div className={`data-card ${props.className || ''}`}>
    <div className={`container ${subdomainClassName(props.centeredTitle)}`}>
      <Header {...props} />
      {subtitle}
    </div>
    <div className={!props.disableContentContainer ? 'container' : ''}>
      <div className={'pt-4 w-100'}>
        {props.children}
      </div>
    </div>
  </div>
}

DataCard.defaultProps = {
  title: '',
  showAllLink: '',
  exploreLink: '',
  filterComponent: '',
  disableContentContainer: false,
  headerAction: '',
  hiddenHeader: false,
  smallTitle: false,
  centeredTitle: false,
}

DataCard.propTypes = {
  title: PropTypes.string,
  showAllLink: PropTypes.string,
  exploreLink: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  filterComponent: PropTypes.node,
  disableContentContainer: PropTypes.bool,
  headerAction: PropTypes.node,
  hiddenHeader: PropTypes.bool,
  smallTitle: PropTypes.bool,
  centeredTitle: PropTypes.bool,
}
