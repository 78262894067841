import { useEffect } from 'react'
import { useResetList } from 'account/tabs/assets/collections/hooks/use-reset-list'
import { useResetCollections } from 'account/tabs/assets/collections/hooks/use-reset-collections'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetCollections = useResetCollections()
  const resetList = useResetList()
  const address = useViewerAddress()

  useEffect(() => {
    resetCollections()
    resetList()
  }, [resetCollections, resetList, address])
}
