import { useCreate } from 'modals/collection/asset/hooks/use-create'
import { useIssueSelector } from 'modals/collection/asset/hooks/use-issue-selector'
import { CreateSftForm } from 'components/forms/create-sft-form'
import { actions } from '../slices/create-nft'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { actionTypes } from 'action-statuses/actions-types'
import { useCloseLayerLocation } from 'components/layered-router/hooks/use-close-layer-location'

export const SftForm = ({ collection }) => {
  const create = useCreate()
  const { loading, errors, data } = useIssueSelector()
  const callbackUrl = useCloseLayerLocation(`/collections/${collection.collection}`)
  const actionIdentifier = createActionIdentifier(collection, actionTypes.createAsset)

  useOnSentPayload({ data, callbackUrl, resetAction: actions.reset, actionIdentifier })

  return <CreateSftForm onSubmit={create} submitErrors={errors}
                        loading={loading}
                        callbackUrl={callbackUrl}
                        initialValues={{ collection: collection.collection }} />
}
