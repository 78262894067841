import { useField } from 'formik'
import 'components/forms/nerds-form/fields/style.scss'

export const CheckSwitchField = ({ name, label, disabled }) => {
  const [field, meta, { setTouched }] = useField(name)

  return <div className={'d-flex justify-content-between m-2 form-check form-switch p-0'}>
    <div>
      <label className={'form-check-label'} htmlFor={name}>{label}</label>
    </div>
    <div>
      <input className={'form-check-input clickable'}
             type={'checkbox'}
             id={name} name={name}
             disabled={disabled}
             onChange={field.onChange}
             onInput={() => setTouched()}
             value={field.value}
             checked={field.value}
             onBlur={field.onBlur} />
      {meta.touched && meta.error && <div className={'text-warning'}>{meta.error}</div>}
    </div>
  </div>
}
