import { combineReducers } from 'redux'

import { get } from 'explore-nfts/nfts/slices/get'
import { get as getCollections } from 'explore-nfts/collections/slices/get'
import { list as listCollections } from 'explore-nfts/collections/slices/list'

export const exploreNfts = combineReducers({
  nfts: combineReducers({ get: get.reducer }),
  collections: combineReducers({
    get: getCollections.reducer,
    list: listCollections.reducer,
  }),
})
