import { from, map } from 'rxjs'
import { logout } from 'network/logout'
import { catchError, mergeMap } from 'rxjs/operators'
import { services } from '@elrondnetwork/dapp-core-internal'
import { settings } from 'settings/fetching'
import { getAddress } from '@elrondnetwork/dapp-core/utils'

const makeUrl = () => `${settings().maiarId}/api/v1`

const tokenPayload = async() => ({
  address: await getAddress(),
  maiarIdApi: makeUrl(),
})

const graphPayload = (payload, accessToken) => ({
  ...payload,
  context: { headers: { authorization: `Bearer ${accessToken}` } },
})

const makePayload = payload => from(tokenPayload())
  .pipe(mergeMap(tokenPayload => from(services.maiarId.getAccessToken(tokenPayload))))
  .pipe(map(({ accessToken }) => graphPayload(payload, accessToken)))
  .pipe(catchError(e => logout()))

export const auth = { makePayload }
