import { denominate } from 'components/conversion'

const areEqual = (maxBid, topBid) => {
  const denominatedMaxBid = denominate(maxBid.amount)
  const denominatedTopBid = denominate(topBid.amount)

  return denominatedMaxBid === denominatedTopBid
}

export const isMaxBidReached = input => {
  const { maxBid, topBid } = input.auction

  return maxBid.amount !== '0' && topBid !== null ? areEqual(maxBid, topBid) : false
}
