import { Redirect } from 'react-router-dom'
import { useFirstExactMatch } from 'search/hooks/use-first-exact-match'

export const AssetRedirect = () => {
  const result = useFirstExactMatch()

  if (result?.kind === 'asset') {
    return <Redirect to={`/nfts/${result?.item.identifier}`} />
  }

  return ''
}
