import PropTypes from 'prop-types'
import { Link } from 'subdomain/components/my-asset-card/link'
import { get } from 'lodash'
import { useGetLink } from 'common/hooks/use-get-link'
import { Image } from 'subdomain/components/my-asset-card/image/index'
import { useMarketplaceContractIsPaused } from 'marketplaces/hooks/use-marketplace-contract-is-paused'
import { Price } from 'subdomain/components/my-asset-card/price'
import { Title } from 'subdomain/components/my-asset-card/title/index'
import { Badges } from 'subdomain/components/my-asset-card/badges/index'
import 'subdomain/components/my-asset-card/content.scss'

export const Content = props => {
  const identifier = get(props, 'asset.identifier')
  const link = useGetLink(props.auction, identifier)
  const isContractPaused = useMarketplaceContractIsPaused()

  return <div className={`my-asset-card card ${props.className}`}>
    <Link link={link} previewSource={props.previewSource} />
    <div className={'d-flex'}>
      <Image previewSource={props.previewSource} asset={props.asset} auction={props.auction} />
      <div className={'my-asset-card-infos'}>
        <div className={'d-flex flex-wrap w-100'}>
          <Title asset={props.asset} />
          {props.auction && !isContractPaused && <Price auction={props.auction} />}
        </div>
        <Badges {...props} />
      </div>
    </div>
  </div>
}

Content.defaultProps = {
  className: '',
  previewSource: 'explore',
  auction: {},
  asset: {},
  link: '',
  endDate: undefined,
  ghost: false,
  topBidder: false,
  showEndedBadge: false,
  outbid: false,
}

Content.propTypes = {
  auction: PropTypes.object,
  asset: PropTypes.object,
  className: PropTypes.string,
  previewSource: PropTypes.string,
  link: PropTypes.string,
  endDate: PropTypes.number,
  ghost: PropTypes.bool,
  topBidder: PropTypes.bool,
  showEndedBadge: PropTypes.bool,
  outbid: PropTypes.bool,
}
