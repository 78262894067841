import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useNextElement } from 'nft-page/controls/hooks/use-next-element'
import { useLayeredPath } from 'nft-page/controls/hooks/use-layered-path'

export const useOnRightClick = () => {
  const { replace } = useHistory()
  const nextElement = useNextElement()
  const layerPath = useLayeredPath()

  return useCallback(() => {
    if (nextElement) {
      replace(layerPath(nextElement))
    }
  }, [nextElement, replace, layerPath])
}
