import { Empty as EmptyState } from 'components/empty'
import { faRocket } from '@fortawesome/pro-light-svg-icons'
import { useAuctions } from 'home/trending/hooks/use-auctions'

const stateProps = {
  icon: faRocket,
  message: 'No auctions found.',
}

export const Empty = () => {
  const auctionCount = useAuctions().length

  return !auctionCount && <div className={'carousel-empty'}>
    <EmptyState {...stateProps} />
  </div>
}
