import { useResetState } from 'explore-nfts/nfts/behaviour/hooks/use-reset-state'
import { useGetInitialPage } from 'explore-nfts/nfts/behaviour/hooks/use-get-initial-page'
import { useForceResetState } from 'explore-nfts/nfts/behaviour/hooks/use-force-reset-state'
import { useTransactionWatch } from 'explore-nfts/nfts/behaviour/hooks/use-transaction-watch'

export const Behaviour = () => {
  useResetState()
  useForceResetState()
  useGetInitialPage()
  useTransactionWatch()

  return ''
}
