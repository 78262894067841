import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import 'components/forms/nerds-form/fields/price-field/balance/index.scss'

export const Balance = () => {
  const { account } = useGetAccountInfo()
  const balance = humanize(denominate(account.balance))

  return <div className={'d-flex justify-content-between'}>
    <small className={'price-field-balance w-100 text-end'}>{balance}</small>
  </div>
}
