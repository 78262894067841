import { useHistory } from 'react-router-dom'

export const CancelButton = ({ callbackUrl }) => {
  const { replace, goBack, location } = useHistory()

  const handleClick = () => {
    if (!location?.state?.hydrated) {
      goBack()
    } else {
      replace(callbackUrl)
    }
  }

  return <button className={'btn btn-lg btn-secondary w-100'} type={'button'} onClick={handleClick}>
    Close
  </button>
}
