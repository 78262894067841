import { shouldUseMinBid } from 'common/should-use-min-bid'
// import zoidpay from 'startup/assets/elrond-logo-yellow.svg'
import { Price } from 'nft-page/auction/price'
// import { get } from 'lodash'
import 'nft-page/auction/current-bid/index.scss'
// import elrondapes from 'startup/assets/elrond-logo-red.svg'
// import aquaverse from 'startup/assets/elrond-logo-green.svg'
// import holoride from 'startup/assets/elrond-logo-blue.svg'

// const priceIcon = {
//   elrondapes,
//   aquaverse,
//   zoidpay,
//   holoride,
//   '': holoride,
// }

export const CurrentBid = ({ auction }) => {
  const title = shouldUseMinBid(auction) ? 'Listing Price' : 'Highest Bid'
  // const marketplaceKey = get(auction, 'marketplaceKey', '')

  return <div className={'nft-current-bid'}>
    <p className={'nft-current-bid-title'}>{title}</p>
    <div className={'d-flex align-content-center'}>
      {/* <img className={'nft-current-bid-image'} src={priceIcon[marketplaceKey]} alt={'...'} /> */}
      <Price />
    </div>
  </div>
}
