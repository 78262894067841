import { useHistory } from 'react-router-dom'
import { useLayeredLocation } from './use-layered-location'
import { useCallback } from 'react'

export const useLayeredReplaceNavigate = () => {
  const { replace } = useHistory()
  const layeredLocation = useLayeredLocation()

  return useCallback((path, layerName, previewSource) => {
    replace(layeredLocation(path, layerName, previewSource))
  }, [replace, layeredLocation])
}
