import { useMemo } from 'react'
import { NotLoggedIn } from 'feed/not-logged-in'
import { useIsLoggedIn } from 'common/hooks/use-is-logged-in'
import { hooks } from '@elrondnetwork/dapp-core-internal'

export const useIsPrinceCharming = () => {
  const { isLoggedIn } = useIsLoggedIn()
  const isAuthorized = hooks.useGetHasAccessToken()

  return useMemo(() => (isAuthorized && isLoggedIn), [isAuthorized, isLoggedIn])
}

export const MegaAuthorized = ({ children }) => {
  const isPrinceCharming = useIsPrinceCharming()

  return isPrinceCharming ? children : <NotLoggedIn />
}
