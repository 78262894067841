import { ConnectButton } from 'sidebar/connect/connect-button'
import { useWalletLogin } from 'sidebar/connect/hooks/use-wallet-login'
import { useIsDarkTheme } from 'theme/hooks/use-is-dark-theme'

export const WalletLogin = () => {
  const walletLogin = useWalletLogin()
  const className = useIsDarkTheme() ? 'dark-mode' : ''

  return <ConnectButton LeftIcon={ConnectButton.Icon.Elrond} onClick={walletLogin} className={className}>
    MultiversX Web Wallet
  </ConnectButton>
}
