import { UsdPrice } from 'components/forms/nerds-form/fields/price-field/usd-price'
import { Currency } from 'components/forms/nerds-form/fields/price-field/currency'
import { useField } from 'formik'
import 'components/forms/nerds-form/fields/price-field/info/index.scss'

export const Info = ({ name }) => {
  const [field] = useField(name)

  return <div className={'price-field-info d-flex'}>
    <UsdPrice egld={field.value} />
    <Currency />
  </div>
}
