import { Carousel } from 'components/carousel'
import { useExploreNftsEdges } from 'home/explore-nfts/nfts/hooks/use-explore-nfts-edges'
import { LoadingCards } from 'components/loading-cards'
import { Empty } from 'home/explore-nfts/nfts/empty'
import { useIsLoading } from 'home/explore-nfts/nfts/hooks/use-is-loading'

export const Nfts = () => {
  const assets = useExploreNftsEdges()
  const isLoading = useIsLoading()
  const carouselProps = { items: assets, previewSource: 'home-explore-nfts' }

  return <div className={'explore-nfts'}>
    {isLoading ? <LoadingCards ghostCardNumber={10} /> : <Carousel {...carouselProps} />}
    <Empty />
  </div>
}
