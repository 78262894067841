import { Filters } from 'explore-nfts/filters'
import { List } from 'explore-nfts/nfts/content/list'
import { useNext } from 'explore-nfts/nfts/hooks/use-next'
import { InfiniteScroll } from 'components/infinite-scroll/infinite-scroll'
import { useExploreNftsList } from 'explore-nfts/nfts/hooks/use-explore-nfts-list'
import { DataCard } from 'components/data-card'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { Empty } from 'explore-nfts/nfts/empty'
import { useIsLoading } from 'explore-nfts/nfts/hooks/use-is-loading'
import { useHasMore } from 'explore-nfts/nfts/hooks/use-has-more'
import { Behaviour } from 'explore-nfts/nfts/behaviour'

const cardProps = () => ({ title: 'Explore NFTs' })

export const Content = () => {
  const nfts = useExploreNftsList()
  const isLoading = useIsLoading()

  return <div className={'explore-nfts'}>
    <Behaviour />
    <ScrollToTopOnMount />
    <DataCard {...cardProps()}>
      <Filters />
      <InfiniteScroll next={useNext()} hasMore={useHasMore()} isLoading={isLoading}>
        <List assets={nfts} />
      </InfiniteScroll>
      <Empty />
    </DataCard>
  </div>
}
