/* eslint-disable max-lines-per-function */
import { useHexToHsl } from 'common/hooks/use-hex-to-hsl'
import { useHexToRgb } from 'common/hooks/use-hex-to-rgb'
import { get } from 'lodash'
import { useEffect } from 'react'
import { useCmsData } from 'subdomain/cms-content/hooks/use-cms-data'

export const useCmsFeatureColor = () => {
  const data = useCmsData()
  let featureColor = get(data, 'data.data.attributes.featureColor')

  featureColor = featureColor.match(/^#([0-9a-f]{3}){1,2}$/i) ? featureColor : '#4075ff'

  const theme = get(data, 'data.data.attributes.theme')

  const isDark = theme === 'dark'

  const { h, s, l } = useHexToHsl(featureColor)
  const { r, g, b } = useHexToRgb(featureColor)

  useEffect(() => {
    document.body.style.setProperty('--bs-primary', featureColor)
    document.body.style.setProperty('--bs-primary-rgb', `${r}, ${g}, ${b}`)

    if (!isDark) {
      document.body.style.setProperty('--bs-secondary-btn', featureColor)
      document.body.style.setProperty('--bs-secondary-btn-rgb', `${r}, ${g}, ${b}`)
    } else {
      document.body.style.removeProperty('--bs-secondary-btn')
      document.body.style.removeProperty('--bs-secondary-btn-rgb')
    }

    const primaryHover = `hsl(${h},${s}%, calc(${l}% + 10%))`
    document.body.style.setProperty('--bs-primary-hover', primaryHover)

    const primaryHover2 = `hsl(${h},${s}%, calc(${l}% + 17%))`
    document.body.style.setProperty('--bs-primary-hover-2', primaryHover2)
  }, [b, featureColor, g, h, isDark, l, r, s])
}
