import { Empty as EmptyState } from 'components/empty'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { useActualList } from 'collections/auctions/hooks/use-actual-list'

const makeProps = () => ({
  icon: faCubes,
  message: 'No auctions found.',
  fullscreen: true,
})

export const Empty = () => {
  const auctionsCount = useActualList().length

  return !auctionsCount && <EmptyState {...makeProps()} />
}
