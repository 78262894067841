import { Column } from 'components/column'
import { Behaviour } from 'filters/auctions/behaviour'
import { Type } from 'filters/auctions/type'
import { SortBy } from 'filters/auctions/sort-by'
import { PriceRange } from 'filters/collection-filters/price-range'

export const defaultTypeOptions = [
  { value: 'All', name: '' },
  { value: 'Single', name: 'nft' },
  { value: 'Bundles', name: 'sftAll' },
  { value: 'Variable', name: 'sftOnePerPayment' },
]

export const CollectionFilters = ({ typeOptions = defaultTypeOptions }) => <Column>
  <Behaviour />
  <div className={'d-flex flex-column-reverse flex-lg-row align-items-center mb-3 align-items-center'}>
    <div className={'d-flex mb-4 flex-grow-1 market-filters-price-range-container'}>
      <PriceRange />
    </div>
    <div className={'d-flex flex-grow-1 mb-4 justify-content-between justify-content-lg-end market-filters-sort-by'}>
      <Type options={typeOptions} />
      <SortBy className={'ms-3'} />
    </div>
  </div>
</Column>
