import { useHerotag } from 'nft-page/artist-account/hooks/use-herotag'
import { useNftCreatorSelector } from 'nft-page/hooks/nft/use-nft-creator-selector'
import defaultAvatar from 'common/default-avatar'
import { get } from 'lodash'
import { UserInfo } from 'components/user-info/index'
import 'nft-page/artist-account/artist/index.scss'
import { Title } from 'nft-page/artist-account/title'

export const Artist = () => {
  const creator = useNftCreatorSelector()
  const address = get(creator, 'address', '')
  const image = get(creator, 'profile', defaultAvatar) || defaultAvatar

  return <div className={'artist-account-artist mb-4'}>
    <Title>Creator:</Title>
    <UserInfo image={image} herotag={useHerotag()} address={address} color={'primary'} />
  </div>
}
