import { useAuthorizedAction } from 'common/hooks/use-authorized-action'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useAccountNavigate = path => {
  const { push } = useHistory()
  const onNavigate = useCallback(() => push(path), [path, push])

  return useAuthorizedAction(onNavigate)
}
