import { STEP } from 'sidebar/connect/constants'
import 'sidebar/connect/maiar-login/qr-code.scss'
import { useContext } from 'sidebar/connect/context'
import { ConnectButton } from 'sidebar/connect/connect-button'
import { useSetStep } from 'sidebar/connect/hooks/use-set-step'
import { useLoginToken } from 'auth/hooks/use-login-token'
import { Code } from 'sidebar/connect/maiar-login/code'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'

export const QrCode = () => {
  const logoutRoute = '/logout'
  const token = useLoginToken()
  const callbackRoute = useCallbackRoute()

  const { step } = useContext()
  const handleBack = useSetStep(STEP.loginButtons)

  return step === STEP.maiarQrCode && <div className={'maiar-qr-code'}>
    { token && <Code token={token} callbackRoute={callbackRoute} logoutRoute={logoutRoute} />}
    <ConnectButton LeftIcon={ConnectButton.Icon.ArrowLeft} RightIcon={ConnectButton.Icon.None} onClick={handleBack}>
      Back
    </ConnectButton>
  </div>
}
