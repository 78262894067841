import { Failure } from 'failure'
import { Seo } from 'components/seo'
import { TabHeader } from 'account/tabs/tab-header'
import { Content } from 'account/tabs/assets/auctions/content'
import { VisibleIfActive } from 'account/common/visible-if-active'
import { useSeoProps } from 'account/hooks/use-seo-props'
import { ContractWrapper } from 'components/contract-wrapper'

export const Auctions = () => <VisibleIfActive tab={'auctions'}>
  <Seo {...useSeoProps('auctions')} />

  <Failure.Partial id={'account.auctions'}>
    <TabHeader />
    <ContractWrapper fullscreen>
      <Content />
    </ContractWrapper>
  </Failure.Partial>
</VisibleIfActive>
