import { denominate } from 'components/conversion'
import { useEconomicsSelector } from 'economics/hooks/use-economics-selector'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'
import { useSidebarStats } from 'sidebar/stats/hooks/use-sidebar-stats'
import { humanize } from 'components/conversion/humanize'

export const useBiddingBalance = () => {
  const decimals = useDenominationDecimals()
  const { price } = useEconomicsSelector()
  const { biddingBalance } = useSidebarStats()
  const balance = biddingBalance && parseFloat(biddingBalance) !== 0 ? denominate(biddingBalance) : '0'
  const totalUsd = parseFloat(`${price * balance}`).toFixed(decimals)

  return {
    egld: humanize(balance),
    usd: humanize(totalUsd),
  }
}
