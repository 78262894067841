import { useCallback } from 'react'
import { actions } from 'nft-page/tracking/slices/view'
import { useDispatch } from 'react-redux'
import { useNftCreatorAddressSelector } from 'nft-page/hooks/nft/use-nft-creator-address-selector'
import { useNftCollectionSelector } from 'nft-page/hooks/nft/use-nft-collection-selector'
import { useNftIdentifier } from 'nft-page/hooks/nft/use-nft-identifier'

export const useMakePayload = () => {
  const dispatch = useDispatch()
  const identifier = useNftIdentifier()
  const collection = useNftCollectionSelector()
  const artist = useNftCreatorAddressSelector()

  return useCallback(() => {
    if (collection && artist) {
      return dispatch(actions.attempt({ identifier, collection, artist }))
    }
  }, [artist, collection, dispatch, identifier])
}
