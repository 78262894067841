import { useSelectedTag } from 'explore-nfts/hooks/use-selected-tag'
import { useMemo } from 'react'
import { settings } from 'settings/fetching'
import { useLastCursor } from 'explore-nfts/nfts/hooks/use-last-cursor'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const cursor = useCursor(customCursor)
  const selectedTag = useSelectedTag()

  return useMemo(() => {
    const tags = selectedTag ? [selectedTag] : undefined

    return ({
      pagination: { first: settings().listRequestSize, after: cursor },
      filters: { tags },
    })
  }, [cursor, selectedTag])
}
