import { graph } from 'network'
import { ASSETS, ASSET_COUNT, TAGS, EXPLORE_ASSETS } from 'repositories/assets/queries'
import { ACCOUNT_CREATIONS } from 'repositories/assets/queries/account-creations'
import { ACCOUNT_COLLECTED } from 'repositories/assets/queries/account-collected'
import { ACCOUNT_COLLECTION_ASSETS } from 'repositories/assets/queries/account-collection-assets'
import { HOME_EXPLORE_ASSETS } from 'repositories/assets/queries/home-explore-assets'
import { ASSET } from 'repositories/assets/queries/asset'
import { ASSET_WITH_OWNER } from 'repositories/assets/queries/asset-with-owner'

export const assets = {
  getAsset: payload => graph().query({ query: ASSET, variables: payload }),
  getAssetWithOwner: payload => graph().query({ query: ASSET_WITH_OWNER, variables: payload }),
  get: payload => graph().query({ query: ASSETS, variables: payload }),
  getAccountCreations: payload => graph().query({ query: ACCOUNT_CREATIONS, variables: payload }),
  getAccountCollected: payload => graph().query({ query: ACCOUNT_COLLECTED, variables: payload }),
  getAccountCollectionAssets: payload => graph().query({ query: ACCOUNT_COLLECTION_ASSETS, variables: payload }),
  getExploreAssets: payload => graph().query({ query: EXPLORE_ASSETS, variables: payload }),
  getHomeExploreAssets: payload => graph().query({ query: HOME_EXPLORE_ASSETS, variables: payload }),
  count: payload => graph().query({ query: ASSET_COUNT, variables: payload }),
  tags: payload => graph().query({ query: TAGS, variables: payload }),
}
