import { useFollowAccount, useUnfollowAccount } from 'feed/initial/hooks'
import { InitialSuggestionCard } from 'components/initial-suggestion-card'
import { useState } from 'react'

export const Item = ({ account }) => {
  const [followed, setFollowed] = useState(false)
  const follow = useFollowAccount(setFollowed, account)
  const unfollow = useUnfollowAccount(setFollowed, account)

  return <InitialSuggestionCard {...account} isFollowed={followed} onFollow={follow} onUnfollow={unfollow} />
}
