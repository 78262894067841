import { useField } from 'formik'
import { denominate } from 'components/conversion'
import { get } from 'lodash'
import { BN } from 'bn.js'
import { useDenominationDecimals } from 'settings/hooks/use-denomination-decimals'

const calculate = (minBid, quantity) => minBid.mul(new BN(`${Math.floor(quantity)}`))
  .toString()

export const useTotalEgldValue = auction => {
  const [{ value: quantity }, { error }] = useField('quantity')
  const decimals = useDenominationDecimals()
  const minBidAmount = new BN(get(auction, 'minBid.amount', '0'))

  return error ? 0 : parseFloat(denominate(calculate(minBidAmount, quantity))).toFixed(decimals)
}
