import PropTypes from 'prop-types'

import { useValidateItems } from 'components/carousel/hooks/use-validate-items'
import { usePickContent } from 'components/carousel/hooks/use-pick-content'

export const Carousel = ({ items, previewSource }) => {
  const status = useValidateItems(items)
  const Content = usePickContent(status)

  return <Content status={status} items={items} previewSource={previewSource} />
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  previewSource: PropTypes.string.isRequired,
}
