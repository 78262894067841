import { get, split } from 'lodash'
import { useRouteMatch } from 'react-router-dom'
import { routeNames } from 'startup/routes'

export const useNftCollectionIdentifier = () => {
  const nftPageRoute = useRouteMatch(routeNames.nftPage)
  const identifier = get(nftPageRoute, 'params.identifier', '')

  return split(identifier, '-', 2).join('-')
}

