import { useIsSelected } from 'explore-nfts/filters/hooks/use-is-selected'
import { useOnTagClick } from 'explore-nfts/filters/hooks/use-on-tag-click'
import { useIsLoading } from 'explore-nfts/nfts/hooks/use-is-loading'
import { TagButton } from 'components/tag-button'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { useAssetTags } from 'tags/hooks/use-asset-tags'

export const TagsSelector = () => {
  const isSelected = useIsSelected()
  const onClick = useOnTagClick()
  const isLoading = useIsLoading()
  const tags = useAssetTags()

  return <Swiper freeMode slidesPerView={'auto'}>
    {tags.map((tag, index) => <SwiperSlide key={index}>
      <TagButton {...tag} selected={isSelected(tag)} disabled={isLoading}
                 onClick={() => onClick(tag)} />
    </SwiperSlide>)}
  </Swiper>
}
