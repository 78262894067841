import './multi-range.scss'
import { MultiRangeContext, useValue } from './context'
import { ContentLarge } from './content-large'
import { ContentSmall } from './content-small'

export const MultiRange = ({ max, min, initialMax, initialMin, step, onChange = () => {} }) => {
  const value = useValue({ onChange, initialMin, initialMax })

  return <div className={'multi-range px-1 align-items-center flex-grow-1'}>
    <MultiRangeContext.Provider value={value}>
      <ContentLarge initialMax={initialMax} min={min} max={max} initialMin={initialMin} step={step} />
      <ContentSmall step={step} initialMin={initialMin} min={min} max={max} initialMax={initialMax} />
    </MultiRangeContext.Provider>

  </div>
}
