import { useCallback } from 'react'
import { useRouteFilter } from '../../../../common/hooks/use-route-filter'

export const useOnChange = () => {
  const changeRoute = useRouteFilter()

  return useCallback(event => {
    const shouldReset = !event.target.checked
    changeRoute({ name: 'on-sale', value: event.target.checked }, shouldReset)
  }, [changeRoute])
}
