import { useEffect } from 'react'
import { useContext } from 'components/rolling-collection/context'

export const usePreloadImages = thumbs => {
  const { setLoadedThumbs, loadingIndex, setLoadingIndex } = useContext()
  const jsonThumbs = JSON.stringify(thumbs)

  const onLoaded = () => {
    setLoadedThumbs(items => [...items, thumbs[loadingIndex]])

    const nextImage = Math.min(loadingIndex + 1, thumbs.length - 1)

    setLoadingIndex(nextImage)
  }

  useEffect(() => {
    const thumb = thumbs[loadingIndex]
    const image = new window.Image()

    if (thumb) {
      image.src = thumbs[loadingIndex]
      image.addEventListener('load', onLoaded)
    }

    return () => image.removeEventListener('load', onLoaded)
    // eslint-disable-next-line
  }, [jsonThumbs, setLoadedThumbs, loadingIndex])
}
