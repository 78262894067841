import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './report-web-vitals'
import App from './app'

import 'bootstrap'

ReactDOM.render(<App />, document.getElementById('root'))

reportWebVitals()
