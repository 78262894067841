import { Details } from 'nft-page/auction/details'
import { useOnClick } from 'auction/actions/buy-sft/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from '../watchable-action'
import { get } from 'lodash'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { formatToken } from 'common/format-token'

export const BuySft = params => {
  const { auction, address } = params
  const buySftClick = useOnClick(auction)
  const onClick = usePermittedAction(buySftClick, address)
  const maxBidAmount = get(auction, 'maxBid.amount')
  const maxBidToken = get(auction, 'maxBid.token')
  const price = `${(humanize(denominate(maxBidAmount)) * 1)} ${formatToken(maxBidToken)}`

  return <div className={'col nft-page-auction-inner'}>
    <Details auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.buyNowSft} text={'Buy Now'} onClick={onClick}
                     price={price} />
  </div>
}

BuySft.displayName = 'BuySft'
