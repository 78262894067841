import * as Yup from 'yup'

import { useTests } from 'components/forms/place-bid-form/hooks/use-tests'

export const useSchema = auction => Yup.string()
  .required('Price is a required field')
  .test('comma', 'Use dot for decimals', useTests().testComma)
  .test('number', 'Must be a number', useTests().testNumber)
  .test('decimals', 'Too many decimals', useTests().testDecimals)
  .test('startDot', 'Must not start with dot', useTests().testStartDot)
  .test('endDot', 'Must not end with dot', useTests().testEndDot)
  .test('leadingZeros', 'Leading zero is not allowed', useTests().testLeadingZeros)
  .test('minimum', useTests(auction).testMinimum)
  .test('maximum', useTests(auction).testMaximum)
  .test('balance', useTests(auction).testBalance)
  .default('')
