import { get } from 'lodash'
import { AssetCard } from 'components/asset-card'

export const CardItem = ({ item }) => {
  const asset = get(item, 'node.asset', {})
  const auction = get(item, 'node', {})
  const isGhost = get(item, 'isGhost')

  return <AssetCard asset={asset} auction={auction} previewSource={'account-auctions'} isGhost={isGhost}
                    className={'small-on-mobile'} showEndedBadge />
}
