import { useResetCollected } from 'account/tabs/assets/collected/behaviour/hooks/use-reset-collected'
import { useResetList } from 'account/tabs/assets/collected/hooks/use-reset-list'
import { useEffect } from 'react'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const useResetState = () => {
  const resetCollected = useResetCollected()
  const resetList = useResetList()
  const address = useViewerAddress()

  useEffect(() => {
    resetList()
    resetCollected()
  }, [resetCollected, resetList, address])
}
