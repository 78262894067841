import { Column } from 'components/column'
import { AssetCard } from 'components/asset-card'
import { get } from 'lodash'

export const Item = ({ item }) => {
  const asset = get(item, 'node', {})
  const auction = get(item, 'node.lowestAuction', {})
  const isGhost = get(item, 'isGhost')

  return <Column className={'col-6 col-md-4 col-xl-3 card-item'}>
    <AssetCard previewSource={'collection-assets'} asset={asset} auction={auction} className={'small-on-mobile'}
               isGhost={isGhost} showEndedBadge />
  </Column>
}
