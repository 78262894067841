import './tag-button.scss'
import PropTypes from 'prop-types'

const makeLabel = label => label !== 'All' ? `#${label.toLowerCase()}` : label

export const TagButton = ({ image, label, onClick, selected, disabled }) => <div>
  <button type={'button'}
          className={`btn tag-button me-2 ${selected && 'active'}`}
          onClick={() => onClick(label)} disabled={disabled}>
    {image && <img className={'tag-button-image'} src={image} alt={'...'} />}
    <span className={'tag-button-text'}>{makeLabel(label)}</span>
  </button>
</div>

TagButton.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

TagButton.defaultProps = { onClick: () => {}, image: '' }
