import { get } from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'explore-nfts/collections/slices/get'
import { useExploreCollectionsSelector } from 'explore-nfts/collections/hooks/use-explore-collections-selector'

export const useNext = () => {
  const dispatch = useDispatch()
  const endCursor = get(useExploreCollectionsSelector(), 'data.collections.pageInfo.endCursor', '')

  return useCallback(() => {
    dispatch(actions.setCursor(endCursor))
  }, [dispatch, endCursor])
}
