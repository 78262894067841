const className = 'd-flex align-items-center justify-content-center p-4 mx-lg-4'

export const Wrapper = ({ children }) => <div className={className}>
  <div>
    <div>
      <div>
        {children}
      </div>
    </div>
  </div>
</div>
