import { useRef } from 'react'
import PropTypes from 'prop-types'
import { LoadTrigger } from 'components/infinite-scroll/load-trigger'
import { useCanLoadMore } from 'components/infinite-scroll/hooks/use-can-load-more'
import { useIntersectionObserver } from 'components/infinite-scroll/hooks/use-intersection-observer'
import { useToggleFooterVisibility } from 'components/infinite-scroll/hooks/use-toggle-footer-visibility'
import { settings } from 'settings/fetching'

export const InfiniteScroll = props => {
  const loader = useRef(null)
  const scrollableArea = useRef(null)
  const canLoadMore = useCanLoadMore({ hasMore: props.hasMore, isLoading: props.isLoading })

  useIntersectionObserver({
    loader,
    scrollableArea: props.rootScrollable ? null : scrollableArea,
    hasMore: props.hasMore,
    next: props.next,
    scrollThreshold: settings().scrollThreshold,
    isLoading: props.isLoading,
  })

  useToggleFooterVisibility(props)

  return <div ref={scrollableArea} className={props.className}>
    {props.children}
    {canLoadMore && <LoadTrigger loader={loader} />}
  </div>
}

InfiniteScroll.defaultProps = {
  rootScrollable: true,
  hasMore: false,
  isLoading: false,
  className: '',
}

InfiniteScroll.propTypes = {
  next: PropTypes.func.isRequired,
  rootScrollable: PropTypes.bool,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
}
