import { graph } from 'network'
import { COLLECTIONS, COUNT, STATS } from 'repositories/nft-collections/queries'
import { ISSUE_NFT_COLLECTION } from 'repositories/nft-collections/mutations/issue-nft-collection'
import { SET_COLLECTION_ROLES } from 'repositories/nft-collections/mutations/set-collection-roles'
import { FEATURED } from 'repositories/nft-collections/queries/featured'
import { TRENDING } from 'repositories/nft-collections/queries/trending'

export const nftCollections = {
  setRoles: input => graph().mutate({ mutation: SET_COLLECTION_ROLES, variables: { input } }),
  get: input => graph().query({ query: COLLECTIONS, variables: input }),
  stats: input => graph().query({ query: STATS, variables: input }),
  issueNftCollection: payload => graph().mutate({ mutation: ISSUE_NFT_COLLECTION, variables: { input: payload } }),
  count: variables => graph().query({ query: COUNT, variables }),
  featured: () => graph().query({ query: FEATURED }),
  trending: variables => graph().query({ query: TRENDING, variables }),
}
