import PropTypes from 'prop-types'
import 'components/collection-card/content.scss'
import { Link } from 'components/collection-card/link'
import { Header } from 'components/collection-card/header'
import { Details } from 'components/collection-card/details'
import { VerifiedBadge } from 'components/verified-badge'
import { get } from 'lodash'

export const Component = props => {
  const isSft = get(props.collection, 'type', '') === 'SemiFungibleESDT'
  const sftClass = isSft ? 'is-sft' : ''

  return <div className={`collection-card card  ${sftClass} ${props.className}`}>
    <Link collection={props.collection} openNewLayer={props.openNewLayer} />
    <Header collection={props.collection} />
    {props.collection.verified && <VerifiedBadge />}
    <Details collection={props.collection} />
  </div>
}

Component.defaultProps = { className: '', collection: {} }

Component.propTypes = {
  collection: PropTypes.object,
  className: PropTypes.string,
}
