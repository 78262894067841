import { ReactComponent as CreateIcon } from 'startup/assets/gradient-icons-icon.svg'
import { OnboardingModal } from 'components/onboarding-modal'
import { useOnClick } from 'account/create-collection/hooks/use-on-click'

export const Create = () => <OnboardingModal id={'onboarding-create'} buttonText={'Create New Collection'}
                                             callback={useOnClick()} showCancel>
  <CreateIcon className={'onboarding-modal-icon'} />
  <h4 className={'onboarding-modal-title'}>NFTs are amazing!</h4>
  <div className={'onboarding-modal-content'}>
    <p>Ever thought about creating one yourself? It’s very easy!</p>
    <p>
      NFTs are part of
      {' '}
      <strong>Collections</strong>
      . First, you need to create a Collection.
    </p>
    <p>You can then add thousands of NFTs to it. Or, create another Collection.</p>
  </div>
</OnboardingModal>
