import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { State } from 'sidebar/connect/ledger-login/ledger-container/state'

const props = {
  icon: faCircleNotch,
  title: 'Waiting for device',
  iconClassName: 'fa-spin text-primary',
}

export const Content = () => <State {...props} />
