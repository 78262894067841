import { useBadgeVisibility } from 'subdomain/components/my-asset-card/badges/hooks/use-badge-visibility'
import { Badge, BadgeType } from 'subdomain/components/my-asset-card/badge'
import { TimeLeftBadge } from 'subdomain/components/my-asset-card/badges/time-left-badge'
import 'subdomain/components/my-asset-card/badges/index.scss'

export const Badges = props => {
  const { timeLeft, outBidded, auctionEnded, topBidder } = useBadgeVisibility(props)

  return <div className={'my-asset-card-badges'}>
    <TimeLeftBadge auctionEnded={auctionEnded} auction={props.auction} timeLeft={timeLeft} />
    <Badge label={'Outbidded'} type={BadgeType.Outbidded} condition={outBidded} />
    <Badge label={'Ended'} type={BadgeType.Ended} condition={auctionEnded} />
    <Badge label={'TopBidder'} type={BadgeType.TopBidder} condition={topBidder} />
  </div>
}
