import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import moment from 'moment'
import { nominate } from 'components/conversion'
import { actions } from 'modals/auction/start/slices/create-auction'

export const useCreateVariableAuction = asset => {
  const dispatch = useDispatch()

  return useCallback(payload => {
    const deadline = moment(payload.deadline).unix()
      .toString()

    const { minBid, quantity } = payload

    const variables = {
      identifier: asset.identifier,
      quantity: `${quantity}`,
      minBid: nominate(minBid),
      maxBid: nominate(minBid),
      deadline,
      paymentToken: 'EGLD',
      maxOneSftPerPayment: true,
    }

    return dispatch(actions.attempt(variables))
  }, [asset.identifier, dispatch])
}
