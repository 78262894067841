import { useCallback } from 'react'

import { useContext } from 'home/explore-nfts/context'

export const useOnTagClick = () => {
  const { setSelected, setShouldReset, selected } = useContext()

  return useCallback(({ value: tag }) => {
    selected !== tag && setShouldReset(true)
    setSelected(tag)
  }, [selected, setShouldReset, setSelected])
}
