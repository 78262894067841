import { SliderLabel } from './slider-label'
import { Sliders } from './sliders'
import { SliderValues } from './slider-values'

const className = 'd-none d-md-flex align-items-center'

export const ContentLarge = ({ max, min, initialMax, initialMin, step }) => <div className={className}>
  <SliderLabel />
  <div className={'mx-2 flex-grow-1 flex-lg-grow-0'}>
    <Sliders max={max} min={min} initialMin={initialMin} initialMax={initialMax} step={step} />
  </div>
  <SliderValues />
</div>
