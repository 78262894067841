import { useLoginToken } from 'auth/hooks/use-login-token'
import * as loginServices from '@elrondnetwork/dapp-core/hooks/login'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'

export const useWalletLogin = () => {
  const loginToken = useLoginToken()
  const callbackRoute = useCallbackRoute()
  const [onInitiateLogin] = loginServices.useWebWalletLogin({
    callbackRoute,
    token: loginToken,
    redirectDelayMilliseconds: 100,
  })

  return onInitiateLogin
}
