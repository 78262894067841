import { useGetLoginInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useCallbackRoute } from 'sidebar/connect/hooks/use-callback-route'
import { useHistory } from 'react-router-dom'

/* eslint-disable */

export const useRedirectableLogin = (callback, dependencies) => {
  const { isLoggedIn } = useGetLoginInfo()
  const [done, setDone] = useState(false)
  const callbackRoute = useCallbackRoute()
  const { push } = useHistory()

  useEffect(() => {
    if (done && isLoggedIn) {
      setDone(false)
      push(callbackRoute)
    }
  }, [callbackRoute, done, isLoggedIn, push])

  return useCallback(() => {
    callback()
    setDone(true)
  }, [callback, setDone, ...dependencies])
}

/* eslint-enable */
