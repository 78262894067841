import { useBalance } from 'sidebar/my-wallet/balance/hooks/use-balance'
import { BalanceItem } from 'sidebar/my-wallet/balance-item'
import { useSidebarStats } from 'sidebar/stats/hooks/use-sidebar-stats'

const useGetTitle = () => {
  const { biddingBalance } = useSidebarStats()
  const hasBiddingBalance = biddingBalance && parseFloat(biddingBalance) !== 0

  return hasBiddingBalance ? { title: 'Balance' } : { title: '' }
}

export const EgldBalance = () => {
  const balance = useBalance()
  const { title } = useGetTitle()

  return <BalanceItem title={title} egldAmount={balance.egld} usdAmount={balance.usd} />
}
