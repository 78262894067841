import { SliderLabel } from './slider-label'
import { SliderValues } from './slider-values'
import { Sliders } from './sliders'

const className = 'd-flex flex-column flex-md-row align-items-md-center justify-content-between d-md-none'

export const ContentSmall = ({ max, min, initialMax, initialMin, step }) => <div className={className}>
  <div className={'d-flex justify-content-between align-items-center mb-2'}>
    <SliderLabel />
    <SliderValues max={initialMax} min={initialMin} />
  </div>
  <div className={'my-2'}>
    <Sliders max={max} min={min} step={step} />
  </div>
</div>
