import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { actions as dataActions } from 'explore-nfts/collections/slices/get'
import { actions as listActions } from 'explore-nfts/collections/slices/list'

export const useForceResetState = () => {
  const dispatch = useDispatch()
  const { location: { state, search } } = useHistory()
  const shouldReset = get(state, 'forceReset', false)

  useEffect(() => {
    if (shouldReset) {
      dispatch(dataActions.setCursor(''))
      dispatch(dataActions.reset({}))
      dispatch(listActions.reset({}))
    }
  }, [dispatch, shouldReset, search])
}
