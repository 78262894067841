import { Details } from 'nft-page/auction/details'
import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from '../watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { humanize } from 'components/conversion/humanize'
import { denominate } from 'components/conversion'
import { formatToken } from 'common/format-token'

export const PlaceBid = ({ auction, address }) => {
  const placeBidClick = useOnClick(auction)
  const onClick = usePermittedAction(placeBidClick, address)
  const minBidToken = get(auction, 'minBid.token')
  const amount = useMinimum(auction)
  const price = `${(humanize(denominate(amount)) * 1)} ${formatToken(minBidToken)}`

  return <div className={'nft-page-auction-inner'}>
    <Details auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Bid'} onClick={onClick} price={price} />
  </div>
}

PlaceBid.displayName = 'PlaceBid'
