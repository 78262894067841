import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'explore-nfts/collections/slices/list'
import { useExploreCollectionsSelector } from 'explore-nfts/collections/hooks/use-explore-collections-selector'

export const useAppendData = () => {
  const dispatch = useDispatch()
  const { data } = useExploreCollectionsSelector()

  useEffect(() => {
    dispatch(actions.append(data.collections.edges))
  }, [dispatch, data.collections.edges])
}
