import { settings } from 'settings/fetching'
import { humanize } from 'components/conversion/humanize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import 'components/forms/nerds-form/fields/final-fee-field/header/index.scss'

const decimals = () => settings().dapp.decimals

const egldLabel = () => settings().dapp.egldLabel

export const Header = ({ assetFees, exampleValue }) => {
  const totalEgld = assetFees.toFixed(decimals())

  return <div>
    <button className={'accordion-button final-fee-header collapsed'} type={'button'} data-bs-toggle={'collapse'}
            data-bs-target={'#finalFees'}>
      <div className={'d-flex flex-row flex-grow-1 align-items-center'}>
        {exampleValue && <span className={'text-link-color flex-grow-1'}>
          {`${humanize(totalEgld)} ${egldLabel()}`}
        </span>}
        <span className={'final-fee-header-label me-2 ms-auto text-content-color'}>Breakdown</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
    </button>
  </div>
}
