import { useIsExistingTab } from 'account/tabs/hooks/use-is-existing-tab'
import { Redirect } from 'react-router-dom'
import { useViewerAddress } from 'viewer/hooks/use-viewer-address'

export const RenderWrapper = ({ children }) => {
  const isExisingTab = useIsExistingTab()
  const address = useViewerAddress()

  return isExisingTab ? children : <Redirect to={`/${address}`} />
}
