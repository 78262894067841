import { useMemo } from 'react'
import { useLastCursor } from 'collections/auctions/hooks/use-last-cursor'
import { settings } from 'settings/fetching'
import { useParams } from 'react-router-dom'
import { useFilters } from '../../../filters/hooks/use-filters'
import { compact } from 'lodash'
import { customFilters } from '../../../filters/auctions/filters-logic/custom-filters'
import { sorting } from '../../../filters/auctions/filters-logic/sorting'
import { filters } from '../../../filters/auctions/filters-logic/filters'
import { useCmsMarketplaceKey } from '../../../subdomain/cms-content/hooks/use-cms-marketplace-key'
import { usePriceRange } from 'collections/auctions/hooks/use-price-range'
import { marketPlaceFilter, priceFilters } from '../../../filters/auctions/filters-logic'

/* eslint-disable */
const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useFetchPayload = customCursor => {
  const { identifier: collection } = useParams()
  const cursor = useCursor(customCursor)
  const filters = useFilters()
  const hash = JSON.stringify(filters.search)
  const marketplaceKey = useCmsMarketplaceKey()
  const priceRange = usePriceRange()
  const priceRangeHash = JSON.stringify(priceRange)
  return useMemo(() => {
    const parsed = JSON.parse(hash)
    const parsedPriceRang = JSON.parse(priceRangeHash)
    const defPriceRange = { minVal: parsedPriceRang.minBid?.amount, maxVal: parsedPriceRang.maxBid?.amount }

    return buildFilters({ ...parsed, cursor }, collection, marketplaceKey, defPriceRange)
  }, [collection, cursor, hash, marketplaceKey, priceRangeHash])
}


export const buildFilters = ({ minVal, maxVal, cursor, sortBy, byType, tag }, collection, marketplaceKey, defaultPriceRange) => ({
  customFilters: customFilters({ minVal, maxVal, sortBy }, defaultPriceRange),
  pagination: { first: settings().listRequestSize, after: cursor },
  sorting: compact([...sorting(sortBy)]),
  grouping: { groupBy: 'IDENTIFIER' },
  filters: {
    operator: 'AND',
    filters: [
      ...filters({ byType, tag }),
      { field: 'collection', values: [`${collection}`], op: 'EQ' },
      ...marketPlaceFilter(marketplaceKey),
      ...priceFilters({ minVal, maxVal, sortBy,})
    ],
  },
})
/* eslint-enable */
