import { Layout } from 'account/tabs/tab-header/tab-menu-item/layout'
import { Counter } from 'account/tabs/tab-header/tab-menu-item/counter'
import { uppercaseFirstLetter } from 'common/uppercase-first-letter'
import { useShouldDisplay } from 'account/tabs/tab-header/tab-menu-item/hooks/use-should-display'

export const TabMenuItem = ({ tab }) => {
  const shouldDisplay = useShouldDisplay(tab)

  return shouldDisplay && <Layout tab={tab}>
    <Counter tab={tab} selector={tab.selector} />
    {uppercaseFirstLetter(tab.label)}
  </Layout>
}

