import { matchPath, useHistory } from 'react-router-dom'
import { get, values } from 'lodash'
import { routes } from 'startup/routes'
import { useRouteConfig } from 'components/layered-router/hooks/use-route-config'

const getActiveLayers = ({ state }) => {
  const links = get(state, 'layerLink', {})

  return values(links)
}

const getRouteConfig = pathname => routes.find(route => matchPath(pathname, route.path))

export const useRouteAllowsFooter = () => {
  const { location } = useHistory()
  const routeConfig = useRouteConfig()

  if (!location.state?.layerLink) {
    return get(routeConfig, 'footer', true)
  }

  return getActiveLayers(location)
    .map(getRouteConfig)
    .some(props => props?.footer)
}
