import { get } from 'lodash'
import { useSelectedTag } from 'explore-nfts/hooks/use-selected-tag'
import { useAssetTags } from 'tags/hooks/use-asset-tags'

const useGetTagDisplay = tag => {
  const tags = useAssetTags()
  const registeredTag = tags.find(item => item.value === tag)

  return get(registeredTag, 'label') || tag
}

export const useSeoProps = () => {
  const selectedTag = useSelectedTag()
  const tagDisplay = useGetTagDisplay(selectedTag)

  return { title: selectedTag ? `${tagDisplay} NFTs` : 'Explore' }
}
