// import { ReactComponent as ElrondIcon } from 'startup/assets/elrond-egld-egld-logo.svg'
import 'components/forms/nerds-form/fields/price-field/currency/index.scss'
import { Balance } from 'components/forms/nerds-form/fields/price-field/balance'
import { settings } from 'settings/fetching'

const egldLabel = () => settings().dapp.egldLabel

export const Currency = () => <div className={'price-field-currency d-flex align-items-center'}>
  {/* <ElrondIcon /> */}
  <div>
    <div className={'price-field-currency-currency'}>{egldLabel()}</div>
    <Balance />
  </div>
</div>
