import { ExplorerLink } from 'components/explorer-link'
import { useParams } from 'react-router-dom'
import { useAssetName } from 'nft-page/header/title/hooks/use-asset-name'
import { useIsVerified } from 'nft-page/hooks/nft/use-is-verified'
import { VerifiedBadge } from 'components/verified-badge'
import 'nft-page/header/title/index.scss'

export const Title = () => {
  const assetName = useAssetName()
  const isVerified = useIsVerified()
  const { identifier } = useParams()
  const link = `/nfts/${identifier}`

  return <h1 className={'nft-title text-wrap'}>
    {assetName}
    {' '}
    {isVerified && <VerifiedBadge />}
    {' '}
    <ExplorerLink link={link} />
  </h1>
}
