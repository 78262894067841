import { useOnClick } from 'account/tabs/hooks/use-on-click'
import { useActiveTab } from 'account/tabs/hooks/use-active-tab'

const DEFAULT_CLASSES = 'col d-flex align-items-center justify-content-center tabs-tab text-decoration-none'

export const useTabProps = tab => {
  const onClick = useOnClick(tab)
  const activeTab = useActiveTab()

  return {
    onClick,
    className: `${DEFAULT_CLASSES}${tab.hash === activeTab ? ' tabs-active' : ''}`,
  }
}
