import { useContext } from 'nft-page/mutate-like/context'
import { useOnClick } from 'nft-page/mutate-like/hooks/use-on-click'
import { UnlikeButton } from 'components/unlike-button'
import { LikeButton } from 'components/like-button'
import { useLikes } from 'nft-page/likes/hooks'

export const Content = () => {
  const { isLiked } = useContext()
  const onClick = useOnClick()
  const count = useLikes()
  const btnProps = { count, onClick }

  return isLiked ? <UnlikeButton {...btnProps} /> : <LikeButton {...btnProps} />
}
