import 'account/tabs/tab-header/tab-menu-item/counter/index.scss'
import { CenteredContent } from 'components/centered-content'
import { useCounterProps } from 'account/tabs/tab-header/tab-menu-item/counter/hooks/use-counter-props'
import { useShouldDisplay } from 'account/tabs/tab-header/tab-menu-item/hooks/use-should-display'

export const Counter = ({ tab, selector }) => {
  const shouldDisplay = useShouldDisplay(tab)
  const tabProps = useCounterProps(tab.hash)

  return shouldDisplay && <CenteredContent {...tabProps}>
    {selector()}
  </CenteredContent>
}
