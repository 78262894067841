import { Item } from 'components/collection-stats/item'
import { name } from 'components/collection-stats/name'
import 'components/collection-stats/index.scss'

export const CollectionStats = ({ collectionStats }) => <div className={'collection-stats'}>
  <div className={'row justify-content-center'}>
    <Item label={name.items} value={collectionStats.items} />
    <Item label={name.activeAuctions} value={collectionStats.activeAuctions} />
    <Item label={name.minPrice} value={collectionStats.minPrice} isPrice />
    {/* <Item label={name.saleAverage} value={collectionStats.saleAverage} isPrice />
    <Item label={name.maxPrice} value={collectionStats.maxPrice} isPrice featured />
    <Item label={name.auctionsEnded} value={collectionStats.auctionsEnded} />
    <Item label={name.volumeTraded} value={collectionStats.volumeTraded} isPrice /> */}
  </div>
</div>
