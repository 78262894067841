import { AddressRow } from 'sidebar/connect/ledger-login/ledger-container/address-row'

export const Body = ({ accounts, startIndex, addressesPerPage, selectedAddress, onSelectAddress }) => <tbody>
  {accounts.map((address, index) => {
    const key = index + startIndex * addressesPerPage

    return <AddressRow key={key}
                       address={address}
                       index={key}
                       selectedAddress={selectedAddress}
                       onSelectAddress={onSelectAddress} />
  })}
</tbody>
