import { denominate } from 'components/conversion'
import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { get } from 'lodash'

export const useMaxBidReached = () => {
  const auction = useNftDefaultAuction()
  const maxBidAmount = get(auction, 'maxBid.amount', '')
  const topBidAmount = get(auction, 'topBid.amount', '')

  return denominate(maxBidAmount) === denominate(topBidAmount)
}
